import { type FC, memo } from 'react';

import { useCurrentCompany } from '@/store/company/hooks';

import { Loader, WidgetSimpleValueOnly } from '@/components/structural';


export const CompanyUTRWidget: FC = memo( () =>
{
    const { orCompany, isFetching } = useCurrentCompany();

    if ( isFetching ) return <Loader skeleton/>;

    return ( <>
        <WidgetSimpleValueOnly
              value={ orCompany?.utr_number || '* missing *' }
              type="string"
              description="UTR Code"
              variant="light"
        />
    </> );
} );

CompanyUTRWidget.displayName = 'CompanyUTRWidget';
