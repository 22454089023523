import { type ChangeEvent, type FC, memo, useCallback } from 'react';
import styled from 'styled-components';
import tw from 'tailwind-styled-components';

import { doPatchCurrentUser } from '@/store/user/actions';
import { useAppDispatch } from '@/store/hooks';
import { useCurrentUser } from '@/store/user/hooks';


interface IDarkModeToggleProps
{
    className?: string,
    size: string | number,
    isDarkMode?: boolean,
}

export const DarkModeToggle: FC<IDarkModeToggleProps> = memo( ( { className, size, isDarkMode } ) =>
{
    const dispatch = useAppDispatch();
    const { isLoading } = useCurrentUser();

    const updateDarkMode = useCallback( ( evt: ChangeEvent<HTMLInputElement> ) =>
    {
        dispatch( doPatchCurrentUser( {
            settings: {
                dark_mode: !evt.target.checked
            }
        } ) );
    }, [] );

    return ( <>
        <ToggleWrapper className={ className } $animate={ isLoading }>
            <Toggle $size={ size }
                    type="checkbox"
                    checked={ !isDarkMode }
                    onChange={ updateDarkMode }/>
        </ToggleWrapper>
    </> );
} );

DarkModeToggle.displayName = 'DarkModeToggle';

// https://codepen.io/mrozilla/pen/OJJNjRb
const Toggle = styled.input<{ $size: string | number }>`
    --size: ${ props => props.$size };

    appearance: none;
    outline: none;
    cursor: pointer;

    /*width: var(--size);
    height: var(--size);
    box-shadow: inset calc(var(--size) * 0.33) calc(var(--size) * -0.25) 0;
    border-radius: 999px;
    color: hsl(0, 0%, 100%);

    transition: all 500ms;

    &:checked {
        --ray-size: calc(var(--size) * -0.4);
        --offset-orthogonal: calc(var(--size) * 0.65);
        --offset-diagonal: calc(var(--size) * 0.45);

        transform: scale(0.75);
        color: hsl(40, 100%, 50%);
        box-shadow: inset 0 0 0 var(--size),
        calc(var(--offset-orthogonal) * -1) 0 0 var(--ray-size),
        var(--offset-orthogonal) 0 0 var(--ray-size),
        0 calc(var(--offset-orthogonal) * -1) 0 var(--ray-size),
        0 var(--offset-orthogonal) 0 var(--ray-size),
        calc(var(--offset-diagonal) * -1) calc(var(--offset-diagonal) * -1) 0 var(--ray-size),
        var(--offset-diagonal) var(--offset-diagonal) 0 var(--ray-size),
        calc(var(--offset-diagonal) * -1) var(--offset-diagonal) 0 var(--ray-size),
        var(--offset-diagonal) calc(var(--offset-diagonal) * -1) 0 var(--ray-size);
    }*/
`;

const ToggleWrapper = tw.div<{ $animate: boolean }>`
    relative
    h-full
    inline-flex
    items-center
    ${ p => p.$animate ? 'animate-spin' : '' }
`;