import { takeEvery } from 'redux-saga/effects';

import { userApi } from '@/store/user';
import { storeUtils } from '@/store';
import { doPatchCurrentUser } from '@/store/user/actions';
import { getCookie } from '@/shared/tools';


function* onPatchCurrentUserScript( { payload: data }: ReturnType<typeof doPatchCurrentUser> )
{
    const uuid = getCookie( { cName: 'orCurrentUserUuid' } );
    if ( !uuid ) return;

    // @ts-expect-error
    yield storeUtils.dispatch( userApi.endpoints.patchUser.initiate( { uuid, data } ) );
}

export function* onPatchCurrentUserSaga()
{
    yield takeEvery( [ doPatchCurrentUser ], onPatchCurrentUserScript );
}