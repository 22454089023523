import { createSelector } from '@reduxjs/toolkit';

import type { TRootState } from '@/store';


export const selectAuthState = ( state: TRootState ) => state.auth;

export const selectAWSUser = createSelector(
      [ selectAuthState ],
      ( auth ) => auth.awsUser
);