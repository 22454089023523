import { createEntityAdapter, type EntityState } from '@reduxjs/toolkit';

import { providesIds, providesList } from '@/store/_rtk-query';

import { companyApi } from './service.company';

import { compare, getCookie } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORCompanyShareTransfer, IORCompanyShareTransferMainAttributes } from '@/features/company/shared/models';
import type { IORGetParameters } from '@/store/_models/get-parameters.interface';
import type { IORResponseMeta, IORResponseMetaData } from '@/store/_rtk-query/meta-response.interface';


export const shareTransferAdapter = createEntityAdapter<IORCompanyShareTransfer, string>( {
    sortComparer: ( a, b ) => compare( a.transfer_date, b.transfer_date, true ),
    selectId: ( e ) => e.uuid,
} );

export const shareTransferApi = companyApi.injectEndpoints( {
    endpoints: ( builder ) => ( {
        getOneShareTransfer: builder.query<IORCompanyShareTransfer, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/transfer/${ uuid }`, method: 'get'
            } ),
            providesTags: ( result ) => (
                  providesIds( [ result?.uuid ], 'ShareTransfer' )
            ),
        } ),
        getManyShareTransfers: builder.query<EntityState<IORCompanyShareTransfer, string> & IORResponseMeta, IORGetParameters>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/transfer/`, method: 'get', queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                }
            } ),
            providesTags: ( normalisedResult ) => (
                  providesList( normalisedResult?.ids, 'ShareTransfers', 'ShareTransfer' )
            ),
            transformResponse: ( data: IORCompanyShareTransfer[], meta: IORResponseMetaData ) => ( {
                ...shareTransferAdapter.upsertMany( shareTransferAdapter.getInitialState(), data ),
                ...{ meta }
            } )
        } ),
        patchShareTransfer: builder.mutation<IORCompanyShareTransfer, { uuid: string, data: Partial<IORCompanyShareTransferMainAttributes> }>( {
            query: ( { uuid, data } ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/transfer/${ uuid }`, method: 'patch', data
            } ),
            invalidatesTags: ( { uuid } ) => ( [
                ...providesIds( [ uuid ], 'ShareTransfer' ),
                ...providesList( [], 'Shareholdings' ),
                ...providesList( [], 'ShareholdingsSummary' )
            ] )
        } ),
        postShareTransfer: builder.mutation<IORCompanyShareTransfer, Partial<IORCompanyShareTransferMainAttributes>>( {
            query: ( data ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/transfer/`, method: 'post', data
            } ),
            invalidatesTags: () => ( [
                ...providesList( [], 'ShareTransfers' ),
                ...providesList( [], 'Shareholdings' ),
                ...providesList( [], 'ShareholdingsSummary' )
            ] )
        } ),
        deleteShareTransfer: builder.mutation<void, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/transfer/${ uuid }`, method: 'delete'
            } ),
            invalidatesTags: ( _, __, uuid ) => ( [
                ...providesList( [ uuid ], 'ShareTransfers', 'ShareTransfer' ),
                ...providesList( [], 'Shareholdings' ),
                ...providesList( [], 'ShareholdingsSummary' )
            ] ),
        } ),
    } ),
} );

export const {
    useGetOneShareTransferQuery,
    useLazyGetOneShareTransferQuery,
    useGetManyShareTransfersQuery,
    useLazyGetManyShareTransfersQuery,
    usePatchShareTransferMutation,
    usePostShareTransferMutation,
    useDeleteShareTransferMutation,
    endpoints: shareTransferApiEndpoints,
} = shareTransferApi;