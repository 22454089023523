import moment, { type MomentInput } from 'moment-timezone';

import { DATE_FORMAT, DATE_FORMAT_SERVER } from '@/shared/dateFormats';
import { isUnixDate } from '@/shared/tools/isDate';


export const normaliseDate = ( date: MomentInput, toFormat: string = DATE_FORMAT, fromFormat: string = DATE_FORMAT_SERVER, noFormat = false ) =>
{
    const parsedDate = isUnixDate( date ) ? new Date( Number( date ) ) : date;
    const momentDate = moment( parsedDate, fromFormat );

    // Server is always UTC, so we need to add the user's current timezone offset to the date
    momentDate.add( momentDate.utcOffset(), 'minutes' );

    if ( noFormat )
    {
        return momentDate;
    }

    return momentDate.format( toFormat );
};
