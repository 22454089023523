import { useMemo } from 'react';

import { getMetaDefaultObject } from '@/store/_rtk-query/getMetaDefaultObject';
import { useCurrentUser } from '@/store/user/hooks';

import { tagItemAdapter, useGetManyTagItemsQuery, useGetOneTagItemQuery } from '@/store/company';
import { tagItemSelectors } from '@/store/company/selectors';

import { compare } from '@/shared/tools';
import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORGetParameters } from '@/store/_models/get-parameters.interface';


export const useTagItems = ( companyUUID: string, getParameters: IORGetParameters = { page: 1, per_page: DEFAULT_PER_PAGE }, skip = false ) =>
{
    const { awsUser } = useCurrentUser();
    const { data, isFetching, isLoading, error, status } = useGetManyTagItemsQuery( getParameters, {
        skip: skip || !awsUser
    } );

    const tagItems = tagItemSelectors.selectAll(
          data || tagItemAdapter.getInitialState()
    ).filter( ( e ) => e.company === companyUUID );

    tagItems.sort( ( a, b ) => compare( a.created_at, b.created_at ) );

    const meta = data?.meta || getMetaDefaultObject();

    return useMemo( () => ( {
        tagItems,
        meta,
        isLoading,
        isFetching,
        error,
        status
    } ), [ data, isFetching, isLoading, error, status ] );
};

export const useTagItem = ( uuid: string, skip = false ) =>
{
    const { awsUser } = useCurrentUser();
    const { data: tagItem, isFetching, isLoading, error } = useGetOneTagItemQuery( uuid, {
        skip: skip || !awsUser
    } );

    return useMemo( () => ( {
        tagItem,
        isLoading,
        isFetching,
        error
    } ), [ tagItem, isFetching, isLoading, error ] );
};