import { useMemo } from 'react';

import { useAuth } from '@/store/auth/hooks';
import { useGetManyUsersQuery, userAdapter } from '@/store/user';
import { usersSelector } from '@/store/user/selectors';
import { compare } from '@/shared/tools';


export const useUsers = ( skip = false ) =>
{
    const { awsUser } = useAuth();
    const { data, isFetching, isLoading, error, status } = useGetManyUsersQuery( undefined, {
        skip: skip || !awsUser
    } );

    const users = usersSelector.selectAll(
          data ||
          userAdapter.getInitialState()
    );

    users.sort( ( a, b ) => compare(
          a.updated_at || a.created_at,
          b.updated_at || b.created_at,
          true
    ) );

    return useMemo( () => ( {
        users,
        isLoading,
        isFetching,
        error,
        status
    } ), [ data, isFetching, isLoading, error, status ] );
};