import { type FC } from 'react';
import Highlighter from 'react-highlight-words';


interface IHighlightProps
{
    text: string,
    highlight: string,
}

export const Highlight: FC<IHighlightProps> = ( { text = '', highlight = '' } ) => ( <>
    <Highlighter
          highlightClassName="text-warning dark:text-warning-dark"
          searchWords={ highlight.split( ' ' ) }
          autoEscape
          textToHighlight={ text }
    />
</> );