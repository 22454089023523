import { Amplify } from 'aws-amplify';
import { AuthSession, AuthUser, autoSignIn, confirmResetPassword, confirmSignUp, fetchAuthSession, getCurrentUser, resendSignUpCode, resetPassword, signIn, signOut, signUp } from '@aws-amplify/auth';
import type { AuthServiceOptions } from '@aws-amplify/auth/dist/esm/types';
import type { ConfirmSignUpOptions, SignUpOutput } from '@aws-amplify/auth/dist/esm/providers/cognito/types';

import { processError } from '@/shared/processError';

import type { IAWSUser } from '@/features/auth/models';
import type { ORJSON } from '@/shared/models';


const srv = () =>
{
    ///*** CONFIG ***///
    const defaultOptions: AuthServiceOptions = {
        clientMetadata: { referrer: process.env.NEXT_PUBLIC_OR_TYPE || '' },
        authFlowType: process.env.NEXT_PUBLIC_AUTH_FLOW_TYPE as AuthUser['signInDetails']['authFlowType'],
    };

    try
    {
        Amplify.configure( {
            Auth: {
                Cognito: {
                    identityPoolId: process.env.NEXT_PUBLIC_AWS_AMPLIFY_IDENTITY_POOL_ID,
                    userPoolId: process.env.NEXT_PUBLIC_AWS_AMPLIFY_USER_POOL_ID,
                    userPoolClientId: process.env.NEXT_PUBLIC_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID
                }
            }
        } );
    } catch ( error )
    {
        processError( 'auth Service', error );
    }

    ///*** PRIVATE FUNCTIONS ***///
    const _getUser = async (): Promise<IAWSUser> =>
    {
        const user = await getCurrentUser();
        const session = await fetchAuthSession();
        return parseIAWSUser( user, session );
    };

    ///*** PUBLIC FUNCTIONS ***///

    const parseIAWSUser = ( user: AuthUser, session: AuthSession ): IAWSUser => ( {
        user,
        session: {
            ...session,
            tokens: {
                ...session.tokens,
                idToken: session.tokens.idToken.toString(),
                accessToken: session.tokens.accessToken.toString(),
            }
        },
    } );

    const signInUser = async ( { email, password }: { email: string, password: string } ): Promise<IAWSUser> =>
    {
        const signInOutput = await signIn( { username: email, password, options: { ...defaultOptions } } );
        if ( signInOutput.isSignedIn )
        {
            return _getUser();
        }
    };

    const signInNoPassword = async ( { email }: { email: string } ): Promise<IAWSUser> =>
    {
        try
        {
            const signInOutput = await signIn( { username: email, options: { clientMetadata: { referrer: process.env.NEXT_PUBLIC_OR_TYPE || '' } } } );
            if ( signInOutput.isSignedIn )
            {
                return _getUser();
            }
        } catch ( error )
        {
            const userAlreadyLoggedIn = JSON.stringify( error ).includes( 'UserAlreadyAuthenticatedException' );
            if ( userAlreadyLoggedIn )
            {
                return _getUser();
            }
        }
        throw new Error( 'Could not sign in automatically. Please login with your email and password.' );
    };

    const signOutUser = async () =>
    {
        await refreshToken();
        await signOut( { global: true } );
    };

    const refreshToken = async (): Promise<IAWSUser> =>
    {
        return _getUser();
    };

    const signUpUser = async ( { email, password, extraAttr = {} }: { email: string, password: string, extraAttr?: ORJSON } ): Promise<SignUpOutput> =>
    {
        return signUp( {
            username: email,
            password,
            options: {
                ...defaultOptions,
                userAttributes: { email, ...extraAttr },
                autoSignIn: true
            }
        } );
    };

    const signUpNoConfirm = async ( { email, password }: { email: string, password: string } ): Promise<SignUpOutput> =>
    {
        return signUpUser( { email, password, extraAttr: { 'custom:invited': 'yes' } } )
              .then( ( user ) => user );
    };

    const resendConfirmationEmail = async ( { email }: { email: string } ) =>
    {
        return resendSignUpCode( { username: email, options: { ...defaultOptions } } );
    };

    const confirmSignup = async ( { email, code, options = {} }: { email: string, code: string, options?: ConfirmSignUpOptions } ): Promise<IAWSUser | null> =>
    {
        await confirmSignUp( { username: email, confirmationCode: code, options: { ...defaultOptions, ...options } } );
        try
        {
            await autoSignIn();
            return _getUser();
        } catch ( error )
        {
            return null;
        }
    };

    const userExist = async ( { email }: { email: string } ) =>
    {
        try
        {
            const user = await signInUser( { email, password: '' } );
            if ( user )
            {
                await signOutUser();
                return true;
            }
            return false;
        } catch ( error )
        {
            switch ( error.code )
            {
                case 'PasswordResetRequiredException':
                case 'UserNotConfirmedException':
                    return true;
                case 'NotAuthorizedException':
                case 'UserNotFoundException':
                default:
                    return false;
            }
        }
    };

    const forgotPassword = async ( { email }: { email: string } ) =>
    {
        return resetPassword( { username: email, options: { ...defaultOptions } } );
    };

    const forgotPasswordSubmitNewPassword = async ( { email, code, password }: { email: string, code: string, password: string } ) =>
    {
        return confirmResetPassword( { username: email, confirmationCode: code, newPassword: password, options: { ...defaultOptions } } );
    };

    ///*** PUBLIC API ***///

    return {
        signUp: signUpUser,
        signUpNoConfirm,
        userExist,
        resendConfirmationEmail,
        confirmSignup,
        forgotPassword,
        forgotPasswordSubmitNewPassword,
        signIn: signInUser,
        signInNoPassword,
        refreshToken,
        signOut: signOutUser,
        parseIAWSUser,
    };
};

export const authService = srv();
