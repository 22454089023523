import { normaliseCompanyNameString } from '@/features/company/shared/tools/normaliseCompanyName';
import { replaceUndefinedWithNull } from '@/shared/tools';

import { OR_HMRC_COMPANY_EIS_LIMIT_CURRENT_YEAR, OR_HMRC_COMPANY_EIS_LIMIT_LIFETIME, OR_HMRC_COMPANY_SEIS_LIMIT } from '@/shared/constants';

import type { IORCompany, IORCompanyFromBackend } from '@/features/company/shared/models';


export const mapCompany = ( company: IORCompanyFromBackend ): IORCompany =>
{
    // console.log( company );

    if ( !company.rest_data?.json_data )
    {
        company.rest_data = {
            uuid: 'NOT_AVAILABLE',
            json_data: {
                summary_info: {},
                filing_history: null,
                officers: null,
                pscs: null,
                psc_statements: null,
            } as IORCompanyFromBackend['rest_data']['json_data'],
            last_update: null,
            refresh_url: null,
        };
    }

    const {
        rest_data: {
            json_data: chData,
            last_update: chDataLastUpdate,
            uuid: chDataUuid
        },
        ...companyWithoutCH
    } = company;

    const newOR: Omit<IORCompany, 'ch_data'> = {
        ...companyWithoutCH,
        seis_allocation: OR_HMRC_COMPANY_SEIS_LIMIT,
        eis_allocation_yearly: OR_HMRC_COMPANY_EIS_LIMIT_CURRENT_YEAR,
        eis_allocation: OR_HMRC_COMPANY_EIS_LIMIT_LIFETIME,
        name: normaliseCompanyNameString( companyWithoutCH.company_name ),
    };

    const newCh: IORCompany['ch_data'] = {
        uuid: chDataUuid,
        last_update: chDataLastUpdate,
        chCharges: [],
        chExemptions: null,
        chFilingHistories: chData.filing_history,
        chOfficers: chData.officers,
        chPersonsWithSignificantControl: chData.pscs,
        chPersonsWithSignificantControlStatements: chData.psc_statements,
        chProfile: {
            accounts: {
                accounting_reference_date: {
                    day: chData.summary_info.accounting_reference_day,
                    month: chData.summary_info.accounting_reference_month,
                },
                last_accounts: {
                    made_up_to: chData.summary_info.last_accounts_made_up_to,
                    period_end_on: chData.summary_info.last_accounts_period_end_on,
                    period_start_on: chData.summary_info.last_accounts_period_start_on,
                    type: chData.summary_info.last_accounts_type,
                },
                next_accounts: {
                    due_on: chData.summary_info.next_accounts_due_on,
                    overdue: String( chData.summary_info.next_accounts_overdue ),
                    period_end_on: chData.summary_info.next_accounts_period_end_on,
                    period_start_on: chData.summary_info.next_accounts_period_start_on,
                },
                next_due: chData.summary_info.accounts_next_due,
                next_made_up_to: chData.summary_info.accounts_next_made_up_to,
                overdue: String( chData.summary_info.accounts_overdue ),
            },
            branch_company_details: {
                business_activity: '',
                parent_company_name: '',
                parent_company_number: '',
            },
            can_file: String( chData.summary_info.can_file ),
            company_name: chData.summary_info.company_name,
            company_number: chData.summary_info.company_number,
            company_status: chData.summary_info.company_status,
            company_status_detail: chData.summary_info.company_status_detail,
            confirmation_statement: {
                last_made_up_to: chData.summary_info.conf_statement_last_made_up_to,
                next_due: chData.summary_info.conf_statement_next_due,
                next_made_up_to: chData.summary_info.conf_statement_next_made_up_to,
                overdue: String( chData.summary_info.conf_statement_overdue ),
            },
            date_of_cessation: chData.summary_info.date_of_cessation,
            date_of_creation: chData.summary_info.date_of_creation,
            etag: chData.summary_info.etag,
            external_registration_number: chData.summary_info.external_registration_number,
            foreign_company_details: {
                accounting_requirement: {
                    foreign_account_type: '',
                    terms_of_account_publication: '',
                },
                accounts: {
                    account_period_from: {
                        day: '',
                        month: '',
                    },
                    account_period_to: {
                        day: '',
                        month: '',
                    },
                    must_file_within: {
                        months: '',
                    },
                },
                business_activity: '',
                company_type: '',
                governed_by: '',
                is_a_credit_finance_institution: '',
                originating_registry: {
                    country: '',
                    name: '',
                },
                registration_number: '',
            },
            has_been_liquidated: String( chData.summary_info.has_been_liquidated ),
            has_charges: String( chData.summary_info.has_charges ),
            has_insolvency_history: String( chData.summary_info.has_insolvency_history ),
            is_community_interest_company: String( chData.summary_info.is_community_interest_company ),
            jurisdiction: chData.summary_info.jurisdiction,
            last_full_members_list_date: chData.summary_info.last_full_members_list_date,
            partial_data_available: String( chData.summary_info.partial_data_available ),
            previous_company_names: chData.summary_info.previous_company_names,
            registered_office_address: {
                address_line_1: chData.summary_info.reg_office_address_line_1,
                address_line_2: chData.summary_info.reg_office_address_line_2,
                care_of: chData.summary_info.reg_office_care_of,
                country: chData.summary_info.reg_office_country,
                locality: chData.summary_info.reg_office_locality,
                po_box: chData.summary_info.reg_office_po_box,
                postal_code: chData.summary_info.reg_office_postal_code,
                premises: chData.summary_info.reg_office_premises,
                region: chData.summary_info.reg_office_region,
            },
            registered_office_is_in_dispute: String( chData.summary_info.reg_office_in_dispute ),
            sic_codes: chData.summary_info.sic_codes ? JSON.parse( chData.summary_info.sic_codes ) : [],
            subtype: chData.summary_info.subtype,
            type: chData.summary_info.type,
            undeliverable_registered_office_address: String( chData.summary_info.reg_office_undeliverable ),
        }
    };

    const newChWithoutUndefined = replaceUndefinedWithNull( newCh );

    return {
        ...newOR,
        ch_data: newChWithoutUndefined
    };
};
