import { useCallback, useEffect } from 'react';

import { getCookie, removeCookies, setCookie } from '@/shared/tools';
import { useAppDispatch } from '@/store/hooks';

import { companyApi } from '@/store/company';
import { datastoreApi } from '@/store/datastore';

import { VIEW_ALL_COMPANIES_ID } from '@/shared/constants';
import { portfolioApi } from '@/store/portfolio';


// better than useRef as 'useCurrentIDsGuard' can be used in multiple 'useUser' hook instances at the same time
const prev = {
    currentAccountUuid: null,
    currentCompanyUuid: null,
    currentUserUuid: null,
};

export const useCurrentIDsGuard = ( loggedIn: boolean, currentAccountUuid: string, currentCompanyUuid: string, currentUserUuid: string ) =>
{
    const dispatch = useAppDispatch();

    const invalidateCurrentCompanyData = useCallback( () =>
    {
        dispatch( companyApi.util.invalidateTags( [
            'ConvertibleLoanNote',
            'ConvertibleLoanNotes',
            'FilingCompanyRegisteredAddress',
            'FilingsCompanyRegisteredAddress',
            'FilingCompanyRegisteredName',
            'FilingsCompanyRegisteredName',
            'Filing',
            'Filings',
            'Fundamentals',
            'OptionIssue',
            'OptionIssues',
            'OptionRound',
            'OptionRounds',
            'ShareClass',
            'ShareClasses',
            'ShareClassShareHistory',
            'ShareClassesShareHistory',
            'ShareClassRedesignation',
            'ShareClassRedesignations',
            'ShareDilution',
            'ShareDilutions',
            'ShareIssue',
            'ShareIssues',
            'ShareRound',
            'ShareRounds',
            'ShareTransfer',
            'ShareTransfers',
            'Shareholding',
            'Shareholdings',
            'ShareholdingsSummary',
            'Stakeholder',
            'Stakeholders',
            'Transaction',
            'Transactions'
        ] ) );

        dispatch( datastoreApi.util.invalidateTags( [
            'DatastoreEntity',
            'DatastoreEntities'
        ] ) );

        dispatch( portfolioApi.util.invalidateTags( [
            'PortfolioEntity'
        ] ) );
    }, [] );

    const invalidateAllCompaniesData = useCallback( () =>
    {
        dispatch( companyApi.util.invalidateTags( [
            'Companies'
        ] ) );

        dispatch( portfolioApi.util.invalidateTags( [
            'PortfolioEntity'
        ] ) );
    }, [] );

    const setOrRemoveCookie = useCallback( ( cookieName: string, newValue: string ) =>
    {
        const cookieValue = getCookie( { cName: cookieName } );
        if ( !loggedIn && cookieValue )
        {
            removeCookies( cookieName );
            prev[ cookieName ] = null;
        } else if ( newValue && newValue !== prev[ cookieName ] )
        {
            setCookie( { cName: cookieName, cValue: newValue } );
            prev[ cookieName ] = newValue;
            if ( cookieName === 'orCurrentCompanyUuid' && newValue !== VIEW_ALL_COMPANIES_ID )
            {
                invalidateCurrentCompanyData();
            }
            if ( cookieName === 'orCurrentAccountUuid' || cookieName === 'orCurrentUserUuid' )
            {
                invalidateAllCompaniesData();
            }
        }
    }, [ loggedIn ] );

    useEffect( () =>
    {
        setOrRemoveCookie( 'orCurrentAccountUuid', currentAccountUuid );
        setOrRemoveCookie( 'orCurrentCompanyUuid', currentCompanyUuid );
        setOrRemoveCookie( 'orCurrentUserUuid', currentUserUuid );
    }, [ currentAccountUuid, currentCompanyUuid, currentUserUuid, setOrRemoveCookie, loggedIn ] );
};