import { type FC, memo } from 'react';
import SwitchAntD, { type SwitchProps } from 'antd/lib/switch';


export const Switch: FC<SwitchProps> = memo( ( props ) =>
{
    const {
        ...rest
    } = props;

    return ( <SwitchAntD
          { ...rest }
    /> );
} );

Switch.displayName = 'Switch';