import { type FC, type PropsWithChildren } from 'react';

import { Select } from '@/components/structural/form';


interface ISortingBoxProps
{
    onSortChange: ( value: string ) => void,
    value: string,
    className: string
}

export const SortingBox: FC<PropsWithChildren<ISortingBoxProps>> = ( { onSortChange, children, value, className } ) => ( <>
    <Select
          className={ className }
          onChange={ onSortChange }
          value={ value }
          size="middle"
    >
        { children }
    </Select>
</> );