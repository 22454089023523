import tw from 'tailwind-styled-components';
import { type FC, type ReactNode } from 'react';


interface IBottomContainerProps
{
    left: ReactNode;
    right: ReactNode;
}

export const AuthPageBottomLinks: FC<IBottomContainerProps> = ( { left, right } ) =>
      <Container>
          <div>{ left }</div>
          <div className="text-right">{ right }</div>
      </Container>;

const Container = tw.div`
    grid
    grid-cols-2
    justify-between
    text-primary
`;