export const formatWordsWithSeparator = ( str: string, splitChar = '-', joinChar = ' ' ) =>
{
    if ( !str )
    {
        return '';
    }

    return str
          .split( splitChar )
          .map( word =>
                word[ 0 ].toLocaleUpperCase() +
                word.slice( 1 ).toLocaleLowerCase()
          )
          .join( joinChar );
};