import { useMemo } from 'react';

import { selectAWSUser } from '@/store/auth/selectors';
import { useAppSelector } from '@/store/hooks';


export const useAuth = () =>
{
    const awsUser = useAppSelector( selectAWSUser );

    return useMemo( () => ( {
              awsUser
          } ),
          [ awsUser ]
    );
};