import { type FC, memo, useCallback, useMemo } from 'react';

import { doPatchCurrentUser } from '@/store/user/actions';
import { useAppDispatch } from '@/store/hooks';
import { useCompanies, useCompanyWidgetsFilingDeadlines, useTags } from '@/store/company/hooks';

import { CardWithSpacedAndColouredRows, DataFormat, Loader, Tag, TitleWithIcon, Typography } from '@/components/structural';
import { prepareColumnItem } from '@/components/tables/sorting-tools/prepareColumnItem';
import { Table } from '@/components/tables/Table';

import { getPriorityAndAlertPill, parseCompanies } from '@/features/company/my-company/widgets/filing/filing-deadlines-data';

import { compare, getArray } from '@/shared/tools';
import { type ISorter } from '@/components/tables';

import { COMPANY_COLOURS } from '@/shared/constants';


export const FilingDeadlinesListWidget: FC = memo( () =>
{
    const dispatch = useAppDispatch();
    const { companies, isFetching } = useCompanies();
    const { selectedCompany } = useCompanyWidgetsFilingDeadlines();
    const { tags } = useTags();

    const changeCurrentCompanyTo = useCallback( ( companyUuid: string ) =>
    {
        dispatch( doPatchCurrentUser( {
            settings: {
                currentCompanyUuid: companyUuid
            }
        } ) );
    }, [] );

    const data = useMemo( () => parseCompanies( getArray<typeof companies[number]>( companies ) ), [ companies ] );
    type DataType = typeof data[number];

    const getColumns = useCallback( ( sortByColumn: ISorter ) => [
        prepareColumnItem<DataType, 'company'>( {
            title: 'Company',
            key: 'company',
            render: ( { uuid, name, colour, colourDark, type, tags: companyTags } ) => <>
                <div className={ `alert-${ type }` }>
                    <a onClick={ () => changeCurrentCompanyTo( uuid ) } className="w-full">
                        <TitleWithIcon
                              title={ name }
                              iconBgColour={ colour }
                              iconBgColourDark={ colourDark }
                        />
                    </a>
                    <div
                          className="flex items-center h-full shrink gap-1"
                    >
                        { companyTags?.filter( Boolean ).map( ( tag, index ) =>
                        {
                            const tagText = tags.find( t => t.uuid === tag.tag )?.text;
                            return ( <Tag
                                  key={ tag.uuid }
                                  // className="me-0 text-center w-[calc(100%*(1/4))]" // tags to the right
                                  className="me-0 text-center" // tags under
                                  color={ COMPANY_COLOURS[ index % COMPANY_COLOURS.length ] }
                            >
                                <Typography.Text
                                      ellipsis={ { tooltip: tagText } }
                                      className="text-white"
                                >
                                    { tagText }
                                </Typography.Text>
                            </Tag> );
                        } ) }
                    </div>
                </div>
            </>,
            sorter: ( a, b ) => compare( a.name, b.name, true )
        }, sortByColumn ),
        prepareColumnItem<DataType>( {
            title: 'Filing',
            key: 'filing',
            render: text => <DataFormat raw={ text } type="pill" className="!text-default"/>
        } ),
        prepareColumnItem<DataType>( {
            title: 'Due Date',
            key: 'date',
            render: date => <DataFormat raw={ date } type="date"/>
        }, sortByColumn ),
        prepareColumnItem<DataType, 'priority'>( {
            title: 'Priority',
            key: 'priority',
            render: text => getPriorityAndAlertPill( text ),
        } ),
    ], [] );

    if ( isFetching ) return <Loader skeleton/>;

    return ( <>
        <CardWithSpacedAndColouredRows title="Filing Deadlines" alternateTableRowBg={ false }>
            <Table
                  parsedData={ data }
                  getColumns={ getColumns }
                  sortByKeys={ [ 'company', 'date' ] }
                  size="small"
                  selectedRowKey={ selectedCompany }
                  disablePagination
                  disableColumnsSelector
                  disableSearch
                  disableHorizontalScroll
                  scroll={ { y: '38rem' } }
            />
        </CardWithSpacedAndColouredRows>
    </> );
} );

FilingDeadlinesListWidget.displayName = 'FilingDeadlinesListWidget';