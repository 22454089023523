import ImageNext from 'next/image';
import { type FC, memo } from 'react';


export interface ImageProps
{
    src: string,
    className?: string,
    alt?: string
    height: number | '100%',
    width: number | '100%',
}

export const Image: FC<ImageProps> = memo( (
      { src, className = '', alt = '', height, width }
) =>
{
    return ( <div className={ className }>
              <ImageNext src={ src }
                         alt={ alt }
                         style={ {
                             height,
                             width
                         } }
                         width={ Number( width ) }
                         height={ Number( height ) }
                         fill={ height === '100%' || width === '100%' }
                         sizes={ String( width ) }
                         placeholder="blur"
                         blurDataURL="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="/>
          </div>
    );
} );

Image.displayName = 'Image';