export const delayAsync = ( ms: number ) =>
{
    return new Promise( resolve =>
    {
        setTimeout( () => { resolve( '' ); }, ms );
    } );
};

export const delayFnAsync = ( fn: () => void | Promise<void>, ms: number ) =>
{
    return new Promise( resolve =>
    {
        setTimeout( () =>
        {
            fn();
            resolve( '' );
        }, ms );
    } );
};