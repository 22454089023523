import type { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';

import { getCookie, removeCookies, setCookie } from '@/shared/tools';
import { validateOAuthToken } from '@/store/auth/tools';
import { TokenExpiredError } from 'jsonwebtoken';


export const withAuthGuardSSR = <DataType>( context: GetServerSidePropsContext ) => async ( callback: () => Promise<GetServerSidePropsResult<DataType>> ):
      Promise<GetServerSidePropsResult<DataType>> =>
{
    const url = context.req.url;
    const isAuthPage = url.includes( '/auth' );

    for ( const cName in context.req.cookies )
    {
        if ( cName === 'orAuth' || cName === 'orToken' )
        {
            continue;
        }

        setCookie( { cName, cValue: context.req.cookies[ cName ] } );
    }

    const orToken = getCookie( { cName: 'orToken', from: context.req } );
    const tokenIsValid = !orToken ? false : await validateOAuthToken( orToken );

    if ( !orToken || typeof tokenIsValid !== 'boolean' && !( tokenIsValid instanceof TokenExpiredError ) )
    {
        // console.log( 'AuthGuard: No Valid token found. Removing cookies...' );
        [ 'orAuth',
            'orToken',
            'orCurrentAccountUuid',
            'orCurrentUserUuid',
            'orCurrentUserName',
            'orCurrentUserEmail',
            'orNewSignupEmail',
            'orNewSignupUserType',
            'orNewSignupFirstName',
            'orNewSignupLastName'
        ].forEach( cName =>
        {
            removeCookies( cName );
            context.res.setHeader( 'Set-Cookie', [
                `${ cName }=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT`,
            ] );
        } );

        if ( isAuthPage )
        {
            // console.log( 'AuthGuard: On Auth related page, continue...' );
            return callback();
        }

        // console.log( `AuthGuard: Redirecting ...` );
        return {
            redirect: {
                destination: '/auth/login',
                permanent: false,
            }
        };
    }

    setCookie( { cName: 'orToken', cValue: context.req.cookies.orToken } );
    setCookie( { cName: 'orAuth', cValue: context.req.cookies.orAuth } );

    // console.log( 'AuthGuard: Valid token found.' );

    if ( isAuthPage )
    {
        // console.log( `AuthGuard: Currently on Auth related page. Redirecting ...` );
        return {
            redirect: {
                destination: '/',
                permanent: false,
            }
        };
    }

    // console.log( 'AuthGuard: All OK. Continue...' );
    return callback();
};