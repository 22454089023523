import { put, takeEvery } from 'redux-saga/effects';

import { clearCompanyState } from '@/store/company/actions';
import {
    companyApi,
    convertibleLoanNoteApi,
    filingApi,
    filingCompanyRegisteredAddressApi,
    filingCompanyRegisteredNameApi,
    optionIssueApi,
    optionRoundApi,
    shareClassApi,
    shareDilutionApi,
    shareholdingApi,
    shareIssueApi,
    shareRoundApi,
    shareTransferApi,
    stakeholderApi,
    transactionApi,
} from '@/store/company';
import { companyChApi } from '@/store/company/service.ch';
import { doLogoutClearState } from '@/store/auth/actions';

import { removeCookies } from '@/shared/tools';


function* onLogoutScript()
{
    removeCookies( 'orCurrentCompanyUuid' );

    yield put( companyChApi.util.resetApiState() );
    yield put( companyApi.util.resetApiState() );
    yield put( convertibleLoanNoteApi.util.resetApiState() );
    yield put( filingCompanyRegisteredAddressApi.util.resetApiState() );
    yield put( filingCompanyRegisteredNameApi.util.resetApiState() );
    yield put( filingApi.util.resetApiState() );
    yield put( optionIssueApi.util.resetApiState() );
    yield put( optionRoundApi.util.resetApiState() );
    yield put( shareClassApi.util.resetApiState() );
    yield put( shareDilutionApi.util.resetApiState() );
    yield put( shareIssueApi.util.resetApiState() );
    yield put( shareRoundApi.util.resetApiState() );
    yield put( shareTransferApi.util.resetApiState() );
    yield put( shareholdingApi.util.resetApiState() );
    yield put( stakeholderApi.util.resetApiState() );
    yield put( transactionApi.util.resetApiState() );

    yield put( clearCompanyState() );
}

export function* onLogoutSaga()
{
    yield takeEvery( [ doLogoutClearState ], onLogoutScript );
}