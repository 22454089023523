import { useCallback, useEffect } from 'react';

import { createNotification } from '@/store/notifications/actions';
import { useAppDispatch } from '@/store/hooks';
import { useAuth } from '@/store/auth/hooks';
import { useVersion } from '@/store/internal/hooks';

import { type ModalLiveReference, openYesNoModal, Typography } from '@/components/structural';

import { clearAllCookiesAndCaches } from '@/shared/tools';


const fetchVersion = async () =>
{
    const response = await fetch( '/api/version' );
    if ( !response.ok )
    {
        throw new Error( 'Failed to fetch version' );
    }
    const data = await response.json();
    return data.version;
};

let modalRef: ModalLiveReference;
let intervalRef: NodeJS.Timeout;
let alreadyStarted = false;

export const useVersionChecker = ( interval = 60000, immediatelyRun = true ) =>
{
    const dispatch = useAppDispatch();

    const { version: loadedVersion, setVersion } = useVersion();
    const { awsUser } = useAuth();

    const closeModal = useCallback( () =>
    {
        if ( !modalRef ) return;
        modalRef.destroy();
        modalRef = undefined;
    }, [] );

    const stopChecking = useCallback( () =>
    {
        if ( !intervalRef ) return;
        clearInterval( intervalRef );
        intervalRef = undefined;
    }, [] );

    const doUpdateCore = useCallback( ( version: typeof loadedVersion ) =>
    {
        setVersion( version );
        setTimeout( () => clearAllCookiesAndCaches(), 100 );
        setTimeout( () => window?.location.reload(), 500 );
    }, [ dispatch ] );

    const openUpdateConfirmationModal = useCallback( ( version: typeof loadedVersion ) =>
    {
        if ( modalRef ) return;

        const versionLabel = `${ version.major }.${ version.minor }.${ version.patch }`;
        modalRef = openYesNoModal( {
            title: 'New Version Available',
            message: <Typography.Paragraph className="text-center">
                A new version of the application is available ({ versionLabel }).<br/>
                Would you like to apply the update now?<br/><br/>
                This will log you out, so please save any work before proceeding.<br/><br/>
                <span className="italic">Note: The app will automatically update the next time you access it.</span>
            </Typography.Paragraph>,
            buttonType: 'success',
            okButtonText: 'Update Now',
            okButtonClassName: '!w-32',
            onOk: () =>
            {
                doUpdateCore( version );
                stopChecking();
                closeModal();
            },
            cancelButtonText: 'Update Later',
            cancelButtonClassName: '!w-32',
            onCancel: () =>
            {
                closeModal();
                stopChecking();
            },
            maskClosable: false,
            closable: false
        } );
    }, [ doUpdateCore ] );

    const doUpdateGuest = useCallback( ( version: typeof loadedVersion ) =>
    {
        dispatch( createNotification( {
            title: 'New version detected. Applying update...',
            type: 'info',
            duration: 5
        } ) );
        stopChecking();
        setTimeout( () => doUpdateCore( version ), 500 );
    }, [] );

    useEffect( () =>
    {
        const checkVersion = async () =>
        {
            alreadyStarted = true;

            try
            {
                const serverVersion = await fetchVersion();

                if ( serverVersion.major !== loadedVersion.major || serverVersion.minor !== loadedVersion.minor || serverVersion.patch !== loadedVersion.patch )
                {
                    if ( awsUser ) openUpdateConfirmationModal( serverVersion );
                    else doUpdateGuest( serverVersion );
                }
            } catch ( error )
            {
                console.error( 'Error checking app version:', error );
            }
        };

        if ( immediatelyRun && !alreadyStarted ) checkVersion();

        if ( !intervalRef ) intervalRef = setInterval( checkVersion, interval );

        return stopChecking;
    }, [ interval, immediatelyRun, loadedVersion, doUpdateCore ] );
};