import type { TRootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';


export const selectNotificationsState = ( state: TRootState ) => state.notifications;

export const selectAppAlerts = createSelector(
      selectNotificationsState,
      ( notifications ) => notifications.alerts
);

export const selectAppBellAlerts = createSelector(
      selectNotificationsState,
      ( notifications ) => notifications.bellAlerts
);

export const selectAppBellUnreadAlerts = createSelector(
      selectNotificationsState,
      ( notifications ) => notifications.bellAlerts.filter( ba => !ba.read )
);

export const selectAppBellReadAlerts = createSelector(
      selectNotificationsState,
      ( notifications ) => notifications.bellAlerts.filter( ba => ba.read )
);

export const selectAppBellPendingAlerts = createSelector(
      selectNotificationsState,
      ( notifications ) => notifications.bellAlerts.filter( ba => !ba.action_result )
);

export const selectAppMessages = createSelector(
      selectNotificationsState,
      ( notifications ) => notifications.messages
);

export const selectAppNotifications = createSelector(
      selectNotificationsState,
      ( notifications ) => notifications.notifications
);