import { type FC, memo, useCallback, useEffect, useMemo } from 'react';

import { Checkbox, Form, FormItem, type IFormStepProps, Radio, RadioGroup, useForm } from '@/components/structural/form';
import { SVGIcon } from '@/components/structural/images';
import { Tooltip } from '@/components/structural';

import type { ORJSON } from '@/shared/models';
import type { ORCompanyFilingService } from '@/features/company/shared/models';

import infoIcon from '@/icons/info-circle-fill.svg';


export interface IStep1Data extends ORJSON<boolean | string>
{
    checked_name_availability: boolean,
    checked_name_eligibility: boolean,
    fee_disclaimer: boolean,
    service: ORCompanyFilingService,
}

export const Step1: FC<IFormStepProps<IStep1Data>> = memo( ( { initialData, isStepValid, setData, setIsValid } ) =>
{
    const [ formStep ] = useForm();

    const defaults = useMemo<IStep1Data>( () => ( initialData || {
        checked_name_availability: false,
        checked_name_eligibility: false,
        fee_disclaimer: false,
        service: null,
    } ), [ initialData ] );

    const updateFormData = useCallback( () =>
    {
        setData( formStep.getFieldsValue() );
        isStepValid( formStep ).then( setIsValid );
    }, [ setData, formStep, setIsValid, isStepValid ] );

    useEffect( () =>
    {
        formStep.setFieldsValue( defaults );
        if ( initialData ) isStepValid( formStep ).then( setIsValid );
    }, [ defaults ] );

    return <>
        <Form form={ formStep }
              className="font-roboto"
              layout="vertical"
              requiredMark={ false }
              onValuesChange={ updateFormData }
        >
            <div className="my-4">
                Before submitting a change of company name request to Companies House, please confirm the following:
            </div>

            <FormItem
                  name="checked_name_availability"
                  valuePropName="checked"
                  rules={ [ {
                      required: true, message: <></>, type: 'enum', enum: [ true ]
                  } ] }
            >
                <Checkbox>
                    <div className="ml-4">
                        I/we have checked the availability of the proposed name against the&nbsp;
                        <a className="underline text-primary dark:text-default-dark" target="_blank" rel="noreferrer"
                           href="https://find-and-update.company-information.service.gov.uk/company-name-availability">
                            Companies House Name Availability Checker
                        </a>.
                    </div>
                </Checkbox>
            </FormItem>

            <FormItem
                  name="checked_name_eligibility"
                  valuePropName="checked"
                  rules={ [ {
                      required: true, message: <></>, type: 'enum', enum: [ true ]
                  } ] }
            >
                <Checkbox>
                    <div className="ml-4">
                        I/we confirm that we understand&nbsp;
                        <a className="underline text-primary dark:text-default-dark" target="_blank" rel="noreferrer"
                           href="https://www.gov.uk/limited-company-formation/choose-company-name">
                            the rules relating to company names
                        </a>&nbsp;
                        and the proposed name
                        <div className="p-4 pb-0">
                            Is not offensive<br/>
                            Does not contain any sensitive words<br/>
                            Does not suggest a connection with government or local authorities
                        </div>
                    </div>
                </Checkbox>
            </FormItem>

            <FormItem
                  name="fee_disclaimer"
                  valuePropName="checked"
                  rules={ [ {
                      required: true, message: <></>, type: 'enum', enum: [ true ]
                  } ] }
            >
                <Checkbox>
                    <div className="ml-4">
                        I/we understand that a fee will be paid on my behalf to submit the change and an invoice will be created for payment within 30 days.
                    </div>
                </Checkbox>
            </FormItem>

            <FormItem
                  label="Service Required"
                  name="service"
                  valuePropName="value"
                  rules={ [ { required: true, message: <></>, type: 'enum', enum: [ 'Standard', 'Same Day' ] } ] }
            >
                <RadioGroup className="w-full">
                    <div className="grid grid-cols-2 justify-items-around">
                        <Radio value="Standard" className="text-success dark:text-success-dark">Standard</Radio>
                        <Radio value="Same Day" className="text-success dark:text-success-dark">
                            Same Day
                            <Tooltip
                                  className="inline-flex items-end"
                                  title={ <>
                                      If the change of name is urgent, it can be processed by Companies House on a same-day basis,
                                      on payment of an increased filing fee of £30. In practical terms, the resolution needs to be
                                      with Companies House by <span className="text-primary dark:text-default-dark">midday</span> in
                                      order for the change to be effective on the same day.
                                  </> }
                            >
                                &nbsp;
                                <SVGIcon
                                      className={ `!w-5 !h-5 text-info dark:text-info-dark relative bottom-[0.2rem]` }
                                      src={ infoIcon.src }
                                />
                            </Tooltip>
                        </Radio>
                    </div>
                </RadioGroup>
            </FormItem>
        </Form>
    </>;
} );

Step1.displayName = 'CompanyChangeRegisteredNameStep1';