import { utils, type WorkBook, type WorkSheet, write } from 'xlsx';

import { saveAsExcelFile } from './saveAsExcelFile';


export const exportAsExcelFile = ( json: unknown[], excelFileName: string ) =>
{
    const worksheet: WorkSheet = utils.json_to_sheet( json );
    const workbook: WorkBook = utils.book_new();

    utils.book_append_sheet( workbook, worksheet, 'Export Results' );

    const excelBuffer: BlobPart = write( workbook, { bookType: 'xlsx', type: 'array' } );

    saveAsExcelFile( excelBuffer, excelFileName );
};