import { type FC, memo, useCallback, useMemo } from 'react';

import { useCurrentCompany } from '@/store/company/hooks';

import { Card, DataFormat, Loader, WidgetViewAllLink } from '@/components/structural';
import { prepareColumnItem } from '@/components/tables/sorting-tools/prepareColumnItem';
import { Table } from '@/components/tables/Table';

import { getArray, kebabToTitleCase } from '@/shared/tools';


interface IPSCsWidgetProps
{
    maxToShow?: number;
}

export const PSCsWidget: FC<IPSCsWidgetProps> = memo( ( { maxToShow = 5 } ) =>
{
    const { chPSCs, chPSCStatements, isFetching } = useCurrentCompany();

    const data = useMemo( () => getArray<typeof chPSCs.items[number]>( chPSCs?.items )
          .filter( psc => !psc.ceased_on )
          .map( ( psc, index ) => ( {
              ...psc, key: index.toString(),
          } ) as typeof chPSCs.items[0] & { key: string } )
          .slice( 0, maxToShow ), [ chPSCs ] );
    type DataType = typeof data[number];

    const getColumns = useCallback( () => [
        prepareColumnItem<DataType>( {
            title: 'Name',
            key: 'name',
            render: text => <DataFormat raw={ text } maxCharsCutOff={ 30 }/>
        } ),
        prepareColumnItem<DataType, 'natures_of_control'>( {
            title: 'Nature of Control',
            key: 'natures_of_control',
            render: text => <DataFormat raw={ kebabToTitleCase( text[ 0 ] ) } breakTextOn="words"/>
        } ),
    ], [] );

    if ( isFetching ) return <Loader skeleton/>;

    if ( chPSCStatements && chPSCStatements.active_count > 0 )
    {
        const statementNoPSCs = chPSCStatements.items.filter( s =>
              s.statement === 'no-individual-or-entity-with-signficant-control' && !s.ceased_on
        );

        if ( statementNoPSCs ) return ( <>
            <Card title="Persons With Significant Control" styles={ { body: { paddingBottom: '3.5rem' } } }>
                <p className="text-lg">The company knows or has reasonable cause to believe that there is no
                    registrable person or registrable relevant legal entity in relation to the company.</p>
            </Card>
        </> );
    }

    return ( <>
        <Card title="Persons With Significant Control" styles={ { body: { paddingBottom: '3.5rem' } } }>
            <Table
                  parsedData={ data }
                  getColumns={ getColumns }
                  disablePagination
                  size="small"
                  disableColumnsSelector
            />
            <WidgetViewAllLink
                  url="/my-company/pscs"
            />
        </Card>
    </> );
} );

PSCsWidget.displayName = 'PSCsWidget';