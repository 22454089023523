import { AxiosError } from 'axios';
import { ORJSON } from '@/shared/models';


export const handleHTTPResponseError = ( errorObj: AxiosError['response'] ) =>
{
    let details = '';
    const separator = ' | ';

    if ( errorObj.data )
    {
        if ( Array.isArray( errorObj.data ) )
        {
            for ( const errorMessage of errorObj.data )
            {
                details += `"${ errorMessage }"` + separator;
            }
        } else
        {
            for ( const errorCode in errorObj.data as ORJSON<string> )
            {
                details += `"${ errorCode }": ${ errorObj.data[ errorCode ] }` + separator;
            }
        }

        details = ` (${ details.substring( 0, details.lastIndexOf( separator ) ) })`;
    }

    return `[${ errorObj.statusText }] ` + details;
};