import type { EntityId } from '@reduxjs/toolkit';


export const providesList = <R extends EntityId[], T extends string>(
      resultsWithIds: R | undefined,
      tagTypeList: T,
      tagType?: T,
) =>
{
    return resultsWithIds?.length
          ? [
              { type: tagTypeList, uuid: 'LIST' },
              ...resultsWithIds.map( ( uuid ) => ( { type: tagType || tagTypeList, uuid } ) ),
          ]
          : [ { type: tagTypeList, uuid: 'LIST' } ];
};