import { createEntityAdapter, type EntityState } from '@reduxjs/toolkit';

import { providesIds, providesList } from '@/store/_rtk-query';

import { companyApi } from './service.company';

import { compare, getCookie } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORCompanyConvertibleLoanNote, IORCompanyConvertibleLoanNoteMainAttributes } from '@/features/company/shared/models';
import type { IORGetParameters } from '@/store/_models/get-parameters.interface';
import type { IORResponseMeta, IORResponseMetaData } from '@/store/_rtk-query/meta-response.interface';


export const convertibleLoanNoteAdapter = createEntityAdapter<IORCompanyConvertibleLoanNote, string>( {
    sortComparer: ( a, b ) => compare( a.created_at, b.created_at, true ),
    selectId: ( e ) => e.uuid,
} );

export const convertibleLoanNoteApi = companyApi.injectEndpoints( {
    endpoints: ( builder ) => ( {
        getOneConvertibleLoanNote: builder.query<IORCompanyConvertibleLoanNote, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/convertibleloannote/${ uuid }`, method: 'get'
            } ),
            providesTags: ( result ) => (
                  providesIds( [ result?.uuid ], 'ConvertibleLoanNote' )
            ),
        } ),
        getManyConvertibleLoanNotes: builder.query<EntityState<IORCompanyConvertibleLoanNote, string> & IORResponseMeta, IORGetParameters>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/convertibleloannote/`, method: 'get', queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                }
            } ),
            providesTags: ( normalisedResult ) => (
                  providesList( normalisedResult?.ids, 'ConvertibleLoanNotes', 'ConvertibleLoanNote' )
            ),
            transformResponse: ( data: IORCompanyConvertibleLoanNote[], meta: IORResponseMetaData ) => ( {
                ...convertibleLoanNoteAdapter.upsertMany( convertibleLoanNoteAdapter.getInitialState(), data ),
                ...{ meta }
            } )
        } ),
        patchConvertibleLoanNote: builder.mutation<IORCompanyConvertibleLoanNote, { uuid: string, data: Partial<IORCompanyConvertibleLoanNoteMainAttributes> }>( {
            query: ( { uuid, data } ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/convertibleloannote/${ uuid }/`, method: 'patch', data
            } ),
            invalidatesTags: ( _, __, { uuid } ) => (
                  providesIds( [ uuid ], 'ConvertibleLoanNote' )
            )
        } ),
        postConvertibleLoanNote: builder.mutation<IORCompanyConvertibleLoanNote, Partial<IORCompanyConvertibleLoanNoteMainAttributes>>( {
            query: ( data ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/convertibleloannote/`, method: 'post', data
            } ),
            invalidatesTags: () => (
                  providesList( [], 'ConvertibleLoanNotes' )
            )
        } ),
        deleteConvertibleLoanNote: builder.mutation<void, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/convertibleloannote/${ uuid }`, method: 'delete'
            } ),
            invalidatesTags: ( _, __, uuid ) => (
                  providesList( [ uuid ], 'ConvertibleLoanNotes', 'ConvertibleLoanNote' )
            ),
        } ),
    } ),
} );

export const {
    useGetOneConvertibleLoanNoteQuery,
    useLazyGetOneConvertibleLoanNoteQuery,
    useGetManyConvertibleLoanNotesQuery,
    useLazyGetManyConvertibleLoanNotesQuery,
    usePatchConvertibleLoanNoteMutation,
    usePostConvertibleLoanNoteMutation,
    useDeleteConvertibleLoanNoteMutation,
    endpoints: convertibleLoanNoteApiEndpoints,
} = convertibleLoanNoteApi;