const dsn = process.env.NODE_ENV === 'production'?(process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN):'';
const packageJson = require('./package.json');
const release = process.env.NEXT_PUBLIC_OR_TYPE + '@' + packageJson.version;

const config = {
    dsn,
    tracesSampleRate: 1.0,
    maxValueLength: 1_000_000,
    attachStacktrace: true,
    release,
    debug: false,
};

export default config;