import { type FC, memo, type PropsWithChildren, type ReactNode } from 'react';

import Popconfirm, { type PopconfirmProps } from 'antd/lib/popconfirm';
// import { SVGIcon } from '@/components/structural/images';

// import warningIcon from '@/icons/exclamation-triangle-fill.svg';


export interface IConfirmDeleteProps
{
    visible?: boolean,
    title?: string,
    onOk: () => void,
    onCancel?: () => void,
    // defaults to Delete
    okText?: ReactNode,
    okButtonClassName?: string,
    icon?: ReactNode,
    placement?: PopconfirmProps['placement'],
}

export const ConfirmAction: FC<PropsWithChildren<IConfirmDeleteProps>> = memo( ( {
    okText = 'Delete',
    title = 'Are you sure?',
    ...props
} ) =>
{
    const icon = null; //props.icon || <SVGIcon src={ warningIcon.src } className="!text-danger dark:!text-danger-dark"/>;

    return ( <>
        <Popconfirm
              title={ title }
              open={ props.visible }
              onConfirm={ props.onOk }
              onCancel={ props.onCancel }
              okText={ okText }
              okButtonProps={ {
                  type: 'primary',
                  danger: okText === 'Delete',
                  className: props.okButtonClassName || ''
              } }
              icon={ icon }
              placement={ props.placement }
        >
            <span>{ props.children }</span>
        </Popconfirm>
    </> );
} );

ConfirmAction.displayName = 'ConfirmAction';