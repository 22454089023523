import { processError } from '@/shared/processError';


export const replaceStyleSheet = ( id: string, fileName: string ) =>
{
    try
    {
        const ss = document.getElementsByTagName( 'link' );
        let item: HTMLLinkElement;
        for ( let i = ss.length - 1; i >= 0; i-- )
        {
            item = ss.item( i );
            if ( item.getAttribute( 'id' ) !== id ) continue;
            if ( item.getAttribute( 'href' ) === fileName ) return;
            break;
        }

        const newSs = document.createElement( 'link' );
        newSs.setAttribute( 'id', id );
        newSs.setAttribute( 'rel', 'stylesheet' );
        newSs.setAttribute( 'href', fileName );

        const head = document.head || document.getElementsByTagName( 'head' )[ 0 ];
        head.prepend( newSs );

        item && item.remove();
    } catch ( e )
    {
        processError( 'Error replacing stylesheet', id, fileName, e );
    }
};

export const isStyleSheetInPlace = ( id: string, fileName: string ): boolean =>
{
    try
    {
        const ss = document.getElementsByTagName( 'link' );
        for ( let i = ss.length - 1; i >= 0; i-- )
        {
            const item = ss.item( i );
            if ( item.getAttribute( 'id' ) === id && item.getAttribute( 'href' ) === fileName )
            {
                return true;
            }
        }
        return false;
    } catch ( e )
    {
        processError( 'Error checking stylesheet', id, fileName, e );
        return false;
    }
};
