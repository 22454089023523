import { useMemo } from 'react';

import { selectAppMessages } from '@/store/notifications/selectors';
import { useAppSelector } from '@/store/hooks';


export const useMessages = () =>
{
    const messages = useAppSelector( selectAppMessages );

    return useMemo( () => ( {
        messages
    } ), [ messages ] );
};