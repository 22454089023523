import { type FC, memo, useCallback, useState } from 'react';
import { type ColorResult, SketchPicker } from 'react-color';

import { Pill } from '@/components/structural/Pill';

import { COMPANY_COLOURS } from '@/shared/constants';


export interface IColourPickerProps
{
    className?: string,
    pillClassName?: string,
    onChange?: ( colour: string ) => void,
    value?: string,
    presetColors?: string[],
}

export const ColourPicker: FC<IColourPickerProps> = memo( ( props ) =>
{
    const [ showPicker, setShowPicker ] = useState( false );
    const togglePicker = useCallback( () => setShowPicker( ( value ) => !value ), [] );
    const onChangeComplete = useCallback( ( colour: ColorResult ) =>
    {
        props.onChange?.( colour.hex );
        togglePicker();
    }, [ props.onChange ] );

    return ( <div className={ `${ props.className }` }>
        <Pill
              onClick={ togglePicker }
              key={ props.value }
              text=""
              size="md"
              customBgColour={ props.value }
              customColour="#fff"
              className={ `${ props.pillClassName }` }
        />
        { showPicker && <div className="absolute z-[2]">
            <div className="fixed inset-0" onClick={ togglePicker }/>
            <SketchPicker
                  presetColors={ props.presetColors || COMPANY_COLOURS }
                  color={ props.value }
                  onChangeComplete={ onChangeComplete }
                  className="mt-2"
            />
        </div> }
    </div> );
} );

ColourPicker.displayName = 'ColourPicker';