import { type FC, memo } from 'react';

import { useAppDispatch } from '@/store/hooks';

import { doLogin } from '@/store/auth/actions';

import { AuthPageFormButton } from '@/features/auth/components';
import { Form, FormItem, Input, InputPassword } from '@/components/structural/form';
import { SVGIcon } from '@/components/structural/images';

import lockIcon from '@/icons/lock-fill.svg';


export interface IAuthLoginFormProps
{
    className?: string,
}

export const AuthLoginForm: FC<IAuthLoginFormProps> = memo( ( props ) =>
{
    const dispatchAction = useAppDispatch();

    const process = ( values: { email: string, password: string } ) =>
    {
        dispatchAction( doLogin( {
            email: values.email.toLocaleLowerCase().trim(),
            password: values.password
        } ) );
    };

    return ( <>
        <Form
              name="auth"
              onFinish={ process }
              className={ props.className }
        >
            <FormItem
                  label="Email"
                  name="email"
                  rules={ [ { required: true, message: 'Please provide your email!', type: 'email' } ] }
            >
                <Input/>
            </FormItem>

            <FormItem
                  label="Password"
                  name="password"
                  rules={ [ { required: true, message: 'Please provide your password!' } ] }
            >
                <InputPassword
                      prefix={ <SVGIcon { ...lockIcon }/> }
                />
            </FormItem>

            <FormItem wrapperCol={ { span: 24 } }>
                <AuthPageFormButton>
                    LOG IN
                </AuthPageFormButton>
            </FormItem>
        </Form>
    </> );
} );

AuthLoginForm.displayName = 'AuthLoginForm';
