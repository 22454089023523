import { Button, DataFormat, Tooltip } from '@/components/structural';
import { getCustomSortByOption } from '@/components/tables/sorting-tools/getCustomSortByOption';
import type { ICellActions, ISorter, ORColumnsType } from '@/components/tables';
import { mapCellActions, searchData, SortByOption } from '@/components/tables';
import { prepareColumnItem } from '@/components/tables/sorting-tools/prepareColumnItem';
import { SVGIcon } from '@/components/structural/images';

import type { IORCompanyFilingLogItem } from '@/features/company/shared/models';
import type { ORJSON } from '@/shared/models';
import type { ORTableRowVariant } from '@/components/tables/Table';
import type { IORUser } from '@/features/user/models';

import { formatWordsWithSeparator } from '@/shared/tools';

import addedIcon from '@/icons/plus-square.svg';
import editedIcon from '@/icons/pencil-square.svg';
import deletedIcon from '@/icons/trash-fill.svg';
import infoIcon from '@/icons/info-circle.svg';


export const FILINGS_ITEM_REQUEST_ACTION_ICONS = {
    Added: addedIcon.src,
    Edited: editedIcon.src,
    Deleted: deletedIcon.src,
};

export interface ITableData extends ORJSON
{
    key: string,
    rowVariant: ORTableRowVariant,
    submitted_date: IORCompanyFilingLogItem['queued_at'],
    type: IORCompanyFilingLogItem['transaction_type'],
    // detail: IORCompanyFilingLogItem['transaction_summary'],
    user: IORCompanyFilingLogItem['queued_by'],
    state: 'Awaiting Approval' | 'Pending' | 'Approved' | 'Completed' | 'Rejected',
    accepted_date: IORCompanyFilingLogItem['initiated_at'],
    completed_date: IORCompanyFilingLogItem['completed_at'],
    // ch_forms: string[],
    rejected_reason: string,
    entity: IORCompanyFilingLogItem,
    created_at: string,
    updated_at: string,
}

export const getFilingState = ( logItem: IORCompanyFilingLogItem ): ITableData['state'] =>
{
    if ( !logItem ) return 'Pending';
    if ( !logItem.completed_at )
    {
        if ( logItem.initiated_at ) return 'Pending';
        if ( logItem.approved_by ) return 'Approved';
        if ( logItem.queued_by ) return 'Awaiting Approval';
    }
    if ( logItem.is_successful ) return 'Completed';
    return 'Rejected';
};

export const getParsedData = (
      rawData: IORCompanyFilingLogItem[],
      users: IORUser[],
      filter?: string
): ITableData[] =>
{
    if ( !rawData ) return [];

    const variant: ORJSON<ORTableRowVariant> = {
        'Awaiting Approval': 'warning',
        'Pending': 'info',
        'Approved': 'primary',
        'Completed': 'success',
        'Rejected': 'danger'
    };

    const data: ITableData[] = rawData.map( logItem =>
    {
        const key = logItem.uuid;
        const state = getFilingState( logItem );
        const rowVariant = variant[ state ];
        const submitted_date = logItem.queued_at;
        const type = logItem.transaction_type;
        // const detail = logItem.transaction_summary;
        const user = users.find( u => u.uuid === logItem.queued_by )?.full_name || 'Unknown';
        const accepted_date = logItem.initiated_at || '';
        const completed_date = logItem.completed_at || '';
        // const ch_forms = [];
        const rejected_reason = '';
        const entity = logItem;
        const created_at = logItem.queued_at;
        const updated_at = logItem.last_updated_at;

        return {
            key,
            rowVariant,
            submitted_date,
            type,
            // detail,
            user,
            state,
            accepted_date,
            completed_date,
            // ch_forms,
            rejected_reason,
            entity,
            created_at,
            updated_at,
        };
    } );

    if ( filter )
    {
        return searchData<ITableData>( data, filter );
    }

    return data;
};

export const getColumns = (
      sortByColumn: ISorter,
      actions: ICellActions<ITableData>
): ORColumnsType<ITableData> =>
{
    /*const customOnCellShow = (cols = 8) => ( record: ITableData ) => ( {
        colSpan: record.rowVariant === 'info' ? cols : 1,
    } );
    const customOnCellHide = ( record: ITableData ) => ( {
        colSpan: record.rowVariant === 'info' ? 0 : 1,
    } );*/
    return ( [
        prepareColumnItem<ITableData>( {
            title: 'Date Submitted',
            key: 'submitted_date',
            render: text => <DataFormat raw={ text } type="dateTime" link/>,
            // onCell: customOnCellShow(1),
        }, sortByColumn ),
        prepareColumnItem<ITableData, 'type'>( {
            title: 'Type',
            key: 'type',
            render: text => <DataFormat raw={ formatWordsWithSeparator( text, '_' ) } link/>,
            // onCell: customOnCellHide,
        }, sortByColumn ),
        /*prepareColumnItem<ITableData>( {
            title: 'Detail',
            key: 'detail',
            render: text => <DataFormat raw={ text } link/>,
            // onCell: customOnCellHide,
        }, sortByColumn ),*/
        prepareColumnItem<ITableData>( {
            title: 'User',
            key: 'user',
            render: text => <DataFormat raw={ text } link/>,
            // onCell: customOnCellHide,
        }, sortByColumn ),
        prepareColumnItem<ITableData, 'state'>( {
            title: <div>State&nbsp;<Tooltip title={ <>
                <div className="mb-2">
                    The State of your Companies House transaction tells you if the information has been sent and accepted or if there are any outstanding actions.
                </div>
                <div className="mb-2">
                    <strong className="text-primary dark:text-default-dark">Draft</strong>: A draft transaction has not yet been submitted to Companies House. You can open the transaction and submit
                    when
                    you are ready.
                </div>
                <div className="mb-2">
                    <strong className="text-primary dark:text-default-dark">Pending</strong>: You’ve submitted the information to Companies House but it has not yet been updated or accepted by the
                    Registrar of Companies.
                </div>
                <div className="mb-2">
                    <strong className="text-primary dark:text-default-dark">Accepted</strong>: Your submission has been accepted by Companies House and the public records have been updated
                    accordingly.
                </div>
                <div>
                    <strong className="text-primary dark:text-default-dark">Rejected</strong>: Your submission has been rejected by Companies House. You’ll find more detail attached to the transaction
                    in
                    question.
                </div>
            </> }><span><SVGIcon src={ infoIcon.src } className="inline-flex -mb-1 !w-5 !h-5"/></span></Tooltip></div>,
            key: 'state',
            render: state => <>
                { state === 'Completed' && <Button type="success" className="w-full">COMPLETED</Button> }
                { state === 'Approved' && <Button type="primary" className="w-full">APPROVED</Button> }
                { state === 'Pending' && <Button type="info" className="w-full">PENDING</Button> }
                { state === 'Rejected' && <Button type="danger" className="w-full">REJECTED</Button> }
            </>,
            // onCell: customOnCellHide,
        }, sortByColumn ),
        prepareColumnItem<ITableData>( {
            title: 'Accepted Date',
            key: 'accepted_date',
            render: text => <DataFormat raw={ text } type="dateTime" link/>,
            // onCell: customOnCellHide,
        }, sortByColumn ),
        prepareColumnItem<ITableData>( {
            title: 'Completed Date',
            key: 'completed_date',
            render: text => <DataFormat raw={ text } type="dateTime" link/>,
            // onCell: customOnCellHide,
        }, sortByColumn ),
        /*prepareColumnItem<ITableData, 'ch_forms'>( {
            title: 'CH Form',
            key: 'ch_forms',
            render: ch_forms => <DataFormat raw={ ch_forms.join( ', ' ) } link/>,
            // onCell: customOnCellHide,
        }, sortByColumn ),*/
    ].map( c => mapCellActions<ITableData>( c, actions ) ) );
};

export let sortByKeys: string[] = [];
export const getSortByOptions = () =>
{
    const options = [
        SortByOption<ITableData>( {
            key: 'submitted_date',
            title: 'Most Recent',
            type: 'date',
            direction: 'descend'
        } ),
        SortByOption<ITableData>( {
            key: 'submitted_date',
            title: 'Oldest',
            type: 'date',
            direction: 'ascend'
        } ),
    ];

    options.push( getCustomSortByOption() );
    sortByKeys = options.map( option => option.props.value );

    return options;
};
