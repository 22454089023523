import { memo, type ReactNode, useCallback } from 'react';
import tw from 'tailwind-styled-components';

import { useCurrentCompany } from '@/store/company/hooks';
import { usePatchCompanyMutation } from '@/store/company';

import { Button, Card, Col, Link, Loader, routerNavigate, Row, Typography } from '@/components/structural';
import { SVGIcon } from '@/components/structural/images';
import { useCompanyQuickstartStatus } from '@/features/company/my-company/hooks';

import stepCompleteIcon from '@/icons/check.svg';


export const CompanyQuickstartGuide = memo( () =>
{
    const [ patchCompany ] = usePatchCompanyMutation();
    const { orCompany, isFetching: companyIsFetching } = useCurrentCompany();

    const { allStepsDone, activeStep, isStepComplete, dataIsLoading } = useCompanyQuickstartStatus();

    const navigateToShareClasses = useCallback( () => routerNavigate( '/share-capital/share-classes' ), [] );
    const navigateToStakeholders = useCallback( () => routerNavigate( '/stakeholders' ), [] );
    const navigateToShareRounds = useCallback( () => routerNavigate( '/share-capital/share-rounds' ), [] );
    const completeWelcomeSetup = useCallback( () =>
    {
        orCompany && patchCompany( {
            uuid: orCompany.uuid,
            data: {
                setup_complete: true
            }
        } );
        routerNavigate( '/my-company' );
    }, [ orCompany ] );

    if ( companyIsFetching || dataIsLoading ) return <Loader/>;

    return ( <>
        <Card className="pb-8 mt-4 shadow-lg">
            <Typography.Title className="!text-2xl">
                Company Quickstart Guide
            </Typography.Title>
            { !allStepsDone && <Typography.Paragraph className="font-roboto">
                Complete these steps to configure your first Company
            </Typography.Paragraph> }
            { allStepsDone && makeCard( {
                className: 'mb-0',
                number: 0,
                title: 'All done!',
                active: activeStep === '4',
                complete: isStepComplete( '4' ),
                content: <>
                    <Typography.Paragraph>
                        You’ve now completed your account set up.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        If you need to add in new information in the future, you’ll be able to do it in
                        exactly the same way that you used for set up. You can also edit records by clicking on the
                        relevant record in each table. Simply navigate to the correct table and then click
                        on the hyperlink in the first column.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        You can close this Guide now and start using your OneRegistry account.
                    </Typography.Paragraph>
                    <ButtonWrapper>
                        <Button type="default" onClick={ completeWelcomeSetup } className="w-56">
                            CLOSE
                        </Button>
                    </ButtonWrapper>
                </>,
            } ) }
            { !allStepsDone && makeCard( {
                number: 1,
                title: 'Add Share Classes',
                active: activeStep === '1',
                complete: isStepComplete( '1' ),
                content: <>
                    <Typography.Paragraph>
                        First things first, you need to configure&nbsp;
                        <LinkStyled to="/share-capital/share-classes">Share Classes</LinkStyled>.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        To navigate to Share Classes simply click on Share Capital in the top-level navigation.&nbsp;
                        You can choose either to add them individually by clicking on the +Add button, or, if you prefer,&nbsp;
                        you can download our handy template from the download button.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        Fill in the fields on the spreadsheet and then simply upload it back in to the table by&nbsp;
                        dragging and dropping it in, or by using the upload button.
                    </Typography.Paragraph>
                </>,
                button: {
                    onClick: navigateToShareClasses,
                    title: '+ ADD SHARE CLASSES',
                    className: 'w-56'
                },
                contentComplete: <>
                    <Typography.Paragraph>
                        You&apos;ve added <LinkStyled to="/share-capital/share-classes">Share Classes</LinkStyled>.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        If you need to edit the information you added or need to add more, simply navigate back to the Share Classes page.
                    </Typography.Paragraph>
                </>,
            } ) }
            { !allStepsDone && makeCard( {
                number: 2,
                title: 'Add Stakeholders',
                active: activeStep === '2',
                complete: isStepComplete( '2' ),
                content: <>
                    <Typography.Paragraph>
                        Next you&apos;ll need to add&nbsp;
                        <LinkStyled to="/stakeholders">Stakeholders</LinkStyled>.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        To navigate to Stakeholders simply click on Stakeholders in the top-level navigation.&nbsp;
                        You can choose either to add them individually by clicking on the +Add&nbsp;
                        button, or, if you prefer, you can download our handy template from the download button.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        Fill in the fields on the spreadsheet and then simply upload it back in to the table by&nbsp;
                        dragging and dropping it in, or by using the upload button. You’ll issue shares to&nbsp;
                        your Stakeholders in the next step.
                    </Typography.Paragraph>
                </>,
                button: {
                    onClick: navigateToStakeholders,
                    title: '+ ADD STAKEHOLDERS',
                    className: 'w-56'
                },
                contentComplete: <>
                    <Typography.Paragraph>
                        You&apos;ve added <LinkStyled to="/stakeholders">Stakeholders</LinkStyled>.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        If you need to edit the information you added or need to add more, simply navigate back to the Stakeholders page.
                    </Typography.Paragraph>
                </>,
            } ) }
            { !allStepsDone && makeCard( {
                number: 3,
                title: 'Add Share Rounds',
                active: activeStep === '3',
                complete: isStepComplete( '3' ),
                content: <>
                    <Typography.Paragraph>
                        Next you&apos;ll need to add any previous&nbsp;
                        <LinkStyled to="/share-capital/share-rounds">Rounds</LinkStyled>.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        To navigate to Rounds, simply click on Share Capital in the top-level navigation.&nbsp;
                        First of all we’ll ask you to set up the Round, including information such as how much&nbsp;
                        capital you raised. You can choose either to add them individually by clicking on the +Add button,&nbsp;
                        or, if you prefer, you can download our handy template from the download&nbsp;
                        button. Fill in the fields on the spreadsheet and simply upload it back in to the table by dragging&nbsp;
                        and dropping it in, or by using the upload button.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        To add Shareholders to the Round, simply select the relevant record from the table and then&nbsp;
                        add Shareholders manually or in bulk following the same steps you’ve used previously.
                    </Typography.Paragraph>
                </>,
                button: {
                    onClick: navigateToShareRounds,
                    title: '+ ADD ROUNDS',
                    className: 'w-56'
                },
                contentComplete: <>
                    <Typography.Paragraph>
                        You&apos;ve added <LinkStyled to="/share-capital/share-rounds">Rounds</LinkStyled>.
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                        If you need to edit the information you added or need to add more, simply navigate back to the Rounds page.
                    </Typography.Paragraph>
                </>,
            } ) }
        </Card>
    </> );
} );

CompanyQuickstartGuide.displayName = 'CompanyQuickstartGuide';

const makeCard = ( props: {
    number: number,
    title: ReactNode,
    content: ReactNode,
    contentComplete?: ReactNode,
    active?: boolean,
    complete?: boolean,
    button?: {
        onClick: () => void,
        title: ReactNode,
        className?: string,
    },
    className?: string,
} ): ReactNode =>
{
    const number = props.complete ?
          <SVGIcon src={ stepCompleteIcon.src } className="!h-6 !w-6 !m-0"/> :
          props.number;

    const layout = <><Row
          justify="space-around"
          className={ props.number === 0 ? 'text-center' : '' }
    >
        { props.number > 0 && <Col span={ props.button ? 4 : 4 }>
            { ( props.active || props.complete ) ?
                  <NumberRounded>{ number }</NumberRounded> :
                  <NumberRoundedNext>{ number }</NumberRoundedNext>
            }
        </Col> }
        <Col span={ props.button ? 20 : 20 }>
            <CardTitle>
                { props.title }
            </CardTitle>
            <Typography.Paragraph>
                { props.complete ? props.contentComplete : props.content }
            </Typography.Paragraph>
        </Col>
    </Row>
        { props.button && !props.complete && <div className="w-full text-center self-center">
            <Button type="success" className={ props.button.className ?? '' } onClick={ props.button.onClick }>
                { props.button.title }
            </Button>
        </div> }
    </>;

    if ( props.complete ) return <CardStepComplete className={ props.className }>
        { layout }
    </CardStepComplete>;

    return props.active ? <CardCurrentStep className={ props.className }>
        { layout }
    </CardCurrentStep> : <CardNextStep className={ props.className }>
        { layout }
    </CardNextStep>;
};

const NumberRounded = tw.div`
    w-14
    h-14
    rounded-full
    bg-success
    dark:bg-success-dark
    text-white
    dark:text-default-dark
    flex
    place-content-center
    place-items-center
    text-2xl
`;

const NumberRoundedNext = tw( NumberRounded )`
    bg-default-dark-gray
    text-default
    dark:text-default-dark
`;

const CardBase = tw( Card )`
    mb-4
    pt-4
`;

const CardCurrentStep = tw( CardBase )`
    bg-default-light-gray
    dark:bg-default-dark
    pt-6
`;

const CardNextStep = tw( CardBase )`
    border-default
`;

const CardStepComplete = tw( CardBase )`
    bg-default-light-gray
    dark:bg-default-dark
`;

const CardTitle = tw( Typography.Paragraph )`
    text-primary
    dark:text-primary-dark
`;

const LinkStyled = tw( Link )`
    text-primary 
    dark:text-primary-dark
`;

const ButtonWrapper = tw.div`
    grid 
    place-items-center
`;

