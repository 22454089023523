import { type FC, memo, type PropsWithChildren, type ReactNode } from 'react';
import tw from 'tailwind-styled-components';

import { useCurrentUser } from '@/store/user/hooks';

import Typography from 'antd/lib/typography';

import { Card } from '@/components/structural';


export interface IWidgetSimpleWrapperProps
{
    title?: string,
    variant?: 'success' | 'primary' | 'info' | 'warning' | 'danger' | 'default',
    extraContentTopRight?: ReactNode | undefined,
    onClick?: () => void,
    className?: string,
    innerWrapperClassName?: string,
}

export const WidgetSimpleWrapper: FC<PropsWithChildren<IWidgetSimpleWrapperProps>> = memo( ( props ) =>
{
    const { settings } = useCurrentUser();
    const isDarkMode = settings?.dark_mode || false;

    return ( <>
        <CardWrapper
              $variant={ props.variant || 'default' }
              styles={ {
                  header: { color: isDarkMode ? null : '#fff' },
                  body: { padding: 0, height: '100%', display: 'grid', justifyItems: 'normal', alignItems: 'start' }
              } }
              className={ `${ props.onClick ? 'hover:cursor-pointer' : '' } ${ props.className || '' } shadow` }
              onClick={ props.onClick }
        >
            <div className={ `flex flex-col p-8 gap-4 h-full ${ props.innerWrapperClassName }` }>
                { ( props.extraContentTopRight || props.title ) &&
                      <div className="self-start font-medium flex w-full">
                          { props.title && <Typography.Text
                                ellipsis={ { tooltip: props.title } }
                                className="mt-1.5 inline-block flex-grow w-16 text-2xl text-primary dark:text-primary-dark"
                          >
                              { props.title }
                          </Typography.Text> }
                          { props.extraContentTopRight }
                      </div> }
                <div>
                    { props.children }
                </div>
            </div>
        </CardWrapper>
    </> );
} );

WidgetSimpleWrapper.displayName = 'WidgetSimpleWrapper';

const CardWrapper = tw( Card )<{ $variant: IWidgetSimpleWrapperProps['variant'] }>`
    

    border-0
    border-l-[1.25rem]
    
    ${ props => props.$variant === 'default' ? `
    border-white
        dark:border-background-dark
    ` : '' }
    ${ props => props.$variant === 'success' ? `
    border-success
        dark:border-success-dark
    ` : '' }
    ${ props => props.$variant === 'primary' ? `
    border-primary
        dark:border-primary-dark
    ` : '' }
    ${ props => props.$variant === 'info' ? `
    border-info
        dark:border-info-dark
    ` : '' }
    ${ props => props.$variant === 'warning' ? `
    border-warning
        dark:border-warning-dark
    ` : '' }
    ${ props => props.$variant === 'danger' ? `
    border-danger
        dark:border-danger-dark
    ` : '' }
`;
