import { put, takeEvery } from 'redux-saga/effects';

import { clearInternalState } from '@/store/internal/actions';
import { doLogoutClearState } from '@/store/auth/actions';


function* onLogoutScript()
{
    yield put( clearInternalState() );
}

export function* onLogoutSaga()
{
    yield takeEvery( [ doLogoutClearState ], onLogoutScript );
}