import { type FC, memo } from 'react';

import { Button, Typography } from '@/components/structural';
import { SVGIcon } from '@/components/structural/images';

import checkImage from '@/icons/check-circle-fill.svg';


export interface IStepEndProps
{
    className?: string,
    onSubmit?: () => void,
    firstTimeLaunch: boolean,
}

export const StepEnd: FC<IStepEndProps> = memo( ( props ) =>
{
    return ( <>
        <div className={ `${ props.className }` }>
            <div className="grid place-items-center w-full h-40 my-12">
                <SVGIcon src={ checkImage.src } className="h-full w-full text-success dark:text-success-dark"/>
            </div>

            <Typography.Title level={ 3 }>
                Success!
            </Typography.Title>

            { props.firstTimeLaunch && <Typography.Paragraph className="font-roboto">
                You&apos;ve completed onboarding
            </Typography.Paragraph> }

            <Typography.Paragraph className="font-roboto">
                Once the dashboard has launched, you’ll see a helpful ‘Getting Started Guide’ that will
                take you through the next steps to get your Cap Table loaded on to the platform.
            </Typography.Paragraph>

            <Button type="success" className="w-full mt-12" onClick={ props.onSubmit }>
                { props.firstTimeLaunch ? 'LAUNCH' : 'UPDATE' } PLATFORM
            </Button>
        </div>
    </> );
} );

StepEnd.displayName = 'CompanyOnboardStepEnd';