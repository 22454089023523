/** @type {import('tailwindcss').Config} */
module.exports = {
    content: [
        './src/pages/**/*.{ts,tsx}',
        './src/components/**/*.{ts,tsx}',
        './src/features/**/*.{ts,tsx}'
    ],
    darkMode: 'class', // or 'media' or 'class' or false
    important: false,
    corePlugins: {
        preflight: false
    },
    theme: {
        extend: {
            boxShadow: {
                '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
                '3xl': '0 35px 60px -15px rgba(0, 0, 0, 0.3)',
                'bottom-only': '0 5px 5px -5px rgba(0, 0, 0, 0.06)'
            },
            colors: {
                'current': 'currentColor',

                'Biscay': '#193963',
                'Biscay-hover': '#1f406a',
                'BlueZodiac': '#13294c',
                'CaribbeanGreen': '#00d6a3',
                'CaribbeanDarkGreen': '#00b388', // this is the initial brand green
                'CeriseRed': '#e03854',
                'Chambray': '#2e5484',
                'DarkGray': '#E2E2E2',
                'DarkishPurple': '#3346d3',
                'DoveGray': '#666666',
                'LightBlue': '#1e4370',
                'LightGray': '#f8f9fa',
                'LighterGray': '#f0f2f8',
                'LightPurple': '#7175d8',
                'LightYellow': '#FCD27C',
                'DarkerYellow': '#ffba3b',
                'Loblolly': '#bac1cb',
                'Mercury': '#e4e4e4',
                'DarkishGray': '#888b8d',
                'Polar': '#e0f8ef',
                'SilverChalice': '#9e9e9e',
                'Silver': '#bababa',
                'WildSand': '#f5f5f5',

                /* Some helper variables */
                'border': {
                    DEFAULT: '#e4e4e4',
                    dark: '#434343'
                },
                'background': {
                    DEFAULT: 'hsla(0,0%,89%,.6)',
                    medium: 'hsla(0,0%,58%,.6)',
                    light: '#f5f5f5',
                    dark: 'hsla(0,0%,10%,1)',
                    disabled: '#f0f2f8',
                    'item-selected': 'rgba(0,179,136,0.2)'
                },
                'light': '#f5f5f5',
                'dark': '#666666',
                'danger': {
                    DEFAULT: '#e03853',
                    dark: '#842132',
                    hover: 'rgba(224,56,84,0.7)',
                    'hover-dark': 'rgba(132,33,50,0.7)',
                    bg: '#e0385467',
                    'table-row': '#e038534d'
                },
                'default': {
                    DEFAULT: '#888b8d',
                    alt: '#707070',
                    bg: '#888b8d67',
                    disabled: '#888b8d9a',
                    dark: 'rgba(255,255,255,0.85)',
                    darker: '#a3a6b4',
                    'dark-gray': '#e2e2e2',
                    hover: 'rgba(136,139,141,0.7)',
                    'hover-dark': 'rgba(255,255,255,0.55)',
                    'hover-light': 'rgba(255, 255, 255, 0.08)',
                    light: 'hsla(0,0%,89%,.7)',
                    lighter: 'hsla(0,0%,95%,0.8)',
                    'light-dark': 'hsla(0,0%,89%,.9)',
                    'light-gray': '#f0f2f8',
                    'form-field': '#888b8d80',
                    'list-item': '#888b8d60'
                },
                'info': {
                    DEFAULT: '#7175d8',
                    dark: '#5f608d',
                    hover: 'rgba(113,117,216,0.7)',
                    'hover-dark': 'rgba(95,96,141,0.75)',
                    light: '#abafff',
                    updated: '#717fd867',
                    bg: '#717fd867',
                    'table-row': '#7175d84d'
                },
                'primary': {
                    DEFAULT: '#1e4370',
                    dark: 'rgba(255, 255, 255, 0.85)',
                    hover: 'rgba(19,41,76,0.7)',
                    'hover-dark': 'rgba(255,255,255,0.55)',
                    light: '#1e4370',
                    'light-dark': '#26292c',
                    lighter: '#506c93',
                    'lighter-dark': '#2c3b50',
                    bg: '#1E437067',
                    'bg-draft': '#609df250',
                    'table-row': '#609df24d'
                },
                'secondary': {
                    DEFAULT: '#6b6f71',
                    dark: 'rgba(255,255,255,0.45)',
                    hover: 'rgba(107,111,113,0.7)',
                    'hover-dark': 'rgba(255,255,255,0.75)'
                },
                'success': {
                    DEFAULT: '#00b388',
                    dark: '#005c46',
                    hover: 'rgba(0,179,136,0.7)',
                    'hover-dark': 'rgba(0,92,70,0.7)',
                    bg: '#00b38850',
                    'table-row': 'rgba(0,179,136,0.1)',
                    'table-row-dark': 'rgba(0,92,70,0.1)'
                },
                'warning': {
                    DEFAULT: '#f3bc56',
                    dark: '#926722',
                    hover: 'rgba(255,186,59,0.7)',
                    'dark-hover': 'rgba(146,103,34,0.7)',
                    bg: '#f3bc5650'
                }
            },
            fontFamily: {
                roboto: '"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                poppins: '"Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Poppins, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
            },
            gridTemplateColumns: {
                '15/85': '15% 85%',
                '20/80': '20% 80%',
                '25/75': '25% 75%',
                '30/70': '30% 70%',
                '33/66': '33% 66%',
                '40/60': '40% 60%',
                '60/40': '60% 40%',
                '66/33': '66% 33%',
                '70/30': '70% 30%',
                '75/25': '75% 25%',
                '80/20': '80% 20%',
                '86/15': '85% 15%',
                '30': 'repeat(30, minmax(0, 1fr))'
            },
            lineHeight: {
                '1': '0.25rem',
                '2': '0.5rem'
            },
            transitionDuration: {
                '0': '0ms',
                '250': '250ms',
                '350': '350ms',
                '400': '400ms',
                '450': '450ms',
                '550': '550ms',
                '600': '600ms',
                '650': '650ms',
                '750': '750ms',
                '800': '800ms',
                '850': '850ms',
                '900': '900ms',
                '950': '950ms',
                '1250': '1250ms',
                '1500': '1500ms',
                '1750': '1750ms',
                '2000': '2000ms'
            },
            transitionProperty: {
                'height': 'height',
                'spacing': 'margin, padding'
            },
            width: {
                '4.5': '1.15rem',
                'p90': '90%',
                'p85': '85%',
                'p80': '80%',
                'p75': '75%',
                'p70': '70%',
                'p66': '66%',
                'p60': '60%',
                'p50': '50%',
                'p40': '40%',
                'p33': '33%',
                'p30': '30%',
                'p25': '25%',
                'p20': '20%',
                'p15': '15%',
                'p10': '10%'
            },
            maxWidth: {
                '90': '90%',
                '85': '85%',
                '80': '80%',
                '75': '75%',
                '70': '70%',
                '66': '66%',
                '60': '60%',
                '50': '50%',
                '40': '40%',
                '33': '33%',
                '30': '30%',
                '25': '25%',
                '20': '20%',
                '15': '15%',
                '10': '10%'
            },
            height: {
                '4.5': '1.15rem'
            }
        },
        fontSize: {
            '5xs': '0.5rem',
            '4xs': '0.6rem',
            '3xs': '0.7rem',
            '2xs': '0.775rem',
            'xs': '0.85rem',
            'sm': '0.925rem',
            'base': '14px',
            'md': '1rem',
            'lg': '1.125rem',
            'xl': '1.25rem',
            '2xl': '1.5rem',
            '3xl': '1.875rem',
            '4xl': '2.25rem',
            '5xl': '3rem',
            '6xl': '4rem',
            '7xl': '5rem'
        },
        screens: {
            '3xs': '281px', // galaxy fold seems to be 280, smallest screen around July 2021
            '2xs': '320px',
            'xs': '568px',
            'sm': '667px',
            'md': '768px',
            'lg': '992px',
            'xl': '1200px',
            '2xl': '1440px',
            '3xl': '1600px',
            '4xl': '1856px',
            '5xl': '1920px',
            '6xl': '2048px'
        }
    },
    plugins: [
        require('tailwind-hamburgers'),
        /* Firefox */
        require('tailwindcss/plugin')(({addVariant, e, postcss}) => {
            addVariant('firefox', ({container, separator}) => {
                const isFirefoxRule = postcss.atRule({
                    name: '-moz-document',
                    params: 'url-prefix()'
                });
                isFirefoxRule.append(container.nodes);
                container.append(isFirefoxRule);
                isFirefoxRule.walkRules((rule) => {
                    rule.selector = `.${e(
                          `firefox${separator}${rule.selector.slice(1)}`
                    )}`;
                });
            });
        })
    ]
};
