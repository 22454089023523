import axios, { type AxiosError } from 'axios';

import { authService } from '@/store/auth';
import { createMessage } from '@/store/notifications/actions';
import { doLogout, setAWSUser } from '@/store/auth/actions';
import { HTTPErrorOccurred } from '@/store/internal/actions';
import { useAppDispatch } from '@/store/hooks';

import { getMimeTypeByExtension } from '@/features/datastore/tools';

import { getCookie } from '@/shared/tools/cookies';
import { delayAsync } from '@/shared/tools/delayAsync';
import { handleHTTPResponseError } from '@/shared/tools/handleHTTPResponseError';
import { processError } from '@/shared/processError';

import type { IRequestError, ORJSON } from '@/shared/models';


export interface IDownloadFromBackendProps
{
    url: string;
    name: string;
    dispatch: ReturnType<typeof useAppDispatch>;
    returnBlobOnly?: boolean;
}

let downloadTries = 0;
export const downloadFromBackend = async <T extends IDownloadFromBackendProps>( props: T )
      : Promise<T['returnBlobOnly'] extends true ? Blob : void> =>
{
    const headers: ORJSON<string> = {
        authorization: `Bearer ${ getCookie( { cName: 'orToken' } ) }`
    };

    try
    {
        const { data } = await axios( {
            url: props.url.startsWith( 'https' ) ? props.url : `${ process.env.NEXT_PUBLIC_OR_API }${ props.url }`,
            method: 'get',
            responseType: 'blob',
            headers
        } );

        downloadTries = 0;

        const blob = new Blob( [ data ], { type: getMimeTypeByExtension( props.name ) } );

        if ( props.returnBlobOnly ) return blob as T['returnBlobOnly'] extends true ? Blob : never;

        const fileLink = document.createElement( 'a' );
        fileLink.href = window.URL.createObjectURL( blob );
        fileLink.download = props.name;
        fileLink.click();

        props.dispatch( createMessage( {
            content: `"${ fileLink.download }" has been successfully downloaded. Please check your local Downloads folder.`,
            type: 'success'
        } ) );

        return undefined as T['returnBlobOnly'] extends true ? never : void;
    } catch ( axiosErrorUntyped )
    {
        const axiosError = axiosErrorUntyped as AxiosError;

        if ( axiosError.response?.status === 401 && downloadTries < 5 )
        {
            try
            {
                const awsUser = await authService.refreshToken();
                props.dispatch( setAWSUser( awsUser ) );
            } catch ( e )
            {
                const error: IRequestError = {
                    code: '529',
                    name: 'Auth Exception',
                    message: 'Could not re-authenticate user. Logging out...'
                };

                props.dispatch( HTTPErrorOccurred( error ) );
                props.dispatch( doLogout() );

                return;
            }

            await delayAsync( 100 );

            downloadTries++;

            return downloadFromBackend( props );
        } else
        {
            const message = axiosError.response?.data[ 'error' ] ? handleHTTPResponseError( axiosError.response?.data[ 'error' ] ) : axiosError.message;
            const error: IRequestError = {
                code: axiosError.code,
                message,
                name: axiosError.name
            };

            props.dispatch( HTTPErrorOccurred( error ) );
            processError( 'downloadFromBackend Error', axiosError, error );

            downloadTries = 0;
        }
    }
};