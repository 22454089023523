import { forwardRef, memo } from 'react';
import { type BaseSelectRef } from 'rc-select';
import tw from 'tailwind-styled-components';
import styled from 'styled-components';

import SelectAntD, { type RefSelectProps as RefSelectPropsAntd, type SelectProps as SelectPropsAntd } from 'antd/lib/select';


const SelectOR = tw( SelectAntD )`
    w-full
    relative
`;

const SelectStyled = styled( SelectOR )`
    &.ant-select-lg:not(.ant-select-multiple) .ant-select-selector {
        height: 2.65rem !important;
    }

    &.ant-select-multiple .ant-select-selector {
        padding-inline: 0.25rem !important;
        padding-block: 0.25rem !important;
    }

    &.ant-select-focused .ant-select-selector,
    & .ant-select-selector:focus,
    & .ant-select-selector:active,
    &.ant-select-open .ant-select-selector {
        box-shadow: none !important;
    }

    & .ant-select-selection-overflow {
        gap: 0.25rem;
    }
` as typeof SelectAntD;

export const Select = memo( forwardRef<BaseSelectRef, SelectPropsAntd>(
      ( props, ref ) =>
      {
          const {
              size = 'large',
              placeholder = '...',
              ...rest
          } = props;

          return <SelectStyled
                { ...rest }
                size={ size }
                ref={ ref }
                placeholder={ placeholder }
          />;
      } )
) as unknown as typeof SelectAntD;
Select.displayName = 'Select';

export const Option = SelectAntD.Option;
export const OptGroup = SelectAntD.OptGroup;

export type RefSelectProps = RefSelectPropsAntd;
export type SelectProps = SelectPropsAntd;