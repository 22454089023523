import moment from 'moment';


export const isDate = ( value: unknown ) =>
{
    return value instanceof Date || isUnixDate( value ) || moment.isDate( value ) || moment.isMoment( value ) || moment.isDuration( value );
};

export const isUnixDate = ( value: unknown ) =>
{
    return !Number.isNaN( Number( value ) );
};