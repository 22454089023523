import type { FilterKeys, IFiltersState } from '@/components/tables';


export const filtersChanged = <U>(
      key: string,
      value: U,
      filterKeys: FilterKeys,
      filtersState: IFiltersState,
) =>
{
    // console.log( key, 'new values', JSON.stringify( value ), 'existing values', JSON.stringify( filtersState[ key ] ) );
    if ( JSON.stringify( value ) === JSON.stringify( filtersState[ key ] ) )
    {
        // console.log( 'filter values unchanged' );
        return;
    }

    filtersState[ key ] = {
        ...filtersState[ key ],
        ...value
    };
    // console.log( 'updated values', JSON.stringify( filtersState[ key ] ) );
};