import { createEntityAdapter, type EntityState } from '@reduxjs/toolkit';

import { providesIds, providesList } from '@/store/_rtk-query';

import { companyApi } from './service.company';

import { compare, getCookie } from '@/shared/tools';
import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORCompanyNoteMainAttributes, IORCompanyTag, IORCompanyTagItem, IORCompanyTagItemMainAttributes, IORCompanyTagMainAttributes } from '@/features/company/shared/models';
import type { IORGetParameters } from '@/store/_models/get-parameters.interface';
import type { IORResponseMeta, IORResponseMetaData } from '@/store/_rtk-query/meta-response.interface';


export const tagAdapter = createEntityAdapter<IORCompanyTag, string>( {
    sortComparer: ( a, b ) => compare( a.created_at, b.created_at, true ),
    selectId: ( e ) => e.uuid,
} );

export const tagItemAdapter = createEntityAdapter<IORCompanyTagItem, string>( {
    sortComparer: ( a, b ) => compare( a.created_at, b.created_at, true ),
    selectId: ( e ) => e.uuid,
} );

const addCurrentUser_tag = ( data: Partial<IORCompanyNoteMainAttributes> ) => Object.assign( data, {
    created_by: getCookie( { cName: 'orCurrentUserUuid' } )
} );
const addCurrentUser_tagItem = ( data: Partial<IORCompanyNoteMainAttributes> ) => Object.assign( data, {
    tagged_by: getCookie( { cName: 'orCurrentUserUuid' } )
} );

export const tagApi = companyApi.injectEndpoints( {
    endpoints: ( builder ) => ( {
        getOneTag: builder.query<IORCompanyTag, string>( {
            query: ( uuid ) => ( {
                url: `/temp/tag/${ uuid }`,
                overrideUrl: true,
                method: 'get',
            } ),
            providesTags: ( result ) => (
                  providesIds( [ result?.uuid ], 'Tag' )
            )
        } ),
        getManyTags: builder.query<EntityState<IORCompanyTag, string> & IORResponseMeta, IORGetParameters>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} ) => ( {
                url: `/temp/tag/`,
                overrideUrl: true,
                method: 'get',
                queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                },
            } ),
            providesTags: ( normalisedResult ) => (
                  providesList( normalisedResult?.ids, 'Tags', 'Tag' )
            ),
            transformResponse: ( data: IORCompanyTag[], meta: IORResponseMetaData ) => ( {
                ...tagAdapter.upsertMany( tagAdapter.getInitialState(), data ),
                ...{ meta },
            } ),
        } ),
        patchTag: builder.mutation<IORCompanyTag, { uuid: string, data: Partial<IORCompanyTagMainAttributes> }>( {
            query: ( { uuid, data } ) => ( {
                url: `/temp/tag/${ uuid }/`,
                overrideUrl: true,
                method: 'patch',
                data: addCurrentUser_tag( data ),
            } ),
            invalidatesTags: ( _, __, { uuid } ) => ( [
                ...providesIds( [ uuid ], 'Tag' ),
                ...providesList( [], 'Tags' ),
            ] ),
        } ),
        postTag: builder.mutation<IORCompanyTag, Partial<IORCompanyTagMainAttributes>>( {
            query: ( data ) => ( {
                url: `/temp/tag/`,
                overrideUrl: true,
                method: 'post',
                data: addCurrentUser_tag( data ),
            } ),
            invalidatesTags: () => (
                  providesList( [], 'Tags' )
            ),
        } ),
        deleteTag: builder.mutation<void, string>( {
            query: ( uuid ) => ( {
                url: `/temp/tag/${ uuid }/`,
                overrideUrl: true,
                method: 'delete',
            } ),
            invalidatesTags: ( _, __, uuid ) => (
                  providesList( [ uuid ], 'Tags', 'Tag' )
            ),
        } ),
        getOneTagItem: builder.query<IORCompanyTagItem, string>( {
            query: ( uuid ) => ( {
                url: `/temp/tagitem/${ uuid }`,
                overrideUrl: true,
                method: 'get',
            } ),
            providesTags: ( result ) => (
                  providesIds( [ result?.uuid ], 'TagItem' )
            )
        } ),
        getManyTagItems: builder.query<EntityState<IORCompanyTagItem, string> & IORResponseMeta, IORGetParameters>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} ) => ( {
                url: `/temp/tagitem/`,
                overrideUrl: true,
                method: 'get',
                queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                },
            } ),
            providesTags: ( normalisedResult ) => providesList( normalisedResult?.ids, 'TagItems', 'TagItem' ),
            transformResponse: ( data: IORCompanyTagItem[], meta: IORResponseMetaData ) => ( {
                ...tagItemAdapter.upsertMany( tagItemAdapter.getInitialState(), data ),
                ...{ meta },
            } ),
        } ),
        postTagItem: builder.mutation<IORCompanyTagItem, Partial<IORCompanyTagItemMainAttributes>>( {
            query: ( data ) => ( {
                url: `/temp/tagitem/`,
                overrideUrl: true,
                method: 'post',
                data: addCurrentUser_tagItem( data ),
            } ),
            invalidatesTags: ( { company } ) => ( [
                ...providesList( [], 'TagItems' ),
                ...providesIds( [ company ], 'Company' ),
            ] ),
        } ),
        patchTagItem: builder.mutation<IORCompanyTagItem, { uuid: string, data: Partial<IORCompanyTagItemMainAttributes> }>( {
            query: ( { uuid, data } ) => ( {
                url: `/temp/tagitem/${ uuid }/`,
                overrideUrl: true,
                method: 'patch',
                data: addCurrentUser_tagItem( data ),
            } ),
            invalidatesTags: ( { company }, __, { uuid } ) => ( [
                ...providesIds( [ uuid ], 'TagItem' ),
                ...providesList( [], 'TagItems' ),
                ...providesIds( [ company ], 'Company' ),
            ] ),
        } ),
        deleteTagItem: builder.mutation<void, string>( {
            query: ( uuid ) => ( {
                url: `/temp/tagitem/${ uuid }/`,
                overrideUrl: true,
                method: 'delete',
            } ),
            invalidatesTags: ( _, __, uuid ) => ( [
                ...providesList( [ uuid ], 'TagItems', 'TagItem' ),
                ...providesList( [], 'Companies' ),
            ] ),
        } ),
    } ),
} );

export const {
    useGetOneTagQuery,
    useLazyGetOneTagQuery,
    useGetManyTagsQuery,
    usePatchTagMutation,
    usePostTagMutation,
    useDeleteTagMutation,
    useGetOneTagItemQuery,
    useLazyGetOneTagItemQuery,
    useGetManyTagItemsQuery,
    usePatchTagItemMutation,
    usePostTagItemMutation,
    useDeleteTagItemMutation,
    endpoints: tagApiEndpoints,
} = tagApi;