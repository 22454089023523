import { createAction } from '@reduxjs/toolkit';

import type { IAWSUser } from '@/features/auth/models';
import type { IRequestError } from '@/shared/models';

import { authSliceKey } from '../slice.key';


export const doLogin = createAction<{ email: string, password: string }>( `${ authSliceKey }/doLogin` );
export const doLoginSuccess = createAction<IAWSUser>( `${ authSliceKey }/doLogin/success` );
export const doLoginError = createAction<IRequestError>( `${ authSliceKey }/doLogin/error` );

export const doLoginNoPassword = createAction<{ email: string }>( `${ authSliceKey }/doLoginNoPassword` );