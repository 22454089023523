import { type ClipboardEvent, type FC, type KeyboardEvent, memo, useEffect, useMemo, useRef, useState } from 'react';
import useReactive from 'ahooks/lib/useReactive';

import { doForgotPasswordChange } from '@/store/auth/actions';
import { useAppDispatch } from '@/store/hooks';

import { AuthPageFormButton } from '@/features/auth/components';
import { SVGIcon } from '@/components/structural/images';
import { Form, FormItem, Input, InputPassword, type InputRef, InputTypeNumberNoArrows, useForm, useTouchForm } from '@/components/structural/form';
import { Space, Typography } from '@/components/structural';

import { handleInputBoxCodePaste, setInputBoxCodeDigit } from '@/features/auth/tools';

import { getCookie } from '@/shared/tools';

import checkIcon from '@/icons/check.svg';
import crossIcon from '@/icons/x.svg';
import lockIcon from '@/icons/lock-fill.svg';


export interface IAuthChangeForgotPasswordFormProps
{
    className?: string,
}

export const AuthForgotPasswordChangeForm: FC<IAuthChangeForgotPasswordFormProps> = memo( ( props ) =>
{
    const dispatchAction = useAppDispatch();
    const [ form ] = useForm();
    const { touchForm } = useTouchForm();
    const [ password, setPassword ] = useState( '' );

    const refs = useRef<InputRef[]>( [] );
    const codeState = useReactive( {
        '0': '', '1': '', '2': '', '3': '', '4': '', '5': '',
    } );

    const process = ( values: { email: string, password: string } ) =>
    {
        const code = Object.values( codeState ).join( '' );
        dispatchAction( doForgotPasswordChange( {
            password: values.password,
            email: values.email.toLocaleLowerCase().trim(),
            code
        } ) );
    };

    const yesIcon = useMemo( () => <SVGIcon src={ checkIcon.src } className="!w-6 !h-6 text-success dark:text-success-dark"/>, [] );
    const noIcon = useMemo( () => <SVGIcon src={ crossIcon.src } className="!w-6 !h-6 text-danger dark:text-danger-dark"/>, [] );

    const passwordContainsLowerCase = () => /(.*[a-z].*)/.test( password );
    const passwordContainsUpperCase = () => /(.*[A-Z].*)/.test( password );
    const passwordContainsNumbers = () => /(.*\d.*)/.test( password );
    const passwordContainsSymbols = () => /(.*\W.*)/.test( password );
    const passwordContains8to16Chars = () => /^.{8,16}$/.test( password );

    useEffect( () =>
    {
        form.setFieldValue( 'email', getCookie( { cName: 'orNewSignupEmail' } ) );
    }, [ form ] );

    return ( <>
        <Form
              form={ form }
              name="auth"
              layout="vertical"
              requiredMark={ false }
              onFinish={ process }
              onValuesChange={ () => touchForm() }
              validateTrigger="onSubmit"
              className={ props.className }
        >
            <FormItem
                  label="Email"
                  name="email"
                  className="mb-4"
                  rules={ [ { required: true, message: 'Please provide your email!', type: 'email' } ] }
            >
                <Input/>
            </FormItem>

            <FormItem
                  label="Code"
                  className="mb-4"
            >
                <Space size={ 18 } className="justify-between w-full">
                    { Array.from( Array( 6 ).keys() ).map( index => (
                          <InputTypeNumberNoArrows
                                ref={ ( el: InputRef ) => refs.current[ index ] = el }
                                key={ index }
                                type="text"
                                placeholder=""
                                wholeNumbers
                                min={ 0 } max={ 9 }
                                value={ codeState[ index ] }
                                onPaste={ ( e: ClipboardEvent ) => handleInputBoxCodePaste( e.clipboardData.getData( 'text/plain' ), codeState, refs.current ) }
                                onKeyUp={ ( e: KeyboardEvent ) => setInputBoxCodeDigit( e.key, index, codeState, refs.current, e.code ) }
                                className="w-16"
                          />
                    ) ) }
                </Space>
            </FormItem>

            <FormItem
                  label="Create New Password"
                  name="password"
                  className="mb-2"
                  rules={ [ {
                      required: true, validator: async () =>
                      {
                          if ( !passwordContainsLowerCase() ||
                                !passwordContainsUpperCase() ||
                                !passwordContainsNumbers() ||
                                !passwordContainsSymbols() ||
                                !passwordContains8to16Chars() )
                              throw new Error( 'Please provide a valid password!' );
                      }
                  } ] }
            >
                <InputPassword
                      prefix={ <SVGIcon { ...lockIcon }/> }
                      onChange={ ( { target: { value } } ) => setPassword( value ) }
                />
            </FormItem>

            <Typography.Paragraph className="text-xs text-default dark:text-default-dark font-roboto grid grid-rows-2 mb-4">
                <div>
                    Your password must contain: <br/>
                </div>
                <div className="flex content-center">
                    { passwordContainsLowerCase() ? yesIcon : noIcon }&nbsp;Lower case&nbsp;
                    { passwordContainsUpperCase() ? yesIcon : noIcon }&nbsp;Upper case&nbsp;
                    { passwordContainsNumbers() ? yesIcon : noIcon }&nbsp;Numbers&nbsp;
                    { passwordContainsSymbols() ? yesIcon : noIcon }&nbsp;Symbols&nbsp;
                    { passwordContains8to16Chars() ? yesIcon : noIcon }&nbsp;8-16 characters
                </div>
            </Typography.Paragraph>

            <FormItem wrapperCol={ { span: 24 } }>
                <AuthPageFormButton>
                    RESET PASSWORD
                </AuthPageFormButton>
            </FormItem>
        </Form>
    </> );
} );

AuthForgotPasswordChangeForm.displayName = 'AuthForgotPasswordChangeForm';