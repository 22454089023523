import { type FC } from 'react';
import tw from 'tailwind-styled-components';

import { DataFormat, Pill } from '@/components/structural';
import { SVGIcon } from '@/components/structural/images';

import type { ICHCompanyProfile } from '@/features/company/shared/models';

import crossIcon from '@/icons/x.svg';

import * as sicCodesMap from '@/data/sic-codes.json';


export interface ISICCodesPillsListProps
{
    sic_codes: ICHCompanyProfile['sic_codes'],
    className?: string,
    asPills?: boolean,
    asList?: boolean,
    onRemove?: ( code: string ) => void,
}

export const SICCodesList: FC<ISICCodesPillsListProps> = ( { sic_codes, className, asPills, asList, onRemove } ) =>
{
    return ( <>
        <div className={ className }>
            { sic_codes.map( ( code ) =>
            {
                const text = `${ code } ${ sicCodesMap[ code ] }`;
                return ( asPills
                            ?
                            <Pill text={ text } key={ code }/>
                            :
                            asList
                                  ?
                                  <ListItem key={ code }>
                                      <div className="inline-block">{ text }</div>
                                      <RemoveButton src={ crossIcon.src } onClick={ onRemove }/>
                                  </ListItem>
                                  :
                                  <DataFormat className="mb-4" raw={ text } key={ code }/>
                );
            } ) }
        </div>
    </> );
};

const ListItem = tw.div`
    flex
    content-center
    items-center
    justify-between
    bg-default-list-item
    rounded
    py-2
    px-4
    mb-2
`;

const RemoveButton = tw( SVGIcon )`
    w-8
    h-8
    inline-block
    hover:cursor-pointer
`;