import { all } from 'redux-saga/effects';

// import { onLoginSaga } from './onLogin.saga';
import { onLogoutSaga } from './onLogout.saga';
import { onPatchCurrentUserSaga } from './onPatchCurrentUser.saga';


export default function* userSagas()
{
    yield all( [
        // onLoginSaga(),
        onLogoutSaga(),
        onPatchCurrentUserSaga(),
    ] );
}