import { type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';

import CardAntD, { type CardProps } from 'antd/lib/card';

import twConfig from '@/shared/tailwindConfig';


export const Card: FC<PropsWithChildren<CardProps & { alternateTableRowBg?: boolean }>> = ( props ) =>
{
    const { alternateTableRowBg, ...propsWithoutExtra } = props;

    return (
          <StyledCard
                { ...propsWithoutExtra }
                styles={ {
                    header: { borderBottomWidth: 0, fontSize: '1.25rem', ...propsWithoutExtra.styles?.header },
                    body: { paddingTop: 0, ...propsWithoutExtra.styles?.body }
                } }

                className={ 'h-full w-full relative ' + propsWithoutExtra.className }
                $alternateTableRowBg={ alternateTableRowBg ?? true }
          >
              { propsWithoutExtra.children }
          </StyledCard> );
};

const StyledCard = styled( CardAntD )<{ $alternateTableRowBg: boolean }>`
    .ant-card-head-title {
        font-size: 1.42857rem;
    }

    & thead {
        th[class*=table-cell] {
            background-color: #ffffff;
            color: ${ twConfig.theme.colors.primary.DEFAULT };
        }
    }

    ${ ( p ) => p.$alternateTableRowBg ? `tbody {
        tr[class*=table-row]:nth-child(odd) {
            background-color: ${ twConfig.theme.colors.background.DEFAULT };
        }
    }` : '' }
    .dark & thead {
        th[class*=table-cell] {
            background-color: #141414; // dark colour from antd
            color: ${ twConfig.theme.colors.primary.dark };
        }
    }

    .dark & tbody {
        tr[class*=table-row]:nth-child(odd) {
            background-color: ${ twConfig.theme.colors.background.dark };
        }
    }
`;