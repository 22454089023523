import { type FC, type ReactNode } from 'react';
import styled from 'styled-components';
import tw from 'tailwind-styled-components';

import { getWordsFirstLettersAsCapitals } from '@/shared/tools';

import { Highlight } from './Highlight';
import { SVGIcon } from './images/SVGIcon';

import twConfig from '@/shared/tailwindConfig';
import { TextWithCutoff } from '@/components/structural/TextWithCutoff';


interface ITitleWithIconProps
{
    title: string,
    hideTitle?: boolean,
    highlight?: string,
    /**
     * If no icon is provided, one is generated from the first 2 letters of the title, as capitals
     */
    icon?: string | ReactNode,
    customIconText?: string,
    className?: string,
    onClick?: () => void,
    iconClassName?: string,
    iconWrapperClassName?: string,
    iconBgColour?: string,
    iconBgColourDark?: string,
    disableEllipsis?: boolean,
    maxCharsCutOff?: number
}

export const TitleWithIcon: FC<ITitleWithIconProps> = ( {
    title,
    hideTitle = false,
    highlight,
    icon,
    customIconText = null,
    className,
    onClick,
    iconClassName = '',
    iconWrapperClassName,
    iconBgColour = twConfig.theme.colors.info.DEFAULT,
    iconBgColourDark = twConfig.theme.colors.info.dark,
    disableEllipsis,
    maxCharsCutOff
} ) => ( <>
    <Container className={ className || '' } onClick={ onClick }>
        { icon ?
              ( typeof icon === 'string'
                          ?
                          <SVGIconWrapper src={ icon } className={ iconClassName }/>
                          :
                          icon
              )
              :
              <IconWrapperStyled className={ 'text-white dark:text-default ' + ( iconWrapperClassName || '' ) }
                                 $iconBgColour={ iconBgColour } $iconBgColourDark={ iconBgColourDark }>
                  <Icon className={ iconClassName }>{ customIconText || getWordsFirstLettersAsCapitals( title, 2 ) }</Icon>
              </IconWrapperStyled>
        }
        { !hideTitle && <div className="flex grow">
            { !highlight ?
                  <TextWithCutoff disableEllipsis={ disableEllipsis } maxCharsCutOff={ maxCharsCutOff }>
                      { title }
                  </TextWithCutoff>
                  :
                  <Highlight text={ title }
                             highlight={ highlight }
                  />
            }</div> }
    </Container>
</> );

const Container = tw.div`
    overflow-hidden
    overflow-ellipsis
    flex
    items-center
`;

const SVGIconWrapper = tw( SVGIcon )`
    mr-1
    relative
    !w-6
    !h-6
`;

const IconWrapper = tw.div`
    inline-flex
    items-center
    justify-center
    rounded-full
    min-w-[1.5rem]
    w-6
    h-6
    mr-1
    p-0.5
    relative
    text-center
`;

const IconWrapperStyled = styled( IconWrapper )<{ $iconBgColour: string, $iconBgColourDark: string }>`
    & {
        background-color: ${ p => p.$iconBgColour };
    }

    .dark & {
        background-color: ${ p => p.$iconBgColourDark };
    }
`;

const Icon = tw.div`
    w-full
    text-4xs
`;