import { createAction } from '@reduxjs/toolkit';

import type { IRequestError } from '@/shared/models';

import { authSliceKey } from '../slice.key';


export const doForgotPassword = createAction<{ email: string }>( `${ authSliceKey }/doForgotPassword` );
export const doForgotPasswordSuccess = createAction<{ email: string }>( `${ authSliceKey }/doForgotPassword/success` );
export const doForgotPasswordError = createAction<IRequestError>( `${ authSliceKey }/doForgotPassword/error` );

export const doForgotPasswordChange = createAction<{ email: string, code: string, password: string }>( `${ authSliceKey }/doForgotPasswordChange` );
export const doForgotPasswordChangeSuccess = createAction<void>( `${ authSliceKey }/doForgotPasswordChange/success` );
export const doForgotPasswordChangeError = createAction<IRequestError>( `${ authSliceKey }/doForgotPasswordChange/error` );