import moment from 'moment';

import { DATE_FORMAT, DATE_TIME_FORMAT } from '@/shared/dateFormats';


export const formatDate = ( value = undefined, format = 'YYYY-MM-DD' ) =>
      moment( value, format )
            .add( moment().utcOffset(), 'minutes' )
            .format( DATE_FORMAT );

export const formatDateTime = ( value = undefined, format = 'YYYY-MM-DD HH:mm:ss' ) =>
      moment( value, format )
            .add( moment().utcOffset(), 'minutes' )
            .format( DATE_TIME_FORMAT );