import { providesList } from '@/store/_rtk-query';

import { companyApi } from './service.company';

import { getCookie } from '@/shared/tools';

import type { IORCompanyShareholdingsSummary } from '@/features/company/shared/models';


export const shareholdingsSummaryApi = companyApi.injectEndpoints( {
    endpoints: ( builder ) => ( {
        getShareholdingsSummary: builder.query<IORCompanyShareholdingsSummary[], void>( {
            query: () => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/shareholdingsummary/`, method: 'get'
            } ),
            providesTags: () => (
                  providesList( [], 'ShareholdingsSummary' )
            ),
        } ),
    } ),
} );

export const {
    useGetShareholdingsSummaryQuery,
    useLazyGetShareholdingsSummaryQuery,
    endpoints: shareholdingsSummaryApiEndpoints,
} = shareholdingsSummaryApi;