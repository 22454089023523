import { useMemo } from 'react';

import { getMetaDefaultObject } from '@/store/_rtk-query/getMetaDefaultObject';
import { useCurrentUser } from '@/store/user/hooks';

import { tagAdapter, useGetManyTagsQuery, useGetOneTagQuery } from '@/store/company';
import { tagSelectors } from '@/store/company/selectors';

import { compare } from '@/shared/tools';
import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORGetParameters } from '@/store/_models/get-parameters.interface';


export const useTags = ( getParameters: IORGetParameters = { page: 1, per_page: DEFAULT_PER_PAGE }, skip = false ) =>
{
    const { awsUser } = useCurrentUser();
    const { data, isFetching, isLoading, error, status } = useGetManyTagsQuery( getParameters, {
        skip: skip || !awsUser
    } );

    const tags = tagSelectors.selectAll(
          data || tagAdapter.getInitialState()
    );

    tags.sort( ( a, b ) => compare( a.created_at, b.created_at ) );

    const meta = data?.meta || getMetaDefaultObject();

    return useMemo( () => ( {
        tags,
        meta,
        isLoading,
        isFetching,
        error,
        status
    } ), [ data, isFetching, isLoading, error, status ] );
};

export const useTag = ( uuid: string, skip = false ) =>
{
    const { awsUser } = useCurrentUser();
    const { data: tag, isFetching, isLoading, error } = useGetOneTagQuery( uuid, {
        skip: skip || !awsUser
    } );

    return useMemo( () => ( {
        tag,
        isLoading,
        isFetching,
        error
    } ), [ tag, isFetching, isLoading, error ] );
};