import { put, takeEvery } from 'redux-saga/effects';

import { doLogoutClearState } from '@/store/auth/actions';
import { accountApi } from '@/store/account';


function* onLogoutScript()
{
    yield put( accountApi.util.resetApiState() );
}

export function* onLogoutSaga()
{
    yield takeEvery( [ doLogoutClearState ], onLogoutScript );
}