import type { ISorterState } from './interfaces';
import { splitOptionValue } from './splitOptionValue';


/**
 * Mutates sortByColumn.visual and sortByColumn.internal
 * @param value
 * @param sortByColumn
 * @param sortByKeys
 */
export const onSortChange = ( value = '', sortByColumn: ISorterState, sortByKeys: string[] = [] ) =>
{
    // console.log( value );
    const [ key, direction ] = splitOptionValue( value );
    // console.log( `Key: ${ key }, Direction: ${ direction }`, sortByColumn.internal, sortByColumn.visual );
    if ( !key && !direction ||
          key === sortByColumn.internal.key && direction === sortByColumn.internal.direction )
    {
        // console.log( 0 );
        return;
    }

    let parsedDirection = direction;
    // console.log( sortByKeys );
    if ( !( sortByKeys.includes( value ) ) )
    {
        if ( !parsedDirection )
        {
            if ( typeof key === 'string' && ( sortByKeys[ 0 ] || '' ).includes( key + '+descend' ) )
            {
                // console.log( 1 );
                parsedDirection = 'ascend';
                sortByColumn.visual.key = '_custom_';
                sortByColumn.visual.direction = 'ascend';
            } else
            {
                // console.log( 2 );
                return onSortChange( sortByKeys[ 0 ], sortByColumn, sortByKeys );
            }
        } else
        {
            // console.log( 3 );
            sortByColumn.visual.key = '_custom_';
            sortByColumn.visual.direction = 'ascend';
        }
    } else
    {
        // console.log( 4 );
        sortByColumn.visual.key = key;
        sortByColumn.visual.direction = parsedDirection;
    }

    if ( !parsedDirection )
    {
        // console.log( 5 );
        sortByColumn.internal.key = null;
        sortByColumn.internal.direction = null;
    } else
    {
        // console.log( 6 );
        sortByColumn.internal.key = key;
        sortByColumn.internal.direction = parsedDirection;
    }

    // console.log( value, sortByColumn.internal, sortByColumn.visual );
};