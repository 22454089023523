import type { Moment } from 'moment';
import { isDate } from '@/shared/tools/isDate';
import { normaliseDate } from '@/shared/tools/normaliseDate';
import { DATE_FORMAT_SERVER } from '@/shared/dateFormats';


export const compare = ( a: Moment | number | string | Date | boolean, b: Moment | number | string | Date | boolean, isAsc = false ) =>
{
    let result: number;

    if ( typeof a === 'number' && typeof b === 'number' )
    {
        // console.log(a, b, isAsc);
        result = a - b;
    } else if ( typeof a !== 'boolean' && typeof b !== 'boolean' && isDate( a ) && isDate( b ) )
    {
        // console.log(a, b, isAsc);
        result = ( normaliseDate( a, DATE_FORMAT_SERVER, DATE_FORMAT_SERVER, true ) as Moment ).valueOf() -
              ( normaliseDate( b, DATE_FORMAT_SERVER, DATE_FORMAT_SERVER, true ) as Moment ).valueOf();
    } else if ( typeof a === 'string' && typeof b === 'string' )
    {
        // console.log(a, b, isAsc);
        const aLC = a.toLocaleLowerCase();
        const bLC = b.toLocaleLowerCase();

        result = aLC === bLC ? 0 : ( aLC < bLC ? -1 : 1 );
    } else if ( typeof a === 'boolean' && typeof b === 'boolean' )
    {
        // console.log(a, b, isAsc);
        // @ts-expect-error
        result = a - b;
    } else
    {
        // console.log(a, b, isAsc);
        result = a === b ? 0 : ( a < b ? -1 : 1 );
    }

    // console.log( result, result * ( isAsc ? 1 : -1 ) );
    return result * ( isAsc ? 1 : -1 );
};
