import { useMemo } from 'react';

import { useAuth } from '@/store/auth/hooks';
import { useCurrentIDsGuard } from '@/store/user/hooks/useCurrentIDsGuard.hook';
import { useGetOneUserQuery } from '@/store/user';

import { getCookie } from '@/shared/tools';
import { useAccount } from '@/store/account/hooks';


export const useCurrentUser = ( { fetchAccount = false, skip = false }: { fetchAccount?: boolean, skip?: boolean } = {} ) =>
{
    const { awsUser } = useAuth();
    const uuid = getCookie( { cName: 'orCurrentUserUuid' } );

    const { data, isFetching, isLoading, error, status } = useGetOneUserQuery( uuid, {
        skip: skip || !awsUser || !uuid
    } );

    const currentAccountUuid = data?.settings?.currentAccountUuid || data?.default_account;
    const currentCompanyUuid = data?.settings?.currentCompanyUuid;
    const currentUserUuid = data?.uuid;
    const userType = data?.settings?.user_type;

    const { account } = useAccount( currentAccountUuid, !fetchAccount );

    if ( fetchAccount && account )
    {
        Object.assign( data, { account } );
    }

    useCurrentIDsGuard( !!awsUser, currentAccountUuid, currentCompanyUuid, currentUserUuid );

    return useMemo( () => ( {
        me: data,
        awsUser,
        settings: data?.settings || {},
        currentAccountUuid,
        currentCompanyUuid,
        userType,
        isLoading,
        isFetching,
        error,
        status
    } ), [ data, awsUser, isFetching, isLoading, error, status, currentUserUuid, currentAccountUuid, currentCompanyUuid, userType ] );
};