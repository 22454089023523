import { createSlice } from '@reduxjs/toolkit';

import { configureReduxPersist } from '@/store/configureReduxPersist';

import { portfolioSliceKey } from './slice.key';

import type { IPortfolioState } from './state.interface';


const initialState: IPortfolioState = {
    PLACEHOLDER: 'NOT_USED'
};

const _slice = createSlice( {
    name: portfolioSliceKey,
    initialState,
    /**
     * The reducers property both creates an action creator function and responds to that action in the slice reducer.
     * Actions need to be exported, see ./actions/index.ts and list these reducers there
     */
    reducers: {
        clearPortfolioState: () =>
        {
            return initialState;
        }
    },
    /**
     * Use extraReducers when you are dealing with an action that you have already defined somewhere else.
     * The most common examples are responding to a createAsyncThunk action and responding to an action from another slice.
     */
    /*extraReducers: ( builder ) =>
    {}*/
} );

// export const datastoreSlice = _slice.reducer;
export const portfolioSlice = configureReduxPersist<IPortfolioState>( portfolioSliceKey, _slice.reducer );

export const actions = _slice.actions;
