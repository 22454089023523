import { useMemo } from 'react';

import { getMetaDefaultObject } from '@/store/_rtk-query/getMetaDefaultObject';
import { useCurrentUser } from '@/store/user/hooks';

import { responsiblePartyAdapter, useGetManyResponsiblePartiesQuery, useGetOneResponsiblePartyQuery } from '@/store/company';
import { responsiblePartySelectors } from '@/store/company/selectors';

import { compare } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORGetParameters } from '@/store/_models/get-parameters.interface';


export const useResponsibleParties = ( getParameters: IORGetParameters = { page: 1, per_page: DEFAULT_PER_PAGE }, skip = false ) =>
{
    const { awsUser } = useCurrentUser();
    const { data, isFetching, isLoading, error } = useGetManyResponsiblePartiesQuery( getParameters, {
        skip: skip || !awsUser
    } );

    const responsibleParties = responsiblePartySelectors.selectAll(
          data || responsiblePartyAdapter.getInitialState()
    );

    responsibleParties.sort( ( a, b ) => compare( a.created_at, b.created_at ) );

    const meta = data?.meta || getMetaDefaultObject();

    return useMemo( () => ( {
        responsibleParties,
        meta,
        isLoading,
        isFetching,
        error
    } ), [ data, isFetching, isLoading, error ] );
};

export const useResponsibleParty = ( uuid: string, skip = false ) =>
{
    const { awsUser } = useCurrentUser();
    const { data: responsibleParty, isFetching, isLoading, error } = useGetOneResponsiblePartyQuery( uuid, {
        skip: skip || !awsUser
    } );

    return useMemo( () => ( {
        responsibleParty,
        isLoading,
        isFetching,
        error
    } ), [ responsibleParty, isFetching, isLoading, error ] );
};