import { useMemo } from 'react';

import { getMetaDefaultObject } from '@/store/_rtk-query/getMetaDefaultObject';
import { useCurrentCompany } from '@/store/company/hooks/useCurrentCompany.hook';

import { shareClassAdapter, useGetManyShareClassesQuery } from '@/store/company';
import { shareClassSelectors } from '@/store/company/selectors';

import { compare } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORCompanyShareClass } from '@/features/company/shared/models';
import type { IORGetParameters } from '@/store/_models/get-parameters.interface';


export const useShareClasses = ( getParameters: IORGetParameters = { page: 1, per_page: DEFAULT_PER_PAGE }, skip = false ) =>
{
    const { orCompany } = useCurrentCompany();
    const { data, isFetching, isLoading, error } = useGetManyShareClassesQuery( getParameters, {
        skip: skip || !orCompany
    } );

    const shareClasses = shareClassSelectors.selectAll(
          data ||
          shareClassAdapter.getInitialState()
    );

    shareClasses.sort(
          ( a, b ) =>
                compare( a.updated_at, b.updated_at )
    );

    // parse all Share Classes and create a list with the most recent versions, adding the past versions
    // to the "past_versions" array
    const latestShareClassesMap: { [ linked_share_class_uuid: string ]: IORCompanyShareClass } = {};

    for ( const obj of shareClasses )
    {
        if ( !latestShareClassesMap[ obj.linked_share_class_uuid ] )
        {
            // If it's the first time we're seeing this linked UUID, add it to the result
            latestShareClassesMap[ obj.linked_share_class_uuid ] = obj;
        } else
        {
            // If we've seen this linked UUID before, check if the current object has a valid_from value
            if ( obj.valid_from )
            {
                // If the current object has a valid_from value, it's the latest version
                // So, add the current latest version to the history of the new latest version
                const currentHistory = latestShareClassesMap[ obj.linked_share_class_uuid ].past_versions || [];
                const updatedHistory = [ ...currentHistory, { ...latestShareClassesMap[ obj.linked_share_class_uuid ] } ];

                // Update the latest version
                latestShareClassesMap[ obj.linked_share_class_uuid ] = {
                    ...obj,
                    past_versions: updatedHistory
                };
            } else
            {
                // If the current object doesn't have a valid_from value, it's not the latest version
                // So, add the current object to the history of the latest version
                const currentHistory = latestShareClassesMap[ obj.linked_share_class_uuid ].past_versions || [];
                const updatedHistory = [ ...currentHistory, { ...obj } ];

                // Update the history of the latest version
                latestShareClassesMap[ obj.linked_share_class_uuid ] = {
                    ...latestShareClassesMap[ obj.linked_share_class_uuid ],
                    past_versions: updatedHistory
                };
            }
        }
    }

    const shareClassesGrouped = Object.values( latestShareClassesMap );

    const meta = data?.meta || getMetaDefaultObject();

    return useMemo( () => ( {
        shareClasses: shareClassesGrouped,
        meta,
        isLoading,
        isFetching,
        error
    } ), [ data, isFetching, isLoading, error ] );
};