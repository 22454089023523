import { useCallback, useState } from 'react';


export const useTouchForm = () =>
{
    const [ , setFormTouched ] = useState( {} );
    const [ formIsPristine, setFormIsPristine ] = useState( true );

    const touchForm = useCallback( ( keepPristine = false ) =>
    {
        setFormTouched( {} );

        if ( keepPristine )
        {
            setFormIsPristine( true );
        } else
        {
            setFormIsPristine( false );
        }
    }, [] );

    return {
        formIsPristine,
        touchForm
    };
};