import { removeCookies } from './cookies';


export const clearAllCookiesAndCaches = (): void =>
{
    // Retrieve all cookies from the document
    const allCookies = document.cookie.split( ';' ).map( cookie => cookie.trim().split( '=' )[ 0 ] );

    // Use removeCookies to remove all cookies
    if ( allCookies.length > 0 )
    {
        removeCookies( ...allCookies );
    }

    // Clear localStorage
    localStorage.clear();

    // Clear sessionStorage
    sessionStorage.clear();
};