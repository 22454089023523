import { createAction } from '@reduxjs/toolkit';

import type { IRequestError } from '@/shared/models';

import { authSliceKey } from '../slice.key';


export const doLogout = createAction<void>( `${ authSliceKey }/doLogout` );
export const doLogoutClearState = createAction<void>( `${ authSliceKey }/doLogout/clearState` );
export const doLogoutSuccess = createAction<void>( `${ authSliceKey }/doLogout/success` );
export const doLogoutError = createAction<IRequestError>( `${ authSliceKey }/doLogout/error` );