import { useMemo } from 'react';

import { useAppSelector } from '@/store/hooks';
import { selectAppAlerts } from '@/store/notifications/selectors';

import type { AlertChannel } from '@/features/notifications/models';


export const useAlerts = ( channel?: AlertChannel ) =>
{
    let alerts = useAppSelector( selectAppAlerts );

    if ( channel )
    {
        alerts = alerts.filter( alert => alert.channel === channel );
    }

    return useMemo( () => ( {
        alerts
    } ), [ alerts ] );
};