import { useMemo } from 'react';

import { useGetOneByIDQuery } from '@/store/company/service.ch';


export const useCompanyChById = ( { uuid }: { uuid?: string }, skip = false ) =>
{
    const { data, isFetching, isLoading, error } = useGetOneByIDQuery( uuid, {
        skip: skip || !uuid
    } );

    return useMemo( () => ( {
        result: data,
        isLoading,
        isFetching,
        error
    } ), [ data, isFetching, isLoading, error ] );
};