import { useEffect, useMemo, useState } from 'react';


export const usePageLoaded = ( pageLoadedCb?: () => void ) =>
{
    const [ pageLoaded, setPageLoaded ] = useState( false );

    useEffect( () =>
    {
        if ( typeof window === 'undefined' ) return;

        // callback function to call when event triggers
        const onPageLoad = () =>
        {
            // console.log( 'page loaded' );
            setPageLoaded( true );
            pageLoadedCb?.();
        };

        if ( document.readyState === 'complete' )
        {
            onPageLoad();
        } else
        {
            window.addEventListener( 'load', onPageLoad, false );
            return () => window.removeEventListener( 'load', onPageLoad );
        }
    }, [] );

    return useMemo( () => ( {
        pageLoaded, setPageLoaded
    } ), [ pageLoaded, setPageLoaded ] );
};