import { put, takeEvery } from 'redux-saga/effects';

import { clearPortfolioState } from '../actions';
import { doLogoutClearState } from '@/store/auth/actions';
import { portfolioApi } from '@/store/portfolio';


function* onLogoutScript()
{
    yield put( portfolioApi.util.resetApiState() );

    yield put( clearPortfolioState() );
}

export function* onLogoutSaga()
{
    yield takeEvery( [ doLogoutClearState ], onLogoutScript );
}