import { createEntityAdapter, type EntityState } from '@reduxjs/toolkit';

import { providesIds, providesList } from '@/store/_rtk-query';

import { companyApi } from './service.company';

import { compare, getCookie } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORCompanyOptionIssue, IORCompanyOptionIssueMainAttributes } from '@/features/company/shared/models';
import type { IORGetParameters } from '@/store/_models/get-parameters.interface';
import type { IORResponseMeta, IORResponseMetaData } from '@/store/_rtk-query/meta-response.interface';


export const optionIssueAdapter = createEntityAdapter<IORCompanyOptionIssue, string>( {
    sortComparer: ( a, b ) => compare( a.created_at, b.created_at, true ),
    selectId: ( e ) => e.uuid,
} );

export const optionIssueApi = companyApi.injectEndpoints( {
    endpoints: ( builder ) => ( {
        getOneOptionIssue: builder.query<IORCompanyOptionIssue, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/option-issues/${ uuid }`, method: 'get'
            } ),
            providesTags: ( result ) => (
                  providesIds( [ result?.uuid ], 'OptionIssue' )
            ),
        } ),
        getManyOptionIssues: builder.query<EntityState<IORCompanyOptionIssue, string> & IORResponseMeta, IORGetParameters>( {
            query: ( { page = 1, per_page = DEFAULT_PER_PAGE, query = undefined, ordering = undefined, filter = undefined } = {} ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/option-issues/`, method: 'get', queryParams: {
                    per_page,
                    page,
                    query,
                    ordering,
                    filter,
                }
            } ),
            providesTags: ( normalisedResult ) => (
                  providesList( normalisedResult?.ids, 'OptionIssues', 'OptionIssue' )
            ),
            transformResponse: ( data: IORCompanyOptionIssue[], meta: IORResponseMetaData ) => ( {
                ...optionIssueAdapter.upsertMany( optionIssueAdapter.getInitialState(), data ),
                ...{ meta }
            } )
        } ),
        patchOptionIssue: builder.mutation<IORCompanyOptionIssue, { uuid: string, data: Partial<IORCompanyOptionIssueMainAttributes> }>( {
            query: ( { uuid, data } ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/option-issues/${ uuid }`, method: 'patch', data
            } ),
            invalidatesTags: ( _, __, { uuid, data: { option_round } } ) => ( [
                ...providesIds( [ uuid ], 'OptionIssue' ),
                ...providesIds( [ option_round ], 'OptionRound' ),
                ...providesList( [], 'Shareholdings' ),
                ...providesList( [], 'ShareholdingsSummary' )
            ] )
        } ),
        postOptionIssue: builder.mutation<IORCompanyOptionIssue, Partial<IORCompanyOptionIssueMainAttributes>>( {
            query: ( data ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/option-issues/`, method: 'post', data
            } ),
            invalidatesTags: ( _, __, { option_round } ) => ( [
                ...providesIds( [ option_round ], 'OptionRound' ),
                ...providesList( [], 'OptionIssues' ),
                ...providesList( [], 'Shareholdings' ),
                ...providesList( [], 'ShareholdingsSummary' )
            ] )
        } ),
        deleteOptionIssue: builder.mutation<void, string>( {
            query: ( uuid ) => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/option-issues/${ uuid }`, method: 'delete'
            } ),
            invalidatesTags: ( _, __, uuid ) => ( [
                ...providesList( [ uuid ], 'OptionIssues', 'OptionIssue' ),
                ...providesList( [], 'OptionRounds' ),
                ...providesList( [], 'Shareholdings' ),
                ...providesList( [], 'ShareholdingsSummary' )
            ] ),
        } ),
    } ),
} );

export const {
    useGetOneOptionIssueQuery,
    useLazyGetOneOptionIssueQuery,
    useGetManyOptionIssuesQuery,
    useLazyGetManyOptionIssuesQuery,
    usePatchOptionIssueMutation,
    usePostOptionIssueMutation,
    useDeleteOptionIssueMutation,
    endpoints: optionIssueApiEndpoints,
} = optionIssueApi;