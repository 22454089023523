import NextApp, { type AppProps } from 'next/app';
import { type AppInitialProps } from 'next/dist/shared/lib/utils';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { type FC, Suspense, useEffect, useRef, useState } from 'react';
import { END } from 'redux-saga';
import NextNProgress from 'nextjs-progressbar';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

import { authSliceKey } from '@/store/auth';
import { companyApiKey } from '@/store/company/service.key';
import { type PersistorStore, type SagaStore, storeWrapper, TRootState } from '@/store';

import { App, ConfigProvider, LoaderFullscreen } from '@/components/structural';

import { AppLayoutAuthenticated, AppLayoutGuest } from '@/features/layout';
import { usePageLoaded, usePageTitle } from '@/features/layout/hooks';

import { processError } from '@/shared/processError';
import { SentryErrorBoundary } from '@/components/SentryErrorBoundary';

import configureLibs from '@/_libs-config/configureLibs';
import setupPolyfills from '@/_libs-config/setupPolyfills';
import setupAxiosInterceptors from '@/_libs-config/setupAxiosInterceptors';

import { themeConfig } from '@/styles/themeConfig';

import '@/styles/globals.css';


if ( typeof window !== 'undefined' )
{
    setupPolyfills();

    if ( process.env.NODE_ENV === 'development' )
    {
        const reportWebVitals = require( '@/_libs-config/reportWebVitals' );
        reportWebVitals.default( ( message: string, metric: string ) =>
        {
            console.warn( message, metric );
        }, false );
    }
}

const appLayoutPropsRouteMap = {
    '/my-company/onboard': {
        hideBreadcrumbs: true,
        hideHeader: true,
        hideTopViewToggle: true,
    },
    '/portfolio/projections': {
        showPageViewSelectorMap: true,
    }
};

const ORApp: FC<AppProps> = ( { Component, ...rest } ) =>
{
    const router = useRouter();
    const { store, props } = storeWrapper.useWrappedStore( rest );
    const [ isAuthenticated, setIsAuthenticated ] = useState(
          router.pathname !== '/' && !router.pathname.startsWith( '/auth' ) ||
          !!store.getState()?.auth?.awsUser
    );
    const isAuthenticatedRef = useRef(
          router.pathname !== '/' && !router.pathname.startsWith( '/auth' ) ||
          !!store.getState()?.auth?.awsUser
    );
    const { pageTitleWithDash } = usePageTitle();
    const { pageLoaded, setPageLoaded } = usePageLoaded();
    const [ showLoader, setShowLoader ] = useState( true );

    useEffect( () =>
    {
        configureLibs();

        const unsubscribe = store.subscribe( () =>
        {
            const { actions: { lastAction }, auth: { awsUser } = {} } = store.getState() as TRootState;

            if ( !lastAction ) return;

            // user is authenticated, but still transitioning to the app
            if ( !isAuthenticatedRef.current && awsUser )
            {
                isAuthenticatedRef.current = true;
                const loginCheckInterval = setInterval( () =>
                {
                    const onLoginPage = window?.location.pathname.endsWith( '/auth/login' );
                    if ( !onLoginPage )
                    {
                        clearInterval( loginCheckInterval );
                        setIsAuthenticated( true );
                    }
                }, 75 );
            }

            // has login process started?
            if ( !lastAction.isApi &&
                  lastAction.storeSliceKey === authSliceKey &&
                  lastAction.endpoint.startsWith( 'doLogin' )
            )
            {
                setPageLoaded( false );
            }

            // is login complete?
            else if ( lastAction.isApi &&
                  lastAction.storeSliceKey === companyApiKey &&
                  lastAction.endpoint === 'getManyCompanies' &&
                  lastAction.type === QueryStatus.fulfilled
            )
            {
                setPageLoaded( true );
            }

            // has logout process started?
            else if ( !lastAction.isApi &&
                  lastAction.storeSliceKey === authSliceKey &&
                  lastAction.endpoint === 'doLogout'
            )
            {
                setPageLoaded( false );
                const logoutCheckInterval = setInterval( () =>
                {
                    const onLoginPage = window?.location.pathname.endsWith( '/auth/login' );
                    if ( onLoginPage )
                    {
                        clearInterval( logoutCheckInterval );
                        setPageLoaded( true );
                        setIsAuthenticated( false );
                        isAuthenticatedRef.current = false;
                    }
                }, 100 );
            }
        } );

        return () =>
        {
            unsubscribe();
        };
    }, [ store ] );

    useEffect( () =>
    {
        setupAxiosInterceptors( store.dispatch );
    }, [ store.dispatch ] );

    useEffect( () =>
    {
        if ( pageLoaded )
        {
            const loaderTimeout = setTimeout( () => setShowLoader( false ), 250 );
            return () => clearTimeout( loaderTimeout );
        } else
        {
            setShowLoader( true );
        }
    }, [ pageLoaded ] );

    useEffect( () =>
    {
        const startTime = Date.now();

        const fallbackInterval = setInterval( () =>
        {
            const elapsedTime = Date.now() - startTime;

            if ( elapsedTime >= 7500 || showLoader === false )
            {
                if ( showLoader )
                {
                    setPageLoaded( true );
                    setShowLoader( false );
                    setIsAuthenticated( true );
                    isAuthenticatedRef.current = false;
                }
                clearInterval( fallbackInterval );
            }
        }, 100 );

        return () => clearInterval( fallbackInterval );
    }, [ showLoader ] );

    const currentPagePropsMap = appLayoutPropsRouteMap[ router.pathname ] || {};

    return ( <>
        <Head>
            <title>{ pageTitleWithDash }</title>
        </Head>
        <NextNProgress height={ 3 } color={ themeConfig.token.colorPrimary } options={ { showSpinner: false } }/>
        <Provider store={ store }>
            <PersistGate persistor={ ( store as PersistorStore ).__persistor }>
                <ConfigProvider theme={ themeConfig }>
                    <App>
                        <SentryErrorBoundary>
                            <Suspense fallback={ <LoaderFullscreen/> }>
                                { router.pathname === '/landing' ? (
                                      <Component { ...props.pageProps } />
                                ) : (
                                      isAuthenticated ? (
                                            <AppLayoutAuthenticated
                                                  hideBreadcrumbs={ currentPagePropsMap.hideBreadcrumbs }
                                                  showPageViewSelector={ currentPagePropsMap.showPageViewSelector }
                                                  hideHeader={ currentPagePropsMap.hideHeader }
                                                  hideTopViewToggle={ currentPagePropsMap.hideTopViewToggle }
                                            >
                                                <Component { ...props.pageProps } />
                                            </AppLayoutAuthenticated>
                                      ) : (
                                            <AppLayoutGuest>
                                                <Component { ...props.pageProps } />
                                            </AppLayoutGuest>
                                      )
                                ) }
                            </Suspense>
                            <LoaderFullscreen isVisible={ showLoader }/>
                        </SentryErrorBoundary>
                    </App>
                </ConfigProvider>
            </PersistGate>
        </Provider>
    </> );
};

// @ts-expect-error
ORApp.getInitialProps = storeWrapper.getInitialAppProps( ( store: SagaStore ) => async ( context ) =>
{
    let appPageProps: AppInitialProps = { pageProps: {} };

    try
    {
        appPageProps = ( await NextApp.getInitialProps( context ) ).pageProps;
    } catch ( e )
    {
        processError( 'ORApp.getInitialProps appPageProps error: ', e );
    }

    // 1. Wait for all page actions to dispatch
    const pageProps = appPageProps;

    try
    {
        // 2. Stop the saga if on server
        if ( context.ctx.req )
        {
            store.dispatch( END );
            await store.sagaTask.toPromise();
        }
    } catch ( e )
    {
        processError( 'ORApp.getInitialProps stop sagaTask error: ', e );
    }

    // 3. Return props
    return { pageProps };
} );

export default ORApp;
