import { useEffect, useState } from 'react';

import { isStyleSheetInPlace, replaceStyleSheet } from '@/shared/tools';

import type { IORUser } from '@/features/user/models';
import type { IAWSUserLimited } from '@/features/auth/models'; // Adjust the path as necessary


export const useDarkModeStylesheet = ( settings: IORUser['settings'], awsUser: IAWSUserLimited ) =>
{
    const [ currentDarkMode, setCurrentDarkMode ] = useState( settings?.dark_mode );

    useEffect( () =>
    {
        const updateStylesheet = ( darkMode: IORUser['settings']['dark_mode'] ) =>
        {
            if ( !awsUser || !settings || !darkMode )
            {
                if ( !isStyleSheetInPlace( 'active-stylesheet', '/styles/themes/light.css' ) )
                {
                    replaceStyleSheet( 'active-stylesheet', '/styles/themes/light.css' );
                }
                document.body.classList.remove( 'dark', 'scrollbar-dark' );
                document.body.classList.add( 'scrollbar-light' );
            } else
            {
                if ( !isStyleSheetInPlace( 'active-stylesheet', '/styles/themes/dark.css' ) )
                {
                    replaceStyleSheet( 'active-stylesheet', '/styles/themes/dark.css' );
                }
                document.body.classList.remove( 'scrollbar-light' );
                document.body.classList.add( 'dark', 'scrollbar-dark' );
            }
        };

        // Initial load
        updateStylesheet( currentDarkMode );

        // Watch for changes in settings.dark_mode
        if ( settings && settings.dark_mode !== currentDarkMode )
        {
            setCurrentDarkMode( settings.dark_mode );
            updateStylesheet( settings.dark_mode );
        }

    }, [ awsUser, settings, currentDarkMode ] );
};
