import { useCallback, useEffect, useMemo, useState } from 'react';

import { useCurrentCompany, useShareClasses, useShareRounds, useStakeholders } from '@/store/company/hooks';


export const useCompanyQuickstartStatus = () =>
{
    const { shareClasses, isFetching: shareClassesAreFetching } = useShareClasses();
    const { stakeholders, isFetching: stakeholdersAreFetching } = useStakeholders();
    const { shareRounds, isFetching: shareRoundsAreFetching } = useShareRounds();
    const { orCompany } = useCurrentCompany();
    const dataIsLoading = shareClassesAreFetching || stakeholdersAreFetching || shareRoundsAreFetching;

    const [ activeStep, setActiveStep ] = useState( '1' );
    const [ completedSteps, setCompletedSteps ] = useState<string[]>( [] );
    const [ allStepsDone, setAllStepsDone ] = useState( false );
    const isStepComplete = useCallback( ( step: string ) => (
          completedSteps.includes( step )
    ), [ completedSteps ] );

    useEffect( () =>
    {
        const steps: string[] = [];
        if ( shareClasses?.length ) steps.push( '1' );
        if ( stakeholders?.length ) steps.push( '2' );
        if ( shareRounds?.length ) steps.push( '3' );

        if ( steps.length === 3 )
        {
            steps.push( 'end' );
            setActiveStep( () => 'end' );
        } else
        {
            const newActiveStep = String( steps.length + 1 );
            setActiveStep( newActiveStep );
        }

        setCompletedSteps( steps );
        setAllStepsDone( () => steps.includes( 'end' ) || orCompany?.setup_complete );
    }, [ shareClasses, stakeholders, shareRounds, allStepsDone, activeStep, orCompany ] );

    return useMemo( () => ( {
        activeStep,
        completedSteps,
        allStepsDone,
        isStepComplete,
        dataIsLoading
    } ), [ activeStep, completedSteps, allStepsDone, isStepComplete, dataIsLoading ] );
};