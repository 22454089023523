import { useMemo } from 'react';

import { useGetOnePortfolioEntityQuery } from '@/store/portfolio';
import { useCurrentUser } from '@/store/user/hooks';


export const usePortfolio = ( skip = false ) =>
{
    const { awsUser } = useCurrentUser();
    const { data: portfolio, isFetching, isLoading, error } = useGetOnePortfolioEntityQuery( undefined, {
        skip: skip || !awsUser
    } );

    return useMemo( () => ( {
        portfolio,
        isLoading,
        isFetching,
        error
    } ), [ portfolio, isFetching, isLoading, error ] );
};