import { call, put, takeEvery } from 'redux-saga/effects';

import { authService } from '@/store/auth';
import { doSignup, doSignupError, doSignupSuccess } from '@/store/auth/actions';
import { HTTPErrorOccurred } from '@/store/internal/actions';

import handleError from './handleError';
import { processError } from '@/shared/processError';
import { routerNavigate } from '@/components/structural/Link';
import { setCookie } from '@/shared/tools';


function* signupScript( { payload }: ReturnType<typeof doSignup> )
{
    try
    {
        yield call( authService.signUp, payload );
        yield put( doSignupSuccess( payload ) );
    } catch ( err )
    {
        const error = handleError( err );

        processError( 'onSignup saga', error );

        yield put( HTTPErrorOccurred( error ) );
        yield put( doSignupError( error ) );
    }
}

function* signupSuccessScript( { payload: { email, first_name, last_name } }: ReturnType<typeof doSignupSuccess> )
{
    setCookie( { cName: 'orNewSignupEmail', cValue: email } );
    setCookie( { cName: 'orNewSignupFirstName', cValue: first_name } );
    setCookie( { cName: 'orNewSignupLastName', cValue: last_name } );

    yield routerNavigate( '/auth/signup-success' );
}

export function* onSignupSaga()
{
    yield takeEvery( doSignup, signupScript );
    yield takeEvery( doSignupSuccess, signupSuccessScript );
}