import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import useCreation from 'ahooks/lib/useCreation';
import useReactive from 'ahooks/lib/useReactive';
import tw from 'tailwind-styled-components';

import Typography from 'antd/lib/typography';

import { Checkbox, CheckboxGroup } from '@/components/structural/form';

import { formatStringTitleCase, formatWordsWithSeparator, stringIsAllUpperCase } from '@/shared/tools';

import type { FilterRefType } from '@/components/tables';
import type { ORJSON } from '@/shared/models';
import styled from 'styled-components';


export interface IFiltersCategoryState
{
    values: string[],
    active: boolean
}

export interface ICategoryFilterProps
{
    categories: string[],
    initialSelectedValues?: string[],
    filtersChanged: ( key: string, value: IFiltersCategoryState ) => void,
    title: string,
    attribute: string,
    className?: string,
}

export const CategoryFilter = forwardRef<FilterRefType<ICategoryFilterProps>, ICategoryFilterProps>( ( {
    filtersChanged,
    title,
    attribute,
    categories,
    initialSelectedValues = [],
    className,
}: ICategoryFilterProps, ref ) =>
{
    const freshState = useCreation( () => ( {
        values: [],
        active: false
    } ), [] );

    const initialState = useCreation( () => ( {
        values: initialSelectedValues,
        active: initialSelectedValues.length > 0
    } ), [ initialSelectedValues ] );

    const filtersState = useReactive<ORJSON<IFiltersCategoryState>>( {
        [ attribute ]: initialState
    } );

    const options = useMemo( () => categories?.map( category => (
          <CheckboxStyled
                key={ category }
                value={ category }
                className="!ml-0 font-roboto mb-0.5"
          >
              { category.includes( '-' ) ?
                    formatStringTitleCase( formatWordsWithSeparator( category ) )
                    : (
                          stringIsAllUpperCase( category ) ?
                                category
                                :
                                formatStringTitleCase( category )
                    )
              }
          </CheckboxStyled>
    ) ), [ categories ] );

    useImperativeHandle<ICategoryFilterProps, FilterRefType<ICategoryFilterProps>>( ref, () => ( {
        filtersChanged,
        title,
        attribute,
        categories,
        clear: () =>
        {
            filtersState[ attribute ] = freshState;
        }
    } ) );

    useEffect( () =>
    {
        filtersChanged( attribute, filtersState[ attribute ] );
    }, [ filtersState[ attribute ].values ] );

    useEffect( () =>
    {
        if ( initialState.values.length > 0 )
        {
            filtersState[ attribute ] = initialState;
        }
    }, [ initialState ] );

    return ( <>
        <div className={ className }>
            <Text className="mb-2 font-medium">
                { title }
            </Text>
            <CheckboxGroup
                  onChange={ ( values ) =>
                  {
                      if ( values.length === 0 )
                      {
                          filtersState[ attribute ] = {
                              values: [],
                              active: false
                          };
                      } else
                      {
                          filtersState[ attribute ] = {
                              values: values as string[],
                              active: true
                          };
                      }
                  } }
                  value={ filtersState[ attribute ].values }
            >
                <OptionsContainer className={ `${ options.length > 5 ? '' : '' }` }>
                    { options }
                </OptionsContainer>
            </CheckboxGroup>
        </div>
    </> );
} );

CategoryFilter.displayName = 'CategoryFilter';

const Text = tw( Typography.Text )`
    block
    text-primary
    dark:text-primary-dark
`;

const OptionsContainer = tw.div`
    flex
    flex-col
`;

const CheckboxStyled = styled( Checkbox )`
    & .ant-checkbox {
        display: ruby;

        &.ant-checkbox-checked::after {
            border: none;
        }
    }
`;