import { type FC, memo, type ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { clearAlert } from '@/store/notifications/actions';
import { useAlerts } from '@/store/notifications/hooks';
import { useAppDispatch } from '@/store/hooks';

import { Alert, routerNavigate } from '@/components/structural';
import { SVGIcon } from '@/components/structural/images';

import type { AlertChannel } from '@/features/notifications/models';

import twConfig from '@/shared/tailwindConfig';

import successIcon from '@/icons/check-circle-fill.svg';
import errorIcon from '@/icons/exclamation-triangle-fill.svg';
import draftIcon from '@/icons/info-circle-fill.svg';
import pendingIcon from '@/icons/clock-history.svg';
import warningIcon from '@/icons/x-circle-fill.svg';


const ICONS = {
    success: successIcon.src,
    warning: warningIcon.src,
    info: pendingIcon.src,
    error: errorIcon.src,
    primary: draftIcon.src,
};

interface IAlertsProps
{
    channel?: AlertChannel,
    max?: number,
    className?: string,
    alertClassName?: string,
    slimline?: boolean,
}

export const Alerts: FC<IAlertsProps> = memo( ( { channel = 'global', max = 4, className = '', alertClassName = '', slimline = false } ) =>
{
    const dispatchAction = useAppDispatch();
    const { alerts } = useAlerts();
    const [ activeAlerts, setActiveAlerts ] = useState<ReactNode[]>();

    useEffect( () =>
    {
        if ( !Array.isArray( alerts ) || !alerts.length ) return;

        const maxItems = max > alerts.length ? alerts.length : max;

        setActiveAlerts( alerts.filter( alert => alert.channel === channel ).slice( 0, maxItems ).map( ( alert, index ) =>
        {
            const navigateAction = () =>
            {
                alert.onClickNavigateTo && routerNavigate( alert.onClickNavigateTo );
                alert.dismissOnNavigateTo && dispatchAction( clearAlert( alert ) );
            };

            return (
                  <AlertOR
                        className={ `${ index !== maxItems - 1 ? 'mb-4' : '' } ${ alert.onClickNavigateTo ? 'hover:cursor-pointer' : '' } ${ alertClassName }` }
                        message={ <div onClick={ navigateAction }>{ alert.title }</div> }
                        type={ alert.type }
                        icon={ <span className="ant-alert-icon" onClick={ navigateAction }><SVGIcon src={ ICONS[ alert.type ] }/></span> }
                        showIcon
                        onClose={ () =>
                        {
                            alert.onClose && alert.onClose();
                            dispatchAction( clearAlert( alert ) );
                        } }
                        description={ <div onClick={ navigateAction }>{ alert.content }</div> }
                        banner={ alert.banner ?? true }
                        key={ alert.id + alert.title + alert.type }
                        closable={ alert.closable ?? true }
                  />
            );
        } ) );
    }, [ alerts, dispatchAction ] );

    if ( !activeAlerts )
    {
        return null;
    }

    return ( <>
        <AlertsContainer $slimline={ slimline } className={ `${ className }` }>{ activeAlerts }</AlertsContainer>
    </> );
} );

Alerts.displayName = 'Alerts';

export const AlertsContainer = styled.div<{ $slimline: boolean }>`
    & {
        .ant-alert-banner {
            padding: ${ p => p.$slimline ? '0.2rem' : '1.07143rem' } ${ p => p.$slimline ? '1rem' : '1.07143rem' };
            margin-bottom: ${ p => p.$slimline ? '0.4rem' : '1rem' };

            ${ p => p.$slimline ? `
                    align-items: center;
                ` : '' }
            .ant-alert-icon svg {
                height: ${ p => p.$slimline ? '2.5rem' : '3.2rem' };
                width: ${ p => p.$slimline ? '2.5rem' : '3.2rem' };
            }

            .ant-alert-content {
                ${ p => p.$slimline ? `
                    display: flex;
                    flex-wrap: wrap;
                ` : '' }
                .ant-alert-message {
                    font-size: ${ p => p.$slimline ? '1.4rem' : '1.14286rem' };
                    margin-bottom: ${ p => p.$slimline ? '0' : '0.28571rem' };
                    margin-right: ${ p => p.$slimline ? '1rem' : '0' };
                    width: ${ p => p.$slimline ? '7rem' : 'max-content' };
                }

                .ant-alert-description {
                    font-size: ${ p => p.$slimline ? '1rem' : '1rem' };
                }
            }
        }
    }
`;

const alertIconSizeRem = 3;
const alertCloseSizeRem = 2.5;
export const AlertOR = styled( Alert )`
    & {
        border-radius: 0.6rem;
        border-left-width: 0.75rem;
        border-left-style: solid;
        background-color: #fff;

        .ant-alert-icon {
            align-self: center;

            svg {
                height: ${ alertIconSizeRem + .2 }rem;
                width: ${ alertIconSizeRem + .2 }rem;
            }
        }

        .ant-alert-close-icon {
            align-self: center;

            svg {
                color: ${ twConfig.theme.colors.default.hover };

                height: ${ alertCloseSizeRem }rem;
                width: ${ alertCloseSizeRem }rem;
            }
        }
    }

    &.ant-alert-success {
        border-left-color: ${ twConfig.theme.colors.success.DEFAULT };

        .ant-alert-message {
            color: ${ twConfig.theme.colors.success.DEFAULT };
        }
    }

    &.ant-alert-info {
        border-left-color: ${ twConfig.theme.colors.info.DEFAULT };

        .ant-alert-message {
            color: ${ twConfig.theme.colors.info.DEFAULT };
        }
    }

    &.ant-alert-primary {
        border-left-color: ${ twConfig.theme.colors.primary.DEFAULT };

        .ant-alert-icon {
            color: ${ twConfig.theme.colors.primary.DEFAULT };
        }

        .ant-alert-message {
            color: ${ twConfig.theme.colors.primary.DEFAULT };
        }
    }

    &.ant-alert-warning {
        border-left-color: ${ twConfig.theme.colors.warning.DEFAULT };

        .ant-alert-message {
            color: ${ twConfig.theme.colors.warning.DEFAULT };
        }
    }

    &.ant-alert-error {
        border-left-color: ${ twConfig.theme.colors.danger.DEFAULT };

        .ant-alert-message {
            color: ${ twConfig.theme.colors.danger.DEFAULT };
        }
    }

    .dark & {
        background-color: ${ twConfig.theme.colors.background.dark };
    }

    .dark &.ant-alert-success {
        border-left-color: ${ twConfig.theme.colors.success.dark };

        .ant-alert-message {
            color: ${ twConfig.theme.colors.success.dark };
        }
    }

    .dark &.ant-alert-info {
        border-left-color: ${ twConfig.theme.colors.info.dark };

        .ant-alert-message {
            color: ${ twConfig.theme.colors.info.dark };
        }
    }

    .dark &.ant-alert-primary {
        border-left-color: ${ twConfig.theme.colors.primary.dark };

        .ant-alert-icon {
            color: ${ twConfig.theme.colors.primary.dark };
        }

        .ant-alert-message {
            color: ${ twConfig.theme.colors.primary.dark };
        }
    }

    .dark &.ant-alert-warning {
        border-left-color: ${ twConfig.theme.colors.warning.dark };

        .ant-alert-message {
            color: ${ twConfig.theme.colors.warning.dark };
        }
    }

    .dark &.ant-alert-error {
        border-left-color: ${ twConfig.theme.colors.danger.dark };

        .ant-alert-message {
            color: ${ twConfig.theme.colors.danger.dark };
        }
    }
`;