import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { IDatastoreState } from './state.interface';
import { datastoreSliceKey } from './slice.key';

import type { ITableData } from '@/features/datastore/pages-data/datastore';
import { configureReduxPersist } from '@/store/configureReduxPersist';


const initialState: IDatastoreState = {
    currentFolder: null
};

const _slice = createSlice( {
    name: datastoreSliceKey,
    initialState,
    /**
     * The reducers property both creates an action creator function and responds to that action in the slice reducer.
     * Actions need to be exported, see ./actions/index.ts and list these reducers there
     */
    reducers: {
        setDatastoreCurrentFolder: ( state, action: PayloadAction<ITableData> ) =>
        {
            state.currentFolder = action.payload;
        },
        clearDatastoreState: () =>
        {
            return initialState;
        }
    },
    /**
     * Use extraReducers when you are dealing with an action that you have already defined somewhere else.
     * The most common examples are responding to a createAsyncThunk action and responding to an action from another slice.
     */
    /*extraReducers: ( builder ) =>
    {}*/
} );

// export const datastoreSlice = _slice.reducer;
export const datastoreSlice = configureReduxPersist<IDatastoreState>( datastoreSliceKey, _slice.reducer );

export const actions = _slice.actions;
