import { type FC, memo, type PropsWithChildren } from 'react';
import tw from 'tailwind-styled-components';

import { FullScreenOverlay, FullScreenOverlayInner } from '@/components/structural';
import { Image } from '@/components/structural/images';

import { Messages, Notifications } from '@/features/notifications';
import { useVersionChecker } from '@/features/layout/hooks';


import logoImage from '@/images/logo-OR-full.png';


export const AppLayoutGuest: FC<PropsWithChildren> = memo( ( props ) =>
{
    useVersionChecker();

    return ( <>
        <FullScreenOverlay>
            <FullScreenOverlayInner>
                <ORLogo src={ logoImage.src } height={ 38 } width={ 200 } alt="logo"/>
                { props.children }
            </FullScreenOverlayInner>
        </FullScreenOverlay>
        <Messages/>
        <Notifications/>
    </> );
} );

AppLayoutGuest.displayName = 'AppLayoutGuest';


const ORLogo = tw( Image )`
    relative
    w-full
    text-center
`;