import { providesList } from '@/store/_rtk-query';

import { companyApi } from './service.company';

import { getCookie } from '@/shared/tools';

import type { IORCompanyFundamentalEntry } from '@/features/company/shared/models';


export const fundamentalsApi = companyApi.injectEndpoints( {
    endpoints: ( builder ) => ( {
        getFundamentals: builder.query<IORCompanyFundamentalEntry[], void>( {
            query: () => ( {
                url: `/${ getCookie( { cName: 'orCurrentCompanyUuid' } ) }/fundamental/`, method: 'get'
            } ),
            providesTags: () => (
                  providesList( [], 'Fundamentals' )
            ),
        } ),
    } ),
} );

export const {
    useGetFundamentalsQuery,
    useLazyGetFundamentalsQuery,
    endpoints: fundamentalsApiEndpoints,
} = fundamentalsApi;