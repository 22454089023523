import { all } from 'redux-saga/effects';

import { onLogoutSaga } from './onLogout.saga';
// import { onReduxPersistRehydrateForRTKQueriesSaga } from './onReduxPersistRehydrateForRTKQueries.saga';


export default function* internalSagas()
{
    yield all( [
        onLogoutSaga(),
        // onReduxPersistRehydrateForRTKQueriesSaga(),
    ] );
}