import { all } from 'redux-saga/effects';

import { onLoginSaga } from './onLogin.saga';
import { onLoginNoPasswordSaga } from '@/store/auth/sagas/onLoginNoPassword.saga';
import { onLogoutSaga } from './onLogout.saga';
import { onSignupSaga } from '@/store/auth/sagas/onSignup.saga';
import { onSignupConfirmAccountWithCodeSaga } from '@/store/auth/sagas/onSignupConfirmAccountWithCodeSaga';
import { onSignupWithoutConfirmationEmailSaga } from '@/store/auth/sagas/onSignupWithoutConfirmationEmailSaga';
import { onSignupResendConfirmationEmailSaga } from '@/store/auth/sagas/onSignupResendConfirmationEmailSaga';
import { onForgotPasswordSaga } from '@/store/auth/sagas/onForgotPassword.saga';
import { onForgotPasswordChangeSaga } from '@/store/auth/sagas/onForgotPasswordChange.saga';


export default function* authSaga()
{
    yield all( [
        onLoginSaga(),
        onLoginNoPasswordSaga(),
        onLogoutSaga(),
        onSignupSaga(),
        onSignupWithoutConfirmationEmailSaga(),
        onSignupConfirmAccountWithCodeSaga(),
        onSignupResendConfirmationEmailSaga(),
        onForgotPasswordSaga(),
        onForgotPasswordChangeSaga(),
    ] );
}