export interface TypedPromiseInterface<RESOLVE, REJECT = unknown> extends Promise<RESOLVE>
{
    then<TResult1 = RESOLVE, TResult2 = never>(
          onfulfilled?: ( ( value: RESOLVE ) => TResult1 | PromiseLike<TResult1> ) | undefined | null,
          onrejected?: ( ( reason: REJECT ) => TResult2 | PromiseLike<TResult2> ) | undefined | null
    ): TypedPromiseInterface<TResult1 | TResult2, REJECT>;

    catch<TResult = never>(
          onrejected?: ( ( reason: REJECT ) => TResult | PromiseLike<TResult> ) | undefined | null
    ): TypedPromiseInterface<RESOLVE, TResult | REJECT>;

    finally( onfinally?: ( () => void ) | undefined | null ): TypedPromiseInterface<RESOLVE, REJECT>;
}

export class TypedPromise<RESOLVE, REJECT = unknown> extends Promise<RESOLVE> implements TypedPromiseInterface<RESOLVE, REJECT>
{
    constructor(
          executor: ( resolve: ( value: RESOLVE | PromiseLike<RESOLVE> ) => void, reject: ( reason?: REJECT ) => void ) => void
    )
    {
        super( executor );
    }

    then<TResult1 = RESOLVE, TResult2 = never>(
          onfulfilled?: ( ( value: RESOLVE ) => TResult1 | PromiseLike<TResult1> ) | undefined | null,
          onrejected?: ( ( reason: REJECT ) => TResult2 | PromiseLike<TResult2> ) | undefined | null
    ): TypedPromise<TResult1 | TResult2, REJECT>
    {
        return super.then( onfulfilled, onrejected ) as TypedPromise<TResult1 | TResult2, REJECT>;
    }

    catch<TResult = never>(
          onrejected?: ( ( reason: REJECT ) => TResult | PromiseLike<TResult> ) | undefined | null
    ): TypedPromise<RESOLVE, TResult | REJECT>
    {
        return super.catch( onrejected ) as TypedPromise<RESOLVE, TResult | REJECT>;
    }

    finally( onfinally?: ( () => void ) | undefined | null ): TypedPromise<RESOLVE, REJECT>
    {
        return super.finally( onfinally ) as TypedPromise<RESOLVE, REJECT>;
    }
}
