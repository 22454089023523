import { getFileExtension } from './getFileExtension';

import { SVGIcon } from '@/components/structural/images';

import typeFileIcon from '@/icons/file-text.svg';
import typePPTIcon from '@/icons/file-earmark-slides.svg';
import typePDFIcon from '@/icons/file-earmark-pdf.svg';
import typeExcelIcon from '@/icons/file-earmark-spreadsheet.svg';
import typeWordIcon from '@/icons/file-earmark-word.svg';
import typeImageIcon from '@/icons/file-earmark-image.svg';
import typeTextIcon from '@/icons/file-earmark-text.svg';
import typeArchiveIcon from '@/icons/file-earmark-zip.svg';


export const typeSVG = ( icon: string, className = '' ) => <SVGIcon src={ icon } className={ `!w-5 !h-5 text-success dark:text-success-dark ${ className }` }/>;

export const getFileTypeIcon = ( filename: string, iconOnly = false, className = '' ) =>
{
    const ext = getFileExtension( filename );
    let icon: string;

    switch ( true )
    {
        case [ 'pdf' ].includes( ext ):
            icon = typePDFIcon.src;
            break;
        case [ 'ppt', 'pptx' ].includes( ext ):
            icon = typePPTIcon.src;
            break;
        case [ 'xls', 'xlsx' ].includes( ext ):
            icon = typeExcelIcon.src;
            break;
        case [ 'doc', 'docx' ].includes( ext ):
            icon = typeWordIcon.src;
            break;
        case [ 'jpg', 'jpeg', 'gif', 'png', 'raw', 'cr2', 'tif', 'tiff', 'eps', 'bmp', 'psd', 'xcf', 'ai', 'cdr' ].includes( ext ):
            icon = typeImageIcon.src;
            break;
        case [ 'txt', 'html', 'htm', 'css', 'js' ].includes( ext ):
            icon = typeTextIcon.src;
            break;
        case [ 'zip', 'tar', 'gzip', '7z', 'rar', 'iso', 'dmg' ].includes( ext ):
            icon = typeArchiveIcon.src;
            break;
        default:
            icon = typeFileIcon.src;
            break;
    }

    if ( iconOnly )
    {
        return icon;
    }

    return typeSVG( icon, className );
};