import { forwardRef, useEffect, useImperativeHandle } from 'react';
import useCreation from 'ahooks/lib/useCreation';
import useReactive from 'ahooks/lib/useReactive';
import useUpdateEffect from 'ahooks/lib/useUpdateEffect';
import tw from 'tailwind-styled-components';

import Typography from 'antd/lib/typography';

import { InputNumber } from '@/components/structural/form';

import type { FilterRefType } from '@/components/tables';
import type { ORJSON } from '@/shared/models';


export interface IFiltersRangeState
{
    from?: number,
    to?: number,
    active?: boolean
}

export interface IRangeFilterProps
{
    filtersChanged: ( key: string, value: IFiltersRangeState ) => void,
    title: string,
    attribute: string,
    initialSelectedValues: number[],
    className?: string,
}

export const RangeFilter = forwardRef<FilterRefType<IRangeFilterProps>, IRangeFilterProps>( ( { filtersChanged, title, attribute, initialSelectedValues = [], className }: IRangeFilterProps, ref ) =>
{
    const freshState = useCreation( () => ( {
        from: 0,
        to: 0,
        active: false
    } ), [] );

    const initialState = useCreation( () => ( {
        from: initialSelectedValues[ 0 ] || 0,
        to: initialSelectedValues[ 1 ] || 0,
        active: initialSelectedValues.length > 0
    } ), [ initialSelectedValues ] );

    const filtersState = useReactive<ORJSON<IFiltersRangeState>>( {
        [ attribute ]: initialState
    } );

    useImperativeHandle<IRangeFilterProps, FilterRefType<IRangeFilterProps>>( ref, () => ( {
        filtersChanged,
        title,
        attribute,
        initialSelectedValues,
        clear: () =>
        {
            filtersState[ attribute ] = freshState;
        }
    } ) );

    useUpdateEffect( () =>
    {
        filtersChanged( attribute, filtersState[ attribute ] );
    }, [ filtersState[ attribute ] ] );

    useEffect( () =>
    {
        if ( initialState.active )
        {
            filtersState[ attribute ] = initialState;
        }
    }, [ initialState ] );

    return ( <>
        <div className={ className }>
            <Text className="mb-2 font-medium">
                { title }
            </Text>
            <div className="flex flex-col font-roboto text-default dark:text-default-dark">
                <Text className="text-inherit">At least:</Text>
                <InputNumber
                      className="w-[11.65rem] my-2"
                      onChange={ ( value ) => ( filtersState[ attribute ] = {
                          ...filtersState[ attribute ],
                          from: Number( value ),
                          active: true
                      } ) }
                      value={ filtersState[ attribute ]?.from }
                      wholeNumbers
                />
                <Text className="text-inherit">At most:</Text>
                <InputNumber
                      className="w-[11.65rem] mt-2"
                      onChange={ ( value ) => ( filtersState[ attribute ] = {
                          ...filtersState[ attribute ],
                          to: Number( value ),
                          active: true
                      } ) }
                      value={ filtersState[ attribute ]?.to }
                      wholeNumbers
                />
            </div>
        </div>
    </> );
} );

RangeFilter.displayName = 'RangeFilter';

const Text = tw( Typography.Text )`
    block
    text-primary
        dark:text-primary-dark
`;