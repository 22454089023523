import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { AxiosError, AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import axios, { AxiosHeaders } from 'axios';

import { authService } from '@/store/auth';
import { doLogout, setAWSUser } from '@/store/auth/actions';
import { HTTPErrorOccurred } from '@/store/internal/actions';

import { getCookie, handleHTTPResponseError, stringContainsUUID } from '@/shared/tools';
import { processError } from '@/shared/processError';

import type { IORGetParameters } from '@/store/_models/get-parameters.interface';
import type { IRequestError, ORJSON } from '@/shared/models';


export interface IAxiosBaseQueryProps
{
    baseUrl?: string;
    options?: {
        chRequest?: boolean,
        externalRequest?: boolean,
        baseTimeout?: number,
    };
}

export interface IORBaseQueryFnProps
{
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data'],
    queryParams?: IORGetParameters,
    timeout?: number,
    extraHeaders?: Partial<RawAxiosRequestHeaders>,
    overrideUrl?: boolean,
}

const authRetries: ORJSON<number> = {};
const MAX_AUTH_RETRIES = typeof window !== 'undefined' ? 1 : 1;

export const baseQuery = ( {
    baseUrl = '',
    options = {}
}: IAxiosBaseQueryProps = {} ): BaseQueryFn<IORBaseQueryFnProps> => async ( {
    url, method, data, queryParams, timeout, extraHeaders = {}, overrideUrl = false
}, api ) =>
{
    const {
        chRequest = false,
        externalRequest = false,
        baseTimeout = 30_000,
    } = options;
    let urlFull = overrideUrl ? url : ( baseUrl + url );

    if ( urlFull.includes( '/company/view-all' ) ||
          urlFull.includes( '/null/' ) ||
          urlFull.includes( '/undefined/' ) )
    {
        return {
            data: [],
            meta: {}
        };
    }

    if ( !externalRequest )
    {
        let extra: string; // = 'http://localhost:3005';

        if ( chRequest )
        {
            extra = process.env.NEXT_PUBLIC_CH_API;
            // extra += '/api/ch';
        }
        // default OR request
        else
        {
            extra = process.env.NEXT_PUBLIC_OR_API;
            // extra += '/api/or';
        }

        urlFull = extra + urlFull;
    }

    if ( authRetries[ urlFull ] === undefined )
    {
        authRetries[ urlFull ] = 0;
    }

    const fn = async () =>
    {
        if ( !getCookie( { cName: 'orToken' } ) )
        {
            return {
                data: [],
                meta: {}
            };
        }

        try
        {
            const headers: AxiosRequestConfig['headers'] = new AxiosHeaders( { ...extraHeaders } );

            if ( chRequest )
            {
                headers.set( { authorization: `Basic ${ Buffer.from( process.env.NEXT_PUBLIC_CH_KEY + ':' ).toString( 'base64' ) }` } );
            } else
            {
                headers.set( { authorization: `Bearer ${ getCookie( { cName: 'orToken' } ) || '' }` } );
            }

            let urlWithParams = urlFull;

            if ( queryParams )
            {
                let params: ORJSON = {
                    ...queryParams
                };

                if ( queryParams[ 'ordering' ] )
                {
                    params = {
                        ...params,
                        ordering: `${ queryParams.ordering.direction === 'desc' ? '-' : '' }${ queryParams.ordering.field }`,
                    };
                }

                if ( queryParams[ 'query' ] )
                {
                    params = {
                        ...params,
                        search: typeof queryParams.query === 'string' ? queryParams.query :
                              Object.entries( queryParams.query ).map( ( entry ) =>
                              {
                                  if ( entry[ 0 ] && !entry[ 0 ][ '0' ] || typeof entry[ 1 ] === 'string' )
                                  {
                                      return `${ entry[ 0 ] }=${ entry[ 1 ] }`;
                                  }

                                  return Object.entries( entry[ 1 ] ).map( ( e ) => `${ e[ 0 ] }=${ e[ 1 ] }` );
                              } ).join( ',' ),
                    };
                    delete params[ 'query' ];
                }

                if ( queryParams[ 'filter' ] )
                {
                    params = {
                        ...params,
                        ...queryParams.filter,
                    };
                    delete params[ 'filter' ];
                }

                const parsedParams = new URLSearchParams( params as ORJSON<string> );
                urlWithParams = `${ urlFull }?${ parsedParams }`;
            }

            const reqTimeout = timeout ? timeout * 1000 : baseTimeout;
            const result = await axios( {
                url: urlWithParams,
                method,
                data,
                headers,
                timeout: reqTimeout
            } );

            const rawData = {
                data: result?.data,
                meta: {}
            };

            if ( chRequest )
            {
                if ( rawData.data?.items )
                {
                    const { items, ...meta } = rawData.data;
                    return {
                        data: items,
                        meta
                    };
                }

                return {
                    data: rawData,
                    meta: {}
                };
            }

            return {
                data: rawData.data,
                meta: rawData.meta
            };
        } catch ( axiosErrorUntyped )
        {
            const axiosError = axiosErrorUntyped as AxiosError;

            if ( axiosError?.response?.status === 401/* && typeof window !== 'undefined'*/ )
            {
                authRetries[ urlFull ]++;

                if ( authRetries[ urlFull ] <= MAX_AUTH_RETRIES )
                {
                    try
                    {
                        const awsUser = await authService.refreshToken();
                        api.dispatch( setAWSUser( awsUser ) );
                    } catch ( e )
                    {
                        const error: IRequestError = {
                            code: '527',
                            name: 'Auth Exception',
                            message: 'Could not re-authenticate user. Logging out...'
                        };

                        api.dispatch( doLogout() );

                        return withError( error );
                    }

                    return fn();
                } else
                {
                    delete authRetries[ urlFull ];

                    const error: IRequestError = {
                        code: '528',
                        name: 'Internal Exception',
                        message: `Could not get authenticated after trying ${ MAX_AUTH_RETRIES } time(s). Logging out...`
                    };

                    // api.dispatch( HTTPErrorOccurred( error ) );
                    api.dispatch( doLogout() );

                    // processError( 'RTK baseQuery Internal Error', axiosError, urlFull, authRetries, authRetries[ urlFull ], MAX_AUTH_RETRIES );

                    return withError( error );
                }
            }

            const message = axiosError?.response?.data ? handleHTTPResponseError( axiosError.response ) : axiosError?.message;
            const error: IRequestError = {
                code: axiosError?.code,
                message,
                name: axiosError?.name
            };

            if ( stringContainsUUID( axiosError?.config?.url ) && axiosError?.response?.status !== 404 )
            {
                api.dispatch( HTTPErrorOccurred( error ) );
                processError( 'RTK baseQuery Axios Error', error, axiosError, urlFull );
            }

            return withError( error );
        }
    };

    return fn();
};

const withError = ( error: IRequestError ) => ( {
    error: {
        status: Number( error?.code ),
        message: error?.message,
        data: error,
        code: error?.code
    },
} );