import type { FilterKeys, FilterRefType } from '@/components/tables';
import type { ORJSON } from '@/shared/models';


export const clearFilters = ( filterKeys: FilterKeys, filterBarRef: ORJSON<FilterRefType<unknown>> ) =>
{
    const keyGroups = Object.keys( filterKeys );
    keyGroups.forEach( ( keyGroup ) =>
    {
        const keys = Object.keys( filterKeys[ keyGroup ] );
        keys.forEach( ( key ) =>
        {
            const myKey = filterKeys[ keyGroup ][ key ];

            if ( typeof myKey === 'object' )
            {
                filterBarRef[ myKey.title ]?.clear();
            } else
            {
                filterBarRef[ myKey ]?.clear();
            }
        } );
    } );
};