import tw from 'tailwind-styled-components';

import Layout from 'antd/lib/layout';


const { Content } = Layout;

export const FullScreenOverlay = tw( Content )`
    fixed
    h-screen
    w-screen
    inset-0
    bg-background
    scrollbar-light
    flex
    place-items-center
    place-content-center
`;

export const FullScreenOverlayInner = tw.div`
    w-full
    xs:w-[500px]
    p-10
    mx-10
    xs:mx-0
    border
    border-gray-100
    rounded-lg
    shadow-2xl
    bg-white
`;