import { useMemo } from 'react';

import { getMetaDefaultObject } from '@/store/_rtk-query/getMetaDefaultObject';
import { useCurrentCompany } from '@/store/company/hooks/useCurrentCompany.hook';

import { shareIssueAdapter, useGetManyShareIssuesForRoundQuery, useGetManyShareIssuesQuery } from '@/store/company';
import { shareIssueSelectors } from '@/store/company/selectors';

import { compare } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORGetParameters } from '@/store/_models/get-parameters.interface';


export const useShareIssues = ( getParameters: IORGetParameters = { page: 1, per_page: DEFAULT_PER_PAGE }, skip = false ) =>
{
    const { orCompany } = useCurrentCompany();
    const { data, isFetching, isLoading, error } = useGetManyShareIssuesQuery( getParameters, {
        skip: skip || !orCompany
    } );

    const shareIssues = shareIssueSelectors.selectAll(
          data ||
          shareIssueAdapter.getInitialState()
    );

    shareIssues.sort( ( a, b ) => compare( a.issue_date, b.issue_date ) );

    const meta = data?.meta || getMetaDefaultObject();

    return useMemo( () => ( {
        shareIssues,
        meta,
        isLoading,
        isFetching,
        error
    } ), [ data, isFetching, isLoading, error ] );
};

export const useShareIssuesForRound = ( shareRound: string, getParameters: IORGetParameters = { page: 1, per_page: DEFAULT_PER_PAGE }, skip = false ) =>
{
    const { orCompany } = useCurrentCompany();
    const { data, isFetching, isLoading, error } = useGetManyShareIssuesForRoundQuery( { shareRound, options: getParameters }, {
        skip: skip || !orCompany
    } );

    const shareIssues = shareIssueSelectors.selectAll(
          data ||
          shareIssueAdapter.getInitialState()
    );

    shareIssues.sort( ( a, b ) => compare( a.issue_date, b.issue_date ) );

    const meta = data?.meta || getMetaDefaultObject();

    return useMemo( () => ( {
        shareIssues,
        meta,
        isLoading,
        isFetching,
        error
    } ), [ data, isFetching, isLoading, error ] );
};