import type { ICHCompanyPSC } from '@/features/company/shared/models';


export const parsePSCName = ( psc: ICHCompanyPSC ) =>
{
    return psc.name_elements ? {
        first: psc.name_elements.forename,
        last: psc.name_elements.surname,
    } : {
        first: null,
        last: psc.name
    };
};