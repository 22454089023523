export const editDistance = ( s1: string, s2: string ): number =>
{
    const costs = new Array( s2.length + 1 );
    for ( let i = 0; i <= s1.length; i++ )
    {
        let lastValue = i;
        for ( let j = 0; j <= s2.length; j++ )
        {
            if ( i === 0 )
            {
                costs[ j ] = j;
            } else if ( j > 0 )
            {
                let newValue = costs[ j - 1 ];
                if ( s1.charAt( i - 1 ) !== s2.charAt( j - 1 ) )
                {
                    newValue = Math.min( Math.min( newValue, lastValue ), costs[ j ] ) + 1;
                }
                costs[ j - 1 ] = lastValue;
                lastValue = newValue;
            }
        }
        if ( i > 0 )
        {
            costs[ s2.length ] = lastValue;
        }
    }
    return costs[ s2.length ];
};

export const calculateStringSimilarity = ( s1: string, s2: string ): number =>
{
    const string1Length = s1.length;
    const string2Length = s2.length;

    if ( string1Length === 0 || string2Length === 0 )
    {
        return 0;
    }

    if ( s1 === s2 )
    {
        return 100;
    }

    return ( ( string1Length - editDistance( s1, s2 ) ) / parseFloat( string1Length.toString() ) ) * 100;
};

export const stringsAreSimilar = ( s1: string, s2: string, threshold = 80 ): boolean =>
{
    return calculateStringSimilarity( s1, s2 ) >= threshold;
};