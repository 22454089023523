import { useEffect, useMemo } from 'react';

import { hideViewSelector, showViewSelector } from '@/store/internal/actions';
import { selectPageViewSelectorVisibility } from '@/store/internal/selectors';
import { useAppDispatch, useAppSelector } from '@/store/hooks';


export const useViewSelector = ( showPageViewSelector: boolean ) =>
{
    const dispatch = useAppDispatch();
    const viewSelectorVisible = useAppSelector( selectPageViewSelectorVisibility );

    useEffect( () =>
    {
        if ( viewSelectorVisible && !showPageViewSelector ) dispatch( hideViewSelector() );
        else if ( !viewSelectorVisible && showPageViewSelector ) dispatch( showViewSelector() );
    }, [ showPageViewSelector, viewSelectorVisible ] );

    return useMemo( () => ( { viewSelectorVisible } ), [ viewSelectorVisible ] );
};