import { configureReduxPersist } from '@/store/configureReduxPersist';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { ICompanyState } from './state.interface';
import { companySliceKey } from './slice.key';


const initialState: ICompanyState = {
    widgets: {
        filingDeadlines: {
            selectedCompany: null,
        }
    }
};

const _slice = createSlice( {
    name: companySliceKey,
    initialState,
    /**
     * The reducers property both creates an action creator function and responds to that action in the slice reducer.
     * Actions need to be exported, see ./actions/index.ts and list these reducers there
     */
    reducers: {
        setWidgetFilingDeadlinesSelectedCompany: ( state, action: PayloadAction<string> ) =>
        {
            state.widgets.filingDeadlines.selectedCompany = action.payload;
        },
        clearCompanyState: () =>
        {
            return initialState;
        }
    },
    /**
     * Use extraReducers when you are dealing with an action that you have already defined somewhere else.
     * The most common examples are responding to a createAsyncThunk action and responding to an action from another slice.
     */
    /*extraReducers: ( builder ) =>
    {}*/
} );

// export const companySlice = _slice.reducer;
export const companySlice = configureReduxPersist<ICompanyState>( companySliceKey, _slice.reducer );

export const actions = _slice.actions;
