import { all } from 'redux-saga/effects';

import { onCurrentCompanyChangeSaga } from '@/store/company/sagas/onCurrentCompanyChange.saga';
import { onLogoutSaga } from './onLogout.saga';


export default function* companySagas()
{
    yield all( [
        // onCompaniesGetSuccessSaga(),
        onCurrentCompanyChangeSaga(),
        onLogoutSaga(),
    ] );
}