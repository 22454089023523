import { type FC, memo, type MutableRefObject, useCallback } from 'react';
import tw from 'tailwind-styled-components';

import { Button } from '@/components/structural';
import { CategoryFilter, DateFilter, type FilterRefType, type IFiltersCategoryState, type IFiltersDateState, RangeFilter } from './filtering-generators';
import { clearFilters, type FilterKeys, filtersChanged, type IFiltersState } from './filtering-tools';
import type { ORJSON } from '@/shared/models';
import { SVGIcon } from '@/components/structural/images';

import closeFilterBarIcon from '@/icons/x.svg';


interface IFilterBarProps
{
    showFilterBar: boolean,
    filterKeys: FilterKeys,
    filterTitles: ORJSON<string>,
    filterBarRef: MutableRefObject<ORJSON<FilterRefType<unknown>>>,
    filtersState: IFiltersState,
    filtersActive: boolean,
    setFiltersActive: ( val: boolean ) => void,
    toggleFilterBarFn: () => void,
    onFilterApply: () => void,
    onFilterClear: () => void
}

export const FilterBar: FC<IFilterBarProps> = memo( ( {
    showFilterBar,
    filterKeys,
    filterTitles,
    filterBarRef,
    filtersState,
    filtersActive,
    setFiltersActive,
    toggleFilterBarFn,
    onFilterApply,
    onFilterClear,
}: IFilterBarProps ) =>
{
    const onClear = useCallback( () =>
    {
        clearFilters(
              filterKeys, filterBarRef.current
        );
        toggleFilterBarFn();
        onFilterClear();
        setFiltersActive( false );
    }, [ filterKeys, filterBarRef.current, toggleFilterBarFn ] );

    const filtersChangedFn = useCallback( ( key: string, value: unknown ) =>
          filtersChanged<IFiltersDateState>(
                key, value, filterKeys, filtersState
          ), [ filterKeys, filtersState, filtersActive, setFiltersActive ] );

    const filtersApplyFn = useCallback( () =>
    {
        onFilterApply();
        setFiltersActive( true );
    }, [ onFilterApply, setFiltersActive ] );

    const filtersCountMax = ( filterKeys.categoryKeys?.length || 0 ) +
          ( filterKeys.rangeKeys?.length || 0 ) +
          ( filterKeys.dateKeys?.length || 0 );
    let filtersCount = 0;

    return ( <>
        <div className={ `${ showFilterBar ? 'inline-block' : 'hidden' } relative float-right right-9 top-2 hover:cursor-pointer w-0` }
             onClick={ toggleFilterBarFn }
        >
            <SVGIcon
                  src={ closeFilterBarIcon.src }
                  className="!w-8 !h-8"
            />
        </div>
        <FilterBarContainer className={ `${ showFilterBar ? 'flex' : 'hidden' } justify-between` }>
            <div className="flex flex-wrap w-full">
                { filterKeys.categoryKeys?.map( filterKey => ( typeof filterKey === 'object' && <CategoryFilter
                      key={ filterKey.title }
                      ref={ e => { filterBarRef.current[ filterKey.title ] = e; } }
                      filtersChanged={ ( key, value ) =>
                            filtersChanged<IFiltersCategoryState>(
                                  key, value, filterKeys, filtersState
                            )
                      }
                      initialSelectedValues={ filtersState[ filterKey.title ]?.values as unknown as string[] }
                      title={ filterTitles[ filterKey.title ] }
                      categories={ filterKey.values }
                      attribute={ filterKey.title }
                      className={ `${ ++filtersCount === filtersCountMax ? 'flex-grow ' : '' }mr-4` }
                /> ) ) }
                { filterKeys.rangeKeys?.map( filterKey => ( typeof filterKey === 'string' && <RangeFilter
                      key={ filterKey }
                      ref={ e => { filterBarRef.current[ filterKey ] = e; } }
                      filtersChanged={ filtersChangedFn }
                      title={ filterTitles[ filterKey ] }
                      attribute={ filterKey }
                      initialSelectedValues={ filtersState[ filterKey ]?.values as unknown as number[] }
                      className={ `${ ++filtersCount === filtersCountMax ? 'flex-grow ' : '' }mr-4` }
                /> ) ) }
                { filterKeys.dateKeys?.map( filterKey => ( typeof filterKey === 'string' && <DateFilter
                      key={ filterKey }
                      ref={ e => { filterBarRef.current[ filterKey ] = e; } }
                      filtersChanged={ filtersChangedFn }
                      title={ filterTitles[ filterKey ] }
                      attribute={ filterKey }
                      initialSelectedValues={ filtersState[ filterKey ]?.values as unknown as string[] }
                      className={ `${ ++filtersCount === filtersCountMax ? 'flex-grow ' : '' }mr-4` }
                /> ) ) }
                <div className="justify-self-end flex flex-col flex-shrink h-auto w-full lg:w-min mt-4 lg:mt-0">
                    <div className="flex items-end flex-grow justify-end">
                        <Button className="mr-4" type="default" onClick={ toggleFilterBarFn }>
                            CLOSE
                        </Button>
                        <Button className="mr-4" type="info" onClick={ onClear } disabled={ !filtersActive }>
                            CLEAR
                        </Button>
                        <Button type="success" onClick={ filtersApplyFn }>
                            APPLY
                        </Button>
                    </div>
                </div>
            </div>
        </FilterBarContainer>
    </> );
} );
FilterBar.displayName = 'FilterBar';

const FilterBarContainer = tw.div`
    mb-4
    p-4
    bg-background
        dark:bg-background-dark
    rounded-xl
`;

