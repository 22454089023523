import { createApi } from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';

import { baseQuery } from '@/store/_rtk-query/base-query';
import { providesList } from '@/store/_rtk-query';

import { companyApiKey } from './service.key';

import { processError } from '@/shared/processError';

import type { ICHCompaniesSearchResult, ICHCompanyProfile, ICHCompanySearchResultItem } from '@/features/company/shared/models';


export const companyChApi = createApi( {
    reducerPath: companyApiKey + '_ch',
    tagTypes: [ 'CHCompanies', 'CHCompany' ],
    baseQuery: baseQuery( {
        baseUrl: '', options: {
            chRequest: true
        }
    } ),
    extractRehydrationInfo( action, { reducerPath } )
    {
        if ( action.type === HYDRATE )
        {
            return action.payload[ reducerPath ];
        }
    },
    refetchOnFocus: false,
    refetchOnReconnect: false,
    keepUnusedDataFor: parseInt( process.env.NEXT_PUBLIC_UNUSED_CACHE_DURATION, 10 ),
    endpoints: ( builder ) => ( {
        getAllByName: builder.query<{ data: ICHCompanySearchResultItem[], meta: Omit<ICHCompaniesSearchResult, 'items'> }, string>( {
            query: ( filter ) => ( {
                url: `/search/companies`, method: 'get', queryParams: {
                    q: filter,
                    items_per_page: '10'
                }
            } ),
            providesTags: ( result ) => (
                  providesList( result?.data.map( res => res.company_number ), 'CHCompanies', 'CHCompany' )
            ),
            transformResponse: ( data: ICHCompanySearchResultItem[], meta: Omit<ICHCompaniesSearchResult, 'items'> ) =>
            {
                return ( {
                    data, meta
                } );
            },
            onQueryStarted: async ( attrs, api ) =>
            {
                try
                {
                    await api.queryFulfilled;
                } catch ( e )
                {
                    processError( 'companyChApi getAllByName error', e );
                }
            }
        } ),
        getOneByID: builder.query<{ data: ICHCompanyProfile }, string>( {
            query: ( uuid ) => ( {
                url: `/company/${ uuid }`, method: 'get'
            } ),
            providesTags: ( result ) => (
                  providesList( [ result?.data?.company_number ], 'CHCompany' )
            ),
            onQueryStarted: async ( attrs, api ) =>
            {
                try
                {
                    await api.queryFulfilled;
                } catch ( e )
                {
                    processError( 'companyChApi getOneByID error', e );
                }
            }
        } ),
    } ),
} );

export const companyChApiReducer = companyChApi.reducer;
// export const companyApiReducer = configureReduxPersist<ReturnType<typeof companyApi.reducer>>( companyApi.reducerPath, companyApi.reducer );

export const {
    useGetAllByNameQuery,
    useLazyGetAllByNameQuery,
    useGetOneByIDQuery,
    useLazyGetOneByIDQuery,
    usePrefetch
} = companyChApi;
