import type { ISorterState } from './interfaces';
import { splitOptionValue } from './splitOptionValue';


export const getInitialSortByState = ( value = '' ): ISorterState =>
{
    const [ key = null, direction = undefined ] = splitOptionValue( value );
    return ( {
        internal: {
            key,
            direction
        },
        visual: {
            key,
            direction
        }
    } );
};