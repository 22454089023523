import type { ReactNode } from 'react';
import get from 'lodash/get';

import { compare } from '@/shared/tools';

import type { Breakpoint } from '@/components/tables/getORBreakpointsHigherThan';
import type { DataIndex } from 'rc-table/lib/interface';
import type { ISorter } from './interfaces';
import type { ORColumnType } from '@/components/tables';


interface IColumnItem<T, K extends keyof T>
{
    title: ORColumnType<T>['title'],
    className?: ORColumnType<T>['className'],
    width?: ORColumnType<T>['width'],
    key: K,
    minBreakPoint?: Breakpoint,
    render?: ( value: T[K], record: T, index: number ) => ReactNode,
    sorter?: ( a: T[K], b: T[K] ) => number,
    cellContentsType?: 'string' | 'boolean' | 'number',
    headerColSpan?: number,
    onCell?: ORColumnType<T>['onCell'],
}

export const prepareColumnItem = <T, K extends keyof T = keyof T>( item: IColumnItem<T, K>, sorter?: ISorter ): ORColumnType<T> =>
{
    return ( {
        title: item.title,
        width: item.width,
        dataIndex: item.key as DataIndex,
        colSpan: item.headerColSpan ?? 1,
        key: Array.isArray( item.key ) ? item.key.join( '-' ) : String( item.key ),
        sorter: sorter ? ( ( a, b ) =>
              typeof item.sorter === 'function' ?
                    item.sorter(
                          get( a, item.key ),
                          get( b, item.key )
                    ) :
                    !isNaN( Number( get( a, item.key ) ) ) ? compare(
                          Number( get( a, item.key ) ),
                          Number( get( b, item.key ) ),
                          true
                    ) : compare(
                          get( a, item.key ),
                          get( b, item.key ),
                          true
                    ) ) : false,
        render: item.render,
        $minBreakPoint: item.minBreakPoint,
        className: item.className,
        sortOrder: sorter ? ( Array.isArray( item.key ) && Array.isArray( sorter.key ) ?
              ( sorter.key.join( '' ) === item.key.join( '' ) ? sorter.direction : undefined ) :
              ( sorter.key === item.key ? sorter.direction : undefined ) ) : undefined,
        onHeaderCell: () => ( {
            className: `table-cell-data ${ item.cellContentsType || 'string' }`
        } ),
        $onCell: item.onCell ?? ( () => ( {} ) ),
    } );
};