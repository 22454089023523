import { type FC, memo } from 'react';

import type { IFormStepProps } from '@/components/structural/form';


export const StepEnd: FC<IFormStepProps> = memo( () =>
{
    return <>
        <div className="grid grid-cols-1 place-items-center w-full mb-4">
            <div className="mb-4 font-roboto">
                Your request to add SAIL address has now been submitted to Companies House. OneRegistry will be updated
                once the request has been approved by the Registrar of Companies.
            </div>
        </div>
    </>;
} );

StepEnd.displayName = 'CompanyAddSAILAddressStepEnd';