import { useMemo } from 'react';

import { compare } from '@/shared/tools';

import { companyAdapter, useGetManyCompaniesQuery } from '@/store/company';
import { companySelectors } from '@/store/company/selectors';

import type { IORGetParameters } from '@/store/_models/get-parameters.interface';
import { DEFAULT_PER_PAGE } from '@/shared/constants';

import { useCurrentUser } from '@/store/user/hooks';
import { getMetaDefaultObject } from '@/store/_rtk-query/getMetaDefaultObject';


export const useCompanies = ( getParameters: IORGetParameters = { page: 1, per_page: DEFAULT_PER_PAGE }, skip = false ) =>
{
    const { awsUser } = useCurrentUser();
    const { data, isFetching, isLoading, error, status } = useGetManyCompaniesQuery( getParameters, {
        skip: skip || !awsUser
    } );

    const companies = companySelectors.selectAll(
          data ||
          companyAdapter.getInitialState()
    );

    companies.sort( ( a, b ) => compare( a.name, b.name, true ) );

    const meta = data?.meta || getMetaDefaultObject();

    return useMemo( () => ( {
        companies,
        meta,
        isLoading,
        isFetching,
        status,
        error
    } ), [ data, isFetching, isLoading, error, status ] );
};