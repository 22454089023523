import type { DatastoreItemType } from '@/features/datastore/models/datastore.django.interface';


export const mapDatastoreResponse = ( item: DatastoreItemType ) =>
{
    if ( !item )
    {
        return item;
    }

    if ( !( 'contents' in item ) || !item.contents )
    {
        return item;
    }

    const children = Object.keys( item.contents );

    if ( children.length === 0 )
    {
        item.contents = null;
        return { ...item };
    }

    children.forEach( ( key ) =>
    {
        item.contents[ key ] = mapDatastoreResponse( item.contents[ key ] );
    } );

    return { ...item };
};