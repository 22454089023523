import axios from 'axios';

import { processError } from '@/shared/processError';


export const downloadFromAWS = async ( urlToDownload: string, filename: string ) =>
{
    try
    {
        const { data } = await axios( {
            url: urlToDownload,
            method: 'get',
            responseType: 'blob',
        } );

        const blob = new Blob( [ data ], { type: 'application/octet-stream' } );
        const url = window.URL.createObjectURL( blob );

        // based on https://stackoverflow.com/a/56923508
        // create <a> tag dynamically
        const fileLink = document.createElement( 'a' );
        fileLink.href = url;

        // it forces the name of the downloaded file
        fileLink.download = filename;

        // triggers the click event
        fileLink.click();

        return true;
    } catch ( e )
    {
        processError( 'downloadFromAWS General Error', e );
        processError( 'downloadFromAWS Axios Error', e.response?.data?.error );

        return false;
    }
};