import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { configureReduxPersist } from '@/store/configureReduxPersist';
import { removeCookies, setCookie } from '@/shared/tools';

import type { IAWSUser } from '@/features/auth/models';
import type { IAuthState } from './state.interface';

import { authSliceKey } from './slice.key';


const initialState: IAuthState = {
    awsUser: null
};

const _slice = createSlice( {
    name: authSliceKey,
    initialState,
    /**
     * The reducers property both creates an action creator function and responds to that action in the slice reducer.
     * Actions need to be exported, see ./actions/index.ts and list these reducers there
     */
    reducers: {
        setAWSUser: ( state, { payload }: PayloadAction<IAWSUser> ) =>
        {
            setCookie( { cName: 'orToken', cValue: payload.session.tokens.accessToken.toString() } );
            setCookie( { cName: 'orAuth', cValue: 'ok' } );

            state.awsUser = {
                username: payload.user.username
            };
        },
        clearAuthState: () =>
        {
            removeCookies( 'orToken', 'orAuth' );

            return initialState;
        }
    },
    /**
     * Use extraReducers when you are dealing with an action that you have already defined somewhere else.
     * The most common examples are responding to a createAsyncThunk action and responding to an action from another slice.
     */
    /*extraReducers: ( builder ) =>
    {}*/
} );

export const authStoreSlice = configureReduxPersist<IAuthState>( authSliceKey, _slice.reducer );

export const actions = _slice.actions;

