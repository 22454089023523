export type ObjectDiffReturnType<T> = {
    [K in keyof T]?: T[K] extends object ? ObjectDiffReturnType<T[K]> : { original: T[K], new: T[K] }
};
export const objectDiff = <T extends object>( obj1: T, obj2: T ): ObjectDiffReturnType<T> =>
{
    const result: unknown = {};

    for ( const key in obj1 )
    {
        if ( Object.prototype.hasOwnProperty.call( obj2, key ) )
        {
            if ( obj2[ key ] !== obj1[ key ] )
            {
                result[ key ] = { 'original': obj1[ key ], 'new': obj2[ key ] };
            }
        } else
        {
            result[ key ] = { 'original': obj1[ key ], 'new': null };
        }
    }

    for ( const key in obj2 )
    {
        if ( !Object.prototype.hasOwnProperty.call( obj1, key ) )
        {
            result[ key ] = { 'original': null, 'new': obj2[ key ] };
        }
    }

    return result as ObjectDiffReturnType<T>;
};