import { createEntityAdapter } from '@reduxjs/toolkit';

import { compare } from '@/shared/tools';

import type { IORAccount, IORAccountAuditEntry } from '@/features/account/models';


export const accountAdapter = createEntityAdapter<IORAccount, string>( {
    sortComparer: ( a, b ) => compare( a.created_at, b.created_at ),
    selectId: ( e ) => e.uuid,
} );

export const accountAuditEntryAdapter = createEntityAdapter<IORAccountAuditEntry, string>( {
    sortComparer: ( a, b ) => compare( a.created_at, b.created_at ),
    selectId: ( e ) => e.uuid,
} );