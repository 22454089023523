export const CHSailAddressRecordsAndLocations = [
    'Register of people with significant control',
    'Register of members',
    'Register of directors',
    'Directors’ service contracts',
    'Directors’ indemnities',
    'Register of secretaries',
    'Records of resolutions etc',
    'Contracts relating to purchase of own shares',
    'Documents relating to redemption or purchase of own share out of capital by private company',
    'Register of debenture holders',
    'Report to members of outcome of investigation by public company into interests in its shares',
    'Register of interests in shares disclosed to public company',
    'Instruments creating charges and register of charges: England and Wales or Northern Ireland',
    'Instruments creating charges and register of charges: Scotland',
    'Historic register of people with significant control'
] as const;

export type CHSailAddressRecordsAndLocationsType = typeof CHSailAddressRecordsAndLocations[number];