import omit from 'lodash/omit';

import { DATE_FORMAT_NO_DAY } from '@/shared/dateFormats';
import { exportAsExcelFile } from './exportAsExcelFile';
import { normaliseDate } from '@/shared/tools';

import type { ORJSON } from '@/shared/models';


export const downloadExcelList = (
      objects: unknown[],
      fileName: string,
      // if date field name contains an '!', it will be treated as dateFormatNoDay, else dateFormat
      dateFields: string[] = [],
      omitFields: string[] = [],
      // field name: formatterFn() => string
      formatFields: ORJSON<( data: unknown ) => string> = {}
) =>
{
    const list = objects.map( obj =>
    {
        // take away certain keys
        const newObj = omit( obj as ORJSON, [ 'uuid', 'created_at', 'updated_at', 'key', 'entity', ...omitFields ] );

        dateFields.forEach( field =>
        {
            if ( field.includes( '!' ) )
            {
                const fieldParsed = field.split( '!' )[ 0 ];
                newObj[ fieldParsed ] = normaliseDate( newObj[ fieldParsed ], DATE_FORMAT_NO_DAY );
            } else
            {
                if ( !newObj[ field ] )
                {
                    newObj[ field ] = '';
                } else
                {
                    newObj[ field ] = normaliseDate( newObj[ field ] );
                }
            }
        } );

        Object.keys( formatFields ).forEach( field =>
        {
            newObj[ field ] = formatFields[ field ]( newObj[ field ] );
        } );

        return newObj;
    } );

    // create Excel file and export it
    exportAsExcelFile( list, fileName );
};