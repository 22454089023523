import { DEFAULT_PER_PAGE } from '@/shared/constants';


export const getMetaDefaultObject = () => ( {
    pagination: {
        total: 0,
        count: 0,
        per_page: DEFAULT_PER_PAGE,
        current_page: 1,
        total_pages: 1,
        links: {},
    }
} );