import axios from 'axios';

import { processError } from '@/shared/processError';


export const uploadToAWS = async ( urlToUpload: string, file: File ) =>
{
    try
    {
        await axios( {
            url: urlToUpload,
            method: 'put',
            headers: {
                'Content-storeInfo': file.type
            },
            data: file
        } );

        return true;
    } catch ( e )
    {
        processError( 'uploadToAWS General Error', e );
        processError( 'uploadToAWS Axios Error', e.response?.data?.error );

        return false;
    }
};