import { useMemo } from 'react';
import moment from 'moment/moment';

import type { ORButtonType } from '@/components/structural';


export const useFilingDeadlinePriority = ( date: string ) =>
{
    const priorityMap = useMemo( () => ( {
        success: 'LOW',
        warning: 'MEDIUM',
        danger: 'HIGH',
    } ), [] );

    const priorityType: ORButtonType = useMemo( () =>
    {
        if ( !date ) return 'warning';

        const nextDue = moment( date );

        const now = moment();

        // if before next made up to date
        if ( now < nextDue )
        {
            return 'success';
        }

        // if after 1 month before next due
        if ( now > nextDue.clone().subtract( 1, 'month' ) )
        {
            return 'danger';
        }

        // if between next made up to and 1 month before next due
        return 'warning';
    }, [ date ] );

    return {
        priorityType,
        priority: priorityMap[ priorityType ]
    };
};