export const DATASTORE_SPECIAL_CONTENT_BOARD_RESOLUTIONS = 'BOARD_RESOLUTIONS';
export const DATASTORE_SPECIAL_CONTENT_COMPANY_FILINGS = 'COMPANY_FILINGS';
export const DATASTORE_SPECIAL_CONTENT_ANNUAL_SUBMISSIONS = 'ANNUAL_SUBMISSIONS';
export const DATASTORE_SPECIAL_CONTENT_CAP_TABLE = 'CAP_TABLE';
export const DATASTORE_SPECIAL_CONTENT_SHARE_CERTIFICATES = 'SHARE_CERTIFICATES';
export const DATASTORE_SPECIAL_CONTENT_STAKEHOLDER_UPDATES = 'STAKEHOLDER_UPDATES';
export const DATASTORE_SPECIAL_CONTENT_COMPANY_INFORMATION = 'COMPANY_INFORMATION';
export const DATASTORE_SPECIAL_CONTENT_ROOT = 'DOCUMENT_ROOT';
export const DATASTORE_SPECIAL_CONTENT_FILE = 'FILE';
export const DATASTORE_SPECIAL_CONTENT_USER_FOLDER = 'USER_FOLDER';

export type DATASTORE_SPECIAL_CONTENT_TYPE =
      typeof DATASTORE_SPECIAL_CONTENT_BOARD_RESOLUTIONS |
      typeof DATASTORE_SPECIAL_CONTENT_CAP_TABLE |
      typeof DATASTORE_SPECIAL_CONTENT_COMPANY_FILINGS |
      typeof DATASTORE_SPECIAL_CONTENT_ANNUAL_SUBMISSIONS |
      typeof DATASTORE_SPECIAL_CONTENT_SHARE_CERTIFICATES |
      typeof DATASTORE_SPECIAL_CONTENT_STAKEHOLDER_UPDATES |
      typeof DATASTORE_SPECIAL_CONTENT_COMPANY_INFORMATION |
      typeof DATASTORE_SPECIAL_CONTENT_ROOT |
      typeof DATASTORE_SPECIAL_CONTENT_FILE |
      typeof DATASTORE_SPECIAL_CONTENT_USER_FOLDER;
export const DATASTORE_SPECIAL_CONTENT_MAP = {
    [ DATASTORE_SPECIAL_CONTENT_FILE ]: 0,
    [ DATASTORE_SPECIAL_CONTENT_BOARD_RESOLUTIONS ]: 1,
    [ DATASTORE_SPECIAL_CONTENT_COMPANY_FILINGS ]: 2,
    [ DATASTORE_SPECIAL_CONTENT_ANNUAL_SUBMISSIONS ]: 3,
    [ DATASTORE_SPECIAL_CONTENT_CAP_TABLE ]: 4,
    [ DATASTORE_SPECIAL_CONTENT_SHARE_CERTIFICATES ]: 5,
    [ DATASTORE_SPECIAL_CONTENT_STAKEHOLDER_UPDATES ]: 6,
    [ DATASTORE_SPECIAL_CONTENT_COMPANY_INFORMATION ]: 7,
    [ DATASTORE_SPECIAL_CONTENT_ROOT ]: 100,
    [ DATASTORE_SPECIAL_CONTENT_USER_FOLDER ]: 101,
} as const;
export type DATASTORE_SPECIAL_CONTENT_CODE = typeof DATASTORE_SPECIAL_CONTENT_MAP[keyof typeof DATASTORE_SPECIAL_CONTENT_MAP];

interface IDatastorePostEntityAttributes
{
    copy_from?: string,
    move_from?: string,
    comment?: string,
}

export interface IDatastoreCreateFileAttributes extends IDatastorePostEntityAttributes
{
    file_name: string,
    file_data: File,
}

export interface IDatastoreCreateFolderAttributes extends IDatastorePostEntityAttributes
{
    folder_name: string,
}

export interface IDatastorePatchEntityAttributes
{
    name: string,
    comment?: string
}

export interface IDatastoreEntity
{
    uuid: string,
    /**
     * Stays consistent across versions of the same file
     */
    item_uuid: string,
    name: string,
    path: string,
    root_code: DATASTORE_SPECIAL_CONTENT_CODE,
    root_code_type: DATASTORE_SPECIAL_CONTENT_TYPE,
    parent_uuid: string | undefined,
    parent_item_uuid: string | undefined,
    parent_path: string | undefined,
    parent_root_code: IDatastoreEntity['root_code'] | undefined,
    comment: string,
    date: string | undefined,
    uploaded_by_user: string,
}

export interface IDatastoreFile extends IDatastoreEntity
{
    bucket_arn: string,
    download_url: string,
    object_name: string,
    size_bytes: number,
    root_code: typeof DATASTORE_SPECIAL_CONTENT_MAP[typeof DATASTORE_SPECIAL_CONTENT_FILE],
    root_code_type: typeof DATASTORE_SPECIAL_CONTENT_FILE,
    versions: IDatastoreFile[],
    /**
     * The next 3 fields are only present if the file is a versioned file
     */
    is_versioned?: boolean,
    archive_date?: string,
    current_version_download_url?: string,
}

export interface IDatastoreFolder extends IDatastoreEntity
{
    contents: Record<DATASTORE_SPECIAL_CONTENT_TYPE, DatastoreItemType> | null,
}

export interface IDatastoreRootFolder
      extends Omit<IDatastoreFolder,
            'name' | 'path' | 'parent_uuid' | 'parent_item_uuid' |
            'parent_path' | 'parent_root_code' | 'root_code' | 'root_code_type'>
{
    name: '/',
    path: '/',
    parent_uuid: never,
    parent_item_uuid: never,
    parent_path: never,
    parent_root_code: never,
    root_code: typeof DATASTORE_SPECIAL_CONTENT_MAP[typeof DATASTORE_SPECIAL_CONTENT_ROOT],
    root_code_type: typeof DATASTORE_SPECIAL_CONTENT_ROOT,
}

export type DatastoreItemType = IDatastoreFile | IDatastoreFolder | IDatastoreRootFolder;
