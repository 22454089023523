import { call, takeEvery } from 'redux-saga/effects';
import { globalAttachmentCreateSuccess } from '@/store/_global/attachments/attachment.actions';
import { uploadToAWS } from '@/shared/tools';
import { getAttachmentFileFromStore, removeAttachmentFileFromStore } from '@/store/_global/attachments/attachment.store';


function* onAttachmentPostSuccessScript( { payload }: ReturnType<typeof globalAttachmentCreateSuccess> )
{
    yield call( uploadToAWS, payload.presignedUploadUrl.link, getAttachmentFileFromStore( payload ) );
    removeAttachmentFileFromStore( payload );
}

export default function* onAttachmentPostSuccess()
{
    yield takeEvery( [ globalAttachmentCreateSuccess ], onAttachmentPostSuccessScript );
}