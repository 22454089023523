import { createAction } from '@reduxjs/toolkit';

import { datastoreSliceKey } from '@/store/datastore/slice.key';
import type { IDatastoreFile, IDatastoreFolder } from '@/features/datastore/models/datastore.django.interface';


export const datastoreFileCreateSuccess = createAction<IDatastoreFile>( `${ datastoreSliceKey }/datastoreFileCreateSuccess` );
export const datastoreFileRevisionCreateSuccess = createAction<IDatastoreFile>( `${ datastoreSliceKey }/datastoreFileRevisionCreateSuccess` );
export const datastoreFileFetchSuccess = createAction<IDatastoreFile>( `${ datastoreSliceKey }/datastoreFileFetchSuccess` );
export const datastoreFolderCreateSuccess = createAction<IDatastoreFolder>( `${ datastoreSliceKey }/datastoreFolderCreateSuccess` );
export const datastoreFolderFetchSuccess = createAction<IDatastoreFolder>( `${ datastoreSliceKey }/datastoreFolderFetchSuccess` );