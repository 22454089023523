import { type FC, memo, useCallback } from 'react';
import styled from 'styled-components';
import useMount from 'ahooks/lib/useMount';
import tw from 'tailwind-styled-components';

import { useCurrentUser } from '@/store/user/hooks';

import { Form, FormItem, Radio, RadioGroup } from '@/components/structural/form';
import { Typography } from '@/components/structural';

import { AuthPageFormButton } from '@/features/auth/components';

import { getCookie, setCookie } from '@/shared/tools';
import { useBeforeMount } from '@/shared/hooks';

import type { IORUser } from '@/features/user/models';

import twConfig from '@/shared/tailwindConfig';


export interface IUserTypeSelectionProps
{
    onNext?: ( values: { user_type: IORUser['settings']['user_type'] } ) => void,
    hideNextButton?: boolean,
}

export const UserTypeSelection: FC<IUserTypeSelectionProps> = memo( ( {
    onNext = () => null,
    hideNextButton = false,
} ) =>
{
    const { me } = useCurrentUser();

    const processValues = useCallback( ( values ) =>
    {
        setCookie( {
            cName: 'orNewSignupUserType',
            cValue: values.user_type
        } );

        onNext( { user_type: values.user_type } );
    }, [] );

    useBeforeMount( () =>
    {
        if ( !getCookie( { cName: 'orNewSignupUserType' } ) )
        {
            setCookie( {
                cName: 'orNewSignupUserType',
                cValue: 'investor'
            } );
        }
    } );

    useMount( () =>
    {
        onNext( { user_type: getCookie( { cName: 'orNewSignupUserType' } ) as IORUser['settings']['user_type'] } );
    } );

    return ( <>
        <Typography.Title level={ 4 } className="mt-4 font-poppins">
            Welcome{ me?.first_name ? `, ${ me?.first_name }` : '' }!
        </Typography.Title>
        <Typography.Paragraph className="mt-4 font-roboto">
            Let&#39;s get started. First, tell us about you so that we can personalise your experience.
        </Typography.Paragraph>
        <Form
              name="auth"
              onValuesChange={ processValues }
              initialValues={ {
                  user_type: getCookie( { cName: 'orNewSignupUserType' } )
              } }
              className="mt-4"
        >
            <FormItem
                  name="user_type"
                  rules={ [ { required: true, message: 'Please choose one!', type: 'string' } ] }
            >
                <RadioGroup className="w-full">
                    <div className="w-full grid grid-rows-3 grid-cols-1">
                        <RadioStyled value="investor">
                            <OptionTitle>
                                I&#39;m an investor
                            </OptionTitle>
                            <OptionBody>
                                I want to manage a portfolio of investments
                            </OptionBody>
                        </RadioStyled>
                        <RadioStyled value="company">
                            <OptionTitle>
                                I&#39;m a company user
                            </OptionTitle>
                            <OptionBody>
                                I want to manage my companies&#39; share capital, statutory filings, and more
                            </OptionBody>
                        </RadioStyled>
                        <RadioStyled value="investor-company">
                            <OptionTitle>
                                I&#39;m both
                            </OptionTitle>
                            <OptionBody>
                                I want to manage a portfolio of investments and I have companies that I want to manage
                            </OptionBody>
                        </RadioStyled>
                    </div>
                </RadioGroup>
            </FormItem>

            { !hideNextButton && <FormItem wrapperCol={ { span: 24 } }>
                <AuthPageFormButton>
                    NEXT
                </AuthPageFormButton>
            </FormItem> }
        </Form>
    </> );
} );

UserTypeSelection.displayName = 'UserTypeSelection';

const RadioStyledRaw = styled( Radio )`
    &:not(.ant-radio-wrapper-checked) {
        border-color: ${ twConfig.theme.colors.border.DEFAULT };
        border-width: 0.08rem;
    }

    .ant-radio {
        height: 1.15rem;
    }

    .ant-radio ~ span {
        align-self: start;
    }
`;

const RadioStyled = tw( RadioStyledRaw )`
    w-full
    border-2
    border-solid
    border-success
    rounded-xl
    p-4
    mb-4
`;

const OptionTitle = tw( Typography.Paragraph )`
    text-success
`;

const OptionBody = tw( Typography.Paragraph )`
    !mb-0 font-roboto
`;