import { type FC, memo } from 'react';

import { DataFormat, FieldContainerCols, FieldEditText, FieldsWrapperGrid, FieldTitleCenter, FieldValue } from '@/components/structural';
import { formatWordsWithSeparator } from '@/shared/tools';

import type { IFormStepProps } from '@/components/structural/form';
import type { ORJSON } from '@/shared/models';

import type { IStep1Data } from './Step1';
import type { IStep2Data } from './Step2';


export interface IStep3Data extends ORJSON
{
    step1: IStep1Data,
    step2: IStep2Data,
}

export const Step3: FC<IFormStepProps<IStep3Data>> = memo( ( { initialData: data, goToStep } ) =>
{
    return <>
        <div className="grid grid-cols-1 place-items-center w-full mb-4">
            <FieldsWrapperGrid>
                <FieldContainerCols>
                    <FieldTitleCenter>
                        Proposed Name
                    </FieldTitleCenter>
                    <FieldValue>
                        <DataFormat raw={ data.step2?.proposed_name } className="text-inherit font-medium" inline/>
                        <FieldEditText onClick={ () => goToStep( 1 ) }/>
                    </FieldValue>
                </FieldContainerCols>
                <FieldContainerCols>
                    <FieldTitleCenter>
                        Method of change
                    </FieldTitleCenter>
                    <FieldValue>
                        <DataFormat raw={ formatWordsWithSeparator( data.step2?.notice_of_change ) } className="text-inherit font-medium" inline/>
                    </FieldValue>
                </FieldContainerCols>
                <FieldContainerCols>
                    <FieldTitleCenter>
                        Notice of change given<br/>to shareholders
                    </FieldTitleCenter>
                    <FieldValue>
                        <DataFormat raw={ data.step2?.confirm_resolution_file_distributed } type="boolean-text" className="text-inherit text-left font-medium"/>
                    </FieldValue>
                </FieldContainerCols>
                <FieldContainerCols>
                    <FieldTitleCenter>
                        Date of general meeting<br/>or resolution
                    </FieldTitleCenter>
                    <FieldValue>
                        <DataFormat raw={ data.step2?.resolution_date } type="date" className="text-inherit text-left font-medium" inline/>
                    </FieldValue>
                </FieldContainerCols>
                <FieldContainerCols>
                    <FieldTitleCenter>
                        Service required
                    </FieldTitleCenter>
                    <FieldValue>
                        <DataFormat raw={ formatWordsWithSeparator( data.step1?.service ) } className="text-inherit text-left font-medium" inline/>
                        <FieldEditText onClick={ () => goToStep( 0 ) }/>
                    </FieldValue>
                </FieldContainerCols>
                <FieldContainerCols>
                    <FieldTitleCenter>
                        Fee payable
                    </FieldTitleCenter>
                    <FieldValue>
                        <DataFormat raw={ 8 } type="currency" keepDecimals className="text-inherit text-left font-medium"/>
                    </FieldValue>
                </FieldContainerCols>
            </FieldsWrapperGrid>
        </div>
    </>;
} );

Step3.displayName = 'CompanyChangeRegisteredNameStep3';