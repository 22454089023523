import tw from 'tailwind-styled-components';


const FieldsWrapperBase = tw.div`
    fields-wrapper
    w-full
`;

export const FieldsWrapperGrid = tw( FieldsWrapperBase )`
    grid
    grid-cols-1
`;

export const FieldsWrapperFlex = tw( FieldsWrapperBase )`
    flex
    flex-wrap
    place-content-between
`;

const FieldContainerBase = tw.div`
    field-container
    grid
    gap-4
`;

export const FieldContainerCols = tw( FieldContainerBase )`
    grid-cols-2
    mb-2
`;

export const FieldContainerRows = tw( FieldContainerBase )`
    grid-rows-2
    mr-4
`;

const FieldTitleBase = tw.div`
    field-title
    text-right
    font-roboto
    grid
    text-default
    dark:text-default-dark
`;

export const FieldTitleCenter = tw( FieldTitleBase )`
    items-center
`;

export const FieldTitleTop = tw( FieldTitleBase )`
    items-start
`;

const FieldValueBase = tw.div`
    field-value
    font-normal
`;

export const FieldValue = tw( FieldValueBase )`
    text-primary
    dark:text-primary-dark
`;

export const FieldValueDefault = tw( FieldValueBase )`
    text-default
    dark:text-default-dark
`;

export const FieldValueGray = tw( FieldValueBase )`
    text-default-form-field
    dark:text-default-dark
`;

export const FieldEditText = tw.span`
    field-edit-text
    text-info
    dark:text-info-dark
    after:content-['Edit']
    ml-4
    hover:cursor-pointer
    font-roboto
`;