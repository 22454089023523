import { useMemo } from 'react';

import { getMetaDefaultObject } from '@/store/_rtk-query/getMetaDefaultObject';
import { useCurrentUser } from '@/store/user/hooks';

import { noteAdapter, useGetManyNotesQuery, useGetOneNoteQuery } from '@/store/company';
import { noteSelectors } from '@/store/company/selectors';

import { compare } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORGetParameters } from '@/store/_models/get-parameters.interface';


export const useNotes = ( getParameters: IORGetParameters = { page: 1, per_page: DEFAULT_PER_PAGE }, skip = false ) =>
{
    const { awsUser } = useCurrentUser();
    const { data, isFetching, isLoading, error } = useGetManyNotesQuery( getParameters, {
        skip: skip || !awsUser
    } );

    const notes = noteSelectors.selectAll(
          data || noteAdapter.getInitialState()
    );

    notes.sort( ( a, b ) => compare( a.created_at, b.created_at ) );

    const meta = data?.meta || getMetaDefaultObject();

    return useMemo( () => ( {
        notes,
        meta,
        isLoading,
        isFetching,
        error
    } ), [ data, isFetching, isLoading, error ] );
};

export const useNote = ( uuid: string, skip = false ) =>
{
    const { awsUser } = useCurrentUser();
    const { data: note, isFetching, isLoading, error } = useGetOneNoteQuery( uuid, {
        skip: skip || !awsUser
    } );

    return useMemo( () => ( {
        note,
        isLoading,
        isFetching,
        error
    } ), [ note, isFetching, isLoading, error ] );
};