import type { IStoreActionType } from '@/store/_global/actions-store/actions.reducer';


/**
 * if looking for an rtk-query action, typePath format is 'endpoint/actionType'
 * if looking for an non-rtk-query action, typePath format is 'sliceActionType'
 */
export const isAction = ( action: IStoreActionType, storeSliceKey: string, typePath: string ) =>
{
    const path = typePath.split( '/' );

    const checkPart = ( action.storeSliceKey === storeSliceKey &&
          action.endpoint === path[ 0 ] );

    if ( action.isApi )
    {
        return checkPart &&
              action.type === path[ 1 ];
    }

    return checkPart;
};

export const isActionFn = ( action: IStoreActionType ) =>
      /**
       * if looking for a rtk-query action, typePath format is 'endpoint/actionType'
       * if looking for a non-rtk-query action, typePath format is 'sliceActionType'
       */
      ( storeSliceKey: string, typePath: string ) =>
            isAction( action, storeSliceKey, typePath );