import type { InputNumberProps } from 'antd/lib/input-number';

import { addNumberThousandsSeparators, removeNumberThousandsSeparators } from '@/shared/tools';


export const inputNumberFormatter: InputNumberProps['formatter'] = ( value: string, { userTyping } ) =>
{
    if ( userTyping ) return value;
    if ( value === '' ) return '';
    return addNumberThousandsSeparators( { num: value, keepDecimals: true } );
};

export const inputWholeNumberFormatter: InputNumberProps['formatter'] = ( value: string, { userTyping } ) =>
{
    if ( userTyping ) return value;
    if ( value === '' ) return '';
    return addNumberThousandsSeparators( { num: value, keepDecimals: false } );
};

export const inputNumberParser = ( value: string ) =>
{
    if ( value === '' ) return '';
    return removeNumberThousandsSeparators( value );
};