import { useMemo } from 'react';

import { getMetaDefaultObject } from '@/store/_rtk-query/getMetaDefaultObject';
import { useCurrentCompany } from './useCurrentCompany.hook';

import { stakeholderAdapter, useGetManyStakeholdersQuery } from '../service.stakeholder';
import { stakeholderSelectors } from '../selectors';

import { compare } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORGetParameters } from '@/store/_models/get-parameters.interface';


export const useStakeholders = ( getParameters: IORGetParameters = { page: 1, per_page: DEFAULT_PER_PAGE }, skip = false ) =>
{
    const { orCompany } = useCurrentCompany();
    const { data, isFetching, isLoading, error, status } = useGetManyStakeholdersQuery( getParameters, {
        skip: skip || !orCompany
    } );

    const stakeholders = stakeholderSelectors.selectAll(
          data ||
          stakeholderAdapter.getInitialState()
    );

    stakeholders.sort( ( a, b ) => compare( a.entity_name, b.entity_name, true ) );

    const meta = data?.meta || getMetaDefaultObject();

    return useMemo( () => ( {
        stakeholders,
        meta,
        isLoading,
        isFetching,
        error,
        status
    } ), [ data, isFetching, isLoading, error, status ] );
};