import { useMemo } from 'react';

import { useAppSelector } from '@/store/hooks';
import { selectCompanyWidgetsFilingDeadlines } from '../selectors';


export const useCompanyWidgetsFilingDeadlines = () =>
{
    const { selectedCompany } = useAppSelector( selectCompanyWidgetsFilingDeadlines );

    return useMemo( () => ( {
              selectedCompany
          } ),
          [ selectedCompany ]
    );
};