import { useMemo } from 'react';

import { useGetOneUserQuery } from '@/store/user';


export const useUser = ( uuid: string, skip = false ) =>
{
    const { data: user, isFetching, isLoading, error, status } = useGetOneUserQuery( uuid, {
        skip: skip
    } );

    return useMemo( () => ( {
        user,
        isLoading,
        isFetching,
        error,
        status
    } ), [ user, isFetching, isLoading, error, status ] );
};