import { type FC, type ReactNode } from 'react';
import tw from 'tailwind-styled-components';

import Typography from 'antd/lib/typography';

import { SVGIcon } from '@/components/structural/images';


interface IModalTitleProps
{
    icon: string,
    title: ReactNode,
    className?: string
}

export const ModalTitle: FC<IModalTitleProps> = ( { icon, title, className = '' } ) => ( <>
    <Wrapper className={ className }>
        { icon && <SVGIcon src={ icon } className="block !h-6 !w-6 !mr-2 flex-shrink text-success dark:text-success-dark"/> }
        <Typography.Text className="text-primary dark:text-primary-dark text-2xl font-normal">{ title }</Typography.Text>
    </Wrapper>
</> );

const Wrapper = tw.div`
    flex
    items-center
`;