import type { ICHCompanyAddress } from '@/features/company/shared/models';


export const formatCompanyAddress = ( chAddress: ICHCompanyAddress ) =>
{
    if ( !chAddress )
    {
        return '';
    }

    return [
        chAddress.address_line_1,
        chAddress.address_line_2,
        chAddress.locality,
        chAddress.postal_code,
        chAddress.country
    ].filter( text => !!text ).join( ', ' );
};