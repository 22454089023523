import { type FC, memo, type ReactNode, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import tw from 'tailwind-styled-components';

import { useCurrentCompany, useShareClasses, useShareRounds, useStakeholders } from '@/store/company/hooks';
import { usePatchCompanyMutation } from '@/store/company';

import { Button, Card, Link, Loader, routerNavigate, Tabs } from '@/components/structural';
import { SVGIcon } from '@/components/structural/images';

import stepCompleteIcon from '@/icons/check.svg';


export const WelcomeWidget: FC = memo( () =>
{
    const [ patchCompany ] = usePatchCompanyMutation();
    const { orCompany, isFetching: companyIsFetching } = useCurrentCompany();
    const { shareClasses, isFetching: shareClassesAreFetching } = useShareClasses();
    const { stakeholders, isFetching: stakeholdersAreFetching } = useStakeholders();
    const { shareRounds, isFetching: shareRoundsAreFetching } = useShareRounds();

    const [ activeTab, setActiveTab ] = useState( '1' );
    const [ completedSteps, setCompletedSteps ] = useState<string[]>( [] );
    const [ allStepsDone, setAllStepsDone ] = useState( false );
    const isStepComplete = useCallback( ( step: string ) => (
          completedSteps.includes( step )
    ), [ completedSteps ] );

    const navigateToShareClasses = useCallback( () => routerNavigate( '/share-capital/share-classes' ), [] );
    const navigateToStakeholders = useCallback( () => routerNavigate( '/stakeholders' ), [] );
    const navigateToShareRounds = useCallback( () => routerNavigate( '/share-capital/share-rounds' ), [] );
    const completeWelcomeSetup = useCallback( () =>
    {
        orCompany && patchCompany( {
            uuid: orCompany.uuid,
            data: {
                setup_complete: true
            }
        } );
    }, [ orCompany ] );

    useEffect( () =>
    {
        allStepsDone && setAllStepsDone( false );
    }, [ orCompany ] );

    useEffect( () =>
    {
        const steps: string[] = [];
        if ( shareClasses?.length ) steps.push( '1' );
        if ( stakeholders?.length ) steps.push( '2' );
        if ( shareRounds?.length ) steps.push( '3' );

        if ( steps.length === 3 )
        {
            !allStepsDone && setAllStepsDone( true );
            steps.push( 'end' );
            setActiveTab( 'end' );
        } else
        {
            const tab = String( steps.length + 1 );
            tab !== activeTab && setActiveTab( tab );
        }

        setCompletedSteps( steps );
    }, [ shareClasses, stakeholders, shareRounds, allStepsDone, activeTab ] );

    if ( companyIsFetching ||
          shareClassesAreFetching ||
          stakeholdersAreFetching ||
          shareRoundsAreFetching
    ) return <Loader skeleton/>;

    return ( <>
        <CardWrapper title="Getting Started">
            <Tabs
                  className="mb-4 md:mb-0"
                  activeKey={ activeTab }
                  onChange={ ( key ) => setActiveTab( key ) }
                  tabPosition="left"
                  items={ [
                      getTab( {
                          tabKey: '1',
                          title: 'Add Share Classes',
                          isStepComplete,
                          activeTab,
                          childrenNotComplete: <>
                              <Paragraph>
                                  First things first, you need to configure&nbsp;
                                  <LinkStyled to="/share-capital/share-classes">Share Classes</LinkStyled>.
                              </Paragraph>
                              <Paragraph>
                                  To navigate to Share Classes simply click on Share Capital in the top-level navigation.&nbsp;
                                  You can choose either to add them individually by clicking on the +Add button, or, if you prefer,&nbsp;
                                  you can download our handy template from the download button.
                              </Paragraph>
                              <Paragraph>
                                  Fill in the fields on the spreadsheet and then simply upload it back in to the table by&nbsp;
                                  dragging and dropping it in, or by using the upload button.
                              </Paragraph>
                              <ButtonWrapper>
                                  <Button type="success" onClick={ navigateToShareClasses } className="w-56">
                                      + ADD SHARE CLASSES
                                  </Button>
                              </ButtonWrapper>
                          </>,
                          childrenComplete: <>
                              <Paragraph>
                                  You&apos;ve added <LinkStyled to="/share-capital/share-classes">Share Classes</LinkStyled>.
                              </Paragraph>
                              <Paragraph>
                                  If you need to edit the information you added or need to add more, simply navigate back to the Share Classes page.
                              </Paragraph>
                          </>
                      } ),
                      getTab( {
                          tabKey: '2',
                          title: 'Add Stakeholders',
                          isStepComplete,
                          activeTab,
                          childrenNotComplete: <>
                              <Paragraph>
                                  Next you&apos;ll need to add&nbsp;
                                  <LinkStyled to="/stakeholders">Stakeholders</LinkStyled>.
                              </Paragraph>
                              <Paragraph>
                                  To navigate to Stakeholders simply click on Stakeholders in the top-level navigation.&nbsp;
                                  You can choose either to add them individually by clicking on the +Add&nbsp;
                                  button, or, if you prefer, you can download our handy template from the download button.
                              </Paragraph>
                              <Paragraph>
                                  Fill in the fields on the spreadsheet and then simply upload it back in to the table by&nbsp;
                                  dragging and dropping it in, or by using the upload button. You’ll issue shares to&nbsp;
                                  your Stakeholders in the next step.
                              </Paragraph>
                              <ButtonWrapper>
                                  <Button type="success" onClick={ navigateToStakeholders } className="w-56">
                                      + ADD STAKEHOLDERS
                                  </Button>
                              </ButtonWrapper>
                          </>,
                          childrenComplete: <>
                              <Paragraph>
                                  You&apos;ve added <LinkStyled to="/stakeholders">Stakeholders</LinkStyled>.
                              </Paragraph>
                              <Paragraph>
                                  If you need to edit the information you added or need to add more, simply navigate back to the Stakeholders page.
                              </Paragraph>
                          </>
                      } ),
                      getTab( {
                          tabKey: '3',
                          title: 'Add Share Rounds',
                          isStepComplete,
                          activeTab,
                          childrenNotComplete: <>
                              <Paragraph>
                                  Next you&apos;ll need to add any previous&nbsp;
                                  <LinkStyled to="/share-capital/share-rounds">Rounds</LinkStyled>.
                              </Paragraph>
                              <Paragraph>
                                  To navigate to Rounds, simply click on Share Capital in the top-level navigation.&nbsp;
                                  First of all we’ll ask you to set up the Round, including information such as how much&nbsp;
                                  capital you raised. You can choose either to add them individually by clicking on the +Add button,&nbsp;
                                  or, if you prefer, you can download our handy template from the download&nbsp;
                                  button. Fill in the fields on the spreadsheet and simply upload it back in to the table by dragging&nbsp;
                                  and dropping it in, or by using the upload button.
                              </Paragraph>
                              <Paragraph>
                                  To add Shareholders to the Round, simply select the relevant record from the table and then&nbsp;
                                  add Shareholders manually or in bulk following the same steps you’ve used previously.
                              </Paragraph>
                              <ButtonWrapper>
                                  <Button type="success" onClick={ navigateToShareRounds } className="w-56">
                                      + ADD ROUNDS
                                  </Button>
                              </ButtonWrapper>
                          </>,
                          childrenComplete: <>
                              <Paragraph>
                                  You&apos;ve added <LinkStyled to="/share-capital/share-rounds">Rounds</LinkStyled>.
                              </Paragraph>
                              <Paragraph>
                                  If you need to edit the information you added or need to add more, simply navigate back to the Rounds page.
                              </Paragraph>
                          </>
                      } ),
                      allStepsDone && getTab( {
                          tabKey: 'end',
                          title: 'All done!',
                          isStepComplete,
                          activeTab,
                          childrenComplete: <>
                              <Paragraph>
                                  You’ve now completed your account set up.
                              </Paragraph>
                              <Paragraph>
                                  If you need to add in new information in the future, you’ll be able to do it in&nbsp;
                                  exactly the same way that you used for set up. You can also edit records by clicking on the&nbsp;
                                  relevant record in each table. Simply navigate to the correct table and then click&nbsp;
                                  on the hyperlink in the first column.
                              </Paragraph>
                              <Paragraph>
                                  You can close this message now and start using your OneRegistry account.
                              </Paragraph>
                              <ButtonWrapper>
                                  <Button type="default" onClick={ completeWelcomeSetup } className="w-56">
                                      CLOSE
                                  </Button>
                              </ButtonWrapper>
                          </>
                      } )
                  ] }
            />
        </CardWrapper>
    </> )
          ;
} );

WelcomeWidget.displayName = 'WelcomeWidget';

const getTab = ( props: {
    isStepComplete: ( step: string ) => boolean,
    activeTab: string,
    tabKey: string,
    title: string,
    childrenComplete: ReactNode | ReactNode[],
    childrenNotComplete?: ReactNode | ReactNode[],
} ) => ( {
    label: props.title && <TabWrapper $isActive={ props.activeTab === props.tabKey }>
        <TabIconWrapper
              $stepComplete={ props.isStepComplete( props.tabKey ) }
              $isActive={ props.activeTab === props.tabKey }
        >
            <TabIconInner>
                { props.isStepComplete( props.tabKey ) ?
                      <TabIconComplete src={ stepCompleteIcon.src }/>
                      :
                      <span className="text-sm">{ props.tabKey }</span>
                }
            </TabIconInner>
        </TabIconWrapper>
        <TabTitleText
              $isActive={ props.activeTab === props.tabKey }
              $stepComplete={ props.isStepComplete( props.tabKey ) }
              $isEnd={ props.tabKey === 'end' }
        >
            { props.title }
        </TabTitleText>
    </TabWrapper>,
    key: props.tabKey,
    forceRender: true,
    children: <TabContentWrapper>
        { props.isStepComplete( props.tabKey ) ?
              props.childrenComplete
              :
              props.childrenNotComplete
        }
    </TabContentWrapper>,
} );

const CardWrapper = styled( Card )`
    & .ant-tabs {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        @media (max-width: 567px) {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
    }

    & .ant-tabs-content {

    }

    & .ant-tabs-tab {
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        .ant-tabs-tab-btn {
            width: 100%;
        }

        &:not(:first-of-type) {
            margin-top: 0.25rem !important;
        }
    }
`;

const TabWrapper = tw.div<{ $isActive: boolean }>`
    flex
    items-center
    text-xl
    p-2
    rounded
    ${ props => props.$isActive ?
      `bg-background 
        dark:bg-background-dark`
      : '' }
`;

const TabIconWrapper = tw.div<{ $isActive: boolean, $stepComplete: boolean }>`
    border
    rounded-full
    ${ props => props.$isActive ?
      `text-success 
        dark:text-success-dark 
      bg-white 
        dark:bg-background-dark`
      :
      `text-default 
        dark:text-default-dark 
      bg-background 
        dark:bg-background-medium` }
    ${ props => props.$stepComplete ?
      `!bg-success 
            dark:!bg-success-dark
        !text-white
            dark:text-default-dark
        ` : '' }
`;

const TabIconInner = tw.div`
    h-4
    w-4
    p-4
    grid
    place-content-center
`;

const TabIconComplete = tw( SVGIcon )`
    !h-6
    !w-6
    !m-0
`;

const TabTitleText = tw.a<{ $isActive: boolean, $stepComplete: boolean, $isEnd: boolean }>`
    text-sm
    ml-2
    ${ props => props.$isActive ?
      `text-success
        dark:text-success-dark`
      :
      `text-default
        dark:text-default-dark` }
    ${ props => props.$stepComplete && !props.$isEnd ?
      `line-through 
      hover:line-through`
      : '' }
`;

const TabContentWrapper = tw.div`
    bg-background
    dark:bg-background-dark
    p-4
    rounded
`;

const Paragraph = tw.div`
    mb-4
`;

const LinkStyled = tw( Link )`
    text-primary 
    dark:text-primary-dark
`;

const ButtonWrapper = tw.div`
    grid 
    place-items-center
`;