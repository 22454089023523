import { memo } from 'react';

import { Breadcrumb, Typography } from '@/components/structural';


export const CompaniesOfficersHelpText = memo( () => <>
    <Breadcrumb separator={ <span>&gt;</span> } className="mb-1 font-poppins">
        <Breadcrumb.Item>Help Centre</Breadcrumb.Item>
        <Breadcrumb.Item>Getting Started</Breadcrumb.Item>
    </Breadcrumb>
    <div className="font-roboto">
        <Typography.Title level={ 5 } className="font-poppins mt-0">Officers</Typography.Title>
        <Typography.Paragraph className="mb-4">
            The table provided on this page gives an overview of the Directors and Secretaries of the business.
        </Typography.Paragraph>
        <Typography.Paragraph className="mb-4">
            From here you can appoint new Directors or Secretaries, make changes or terminate appointments.
        </Typography.Paragraph>
        <Typography.Paragraph className="mb-4">
            Changes you make here will also be recorded in the Companies House Filing Log.
        </Typography.Paragraph>
        <Typography.Paragraph className="break-words">
            More information relating to the responsibilities and duties of Directors and Secretaries can be found on the
            Companies House website <a href="https://www.gov.uk/guidance/being-a-company-director" target="_blank" rel="noreferrer">here</a>&nbsp;
            <span className="break-all">(<a href="https://www.gov.uk/guidance/being-a-company-director" target="_blank" rel="noreferrer">
                https://www.gov.uk/guidance/being-a-company-director</a>)</span>
        </Typography.Paragraph>
    </div>
</> );

CompaniesOfficersHelpText.displayName = 'CompaniesOfficersHelpText';

