import Head from 'next/head';
import { type FC, memo, type PropsWithChildren } from 'react';

import { FullScreenOverlay } from '@/components/structural';


export interface IAppLayout404Props
{
    pageTitle: string,
}

export const AppLayout404: FC<PropsWithChildren<IAppLayout404Props>> = memo( ( props ) =>
{
    return ( <>
        <Head>
            <title>OneRegistry React - Page not found</title>
        </Head>
        <FullScreenOverlay className="!p-0 !block">
            { props.children }
        </FullScreenOverlay>
    </> );
} );

AppLayout404.displayName = 'AppLayout404';