import { memo } from 'react';

import { Breadcrumb, Typography } from '@/components/structural';


export const CompaniesPSCsHelpText = memo( () => <>
    <Breadcrumb separator={ <span>&gt;</span> } className="!mb-1 font-poppins">
        <Breadcrumb.Item>Help Centre</Breadcrumb.Item>
        <Breadcrumb.Item>Getting Started</Breadcrumb.Item>
    </Breadcrumb>
    <div className="font-roboto">
        <Typography.Title level={ 5 } className="font-poppins mt-0">People with Significant Control (PSCs)</Typography.Title>
        <Typography.Paragraph className="mb-4">
            The table provided on this page gives an overview of the PSCs of the business or statements relating to PSCs.
        </Typography.Paragraph>
        <Typography.Paragraph className="mb-4">
            All limited companies are required to maintain a register of people with significant control. This was introduced
            to provide greater transparency of ownership of UK companies.
        </Typography.Paragraph>
        <Typography.Paragraph className="mb-4">
            From this page you can give notice of PSCs, make changes, or give notice of an Individual ceasing to be a person
            with significant control.
        </Typography.Paragraph>
        <Typography.Paragraph className="mb-4">
            Changes you make here will also be recorded in the Companies House Filing Log.
        </Typography.Paragraph>
        <Typography.Paragraph className="break-words">
            More information relating on how to identify and record the people who own or control your company can be found on
            the Companies House website&nbsp;
            <a href="https://www.gov.uk/guidance/people-with-significant-control-pscs" target="_blank" rel="noreferrer">here</a>&nbsp;
            <span className="break-all">(<a href="https://www.gov.uk/guidance/people-with-significant-control-pscs" target="_blank" rel="noreferrer">
                https://www.gov.uk/guidance/people-with-significant-control-pscs</a>)</span>
        </Typography.Paragraph>
    </div>
</> );

CompaniesPSCsHelpText.displayName = 'CompaniesPSCsHelpText';

