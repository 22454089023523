export const addNumberThousandsSeparators = ( { num, keepDecimals = true, minDecimals = 2, maxDecimals = 20 }: {
    num: unknown,
    keepDecimals?: boolean,
    minDecimals?: number,
    maxDecimals?: number
} ): string =>
{
    const data = Number( num );

    if ( isNaN( data ) )
    {
        return '';
    }

    const minimumFractionDigits = keepDecimals ? minDecimals : 0;
    const maximumFractionDigits = keepDecimals ? maxDecimals : 0;

    const formattedNumber = new Intl.NumberFormat( undefined, {
        minimumFractionDigits,
        maximumFractionDigits,
    } ).format( data );

    // console.log( formattedNumber, keepDecimals, minimumFractionDigits, maximumFractionDigits );

    return formattedNumber;
};

export const removeNumberThousandsSeparators = ( value: string ): number =>
{
    return Number( value.replace( /(,*)/g, '' ) );
};