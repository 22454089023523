import Router from 'next/router';
import { takeEvery } from 'redux-saga/effects';

import { currentCompanyChanged } from '@/store/company/actions';

import { routerNavigate } from '@/components/structural';

import { getCookie } from '@/shared/tools';

import { routeAllowedOnMultipleCompanies } from '@/shared/constants';


function* onCurrentCompanyChangeScript( { payload: companyUuid }: ReturnType<typeof currentCompanyChanged> )
{
    const loggedIn = getCookie( { cName: 'orAuth' } ) === 'ok';

    if ( loggedIn &&
          !routeAllowedOnMultipleCompanies( companyUuid, Router.pathname )
    )
    {
        yield routerNavigate( '/my-company' );
    }
}

export function* onCurrentCompanyChangeSaga()
{
    yield takeEvery( [ currentCompanyChanged ], onCurrentCompanyChangeScript );
}