import { createAction } from '@reduxjs/toolkit';

import type { ConfirmSignUpOptions } from '@aws-amplify/auth/dist/esm/providers/cognito/types';
import type { IRequestError } from '@/shared/models';

import { authSliceKey } from '../slice.key';


export const doSignup = createAction<{ email: string, password: string, first_name: string, last_name: string }>( `${ authSliceKey }/doSignup` );
export const doSignupSuccess = createAction<{ email: string, first_name: string, last_name: string }>( `${ authSliceKey }/doSignup/success` );
export const doSignupError = createAction<IRequestError>( `${ authSliceKey }/doSignup/error` );

export const doSignupWithoutConfirmationEmail = createAction<{ email: string, password: string }>( `${ authSliceKey }/doSignupWithoutConfirmationEmail` );
export const doSignupWithoutConfirmationEmailSuccess = createAction<{ email: string }>( `${ authSliceKey }/doSignupWithoutConfirmationEmail/success` );
export const doSignupWithoutConfirmationEmailError = createAction<IRequestError>( `${ authSliceKey }/doSignupWithoutConfirmationEmail/error` );

export const doSignupResendConfirmationEmail = createAction<{ email: string }>( `${ authSliceKey }/doSignupResendConfirmationEmail` );
export const doSignupResendConfirmationEmailSuccess = createAction<{ email: string }>( `${ authSliceKey }/doSignupResendConfirmationEmail/success` );
export const doSignupResendConfirmationEmailError = createAction<IRequestError>( `${ authSliceKey }/doSignupResendConfirmationEmail/error` );

export const doSignupConfirmAccountWithCode = createAction<{ email: string, code: string, options?: ConfirmSignUpOptions }>( `${ authSliceKey }/doSignupConfirmAccountWithCode` );
export const doSignupConfirmAccountWithCodeSuccess = createAction<{ email: string }>( `${ authSliceKey }/doSignupConfirmAccountWithCode/success` );
export const doSignupConfirmAccountWithCodeError = createAction<IRequestError>( `${ authSliceKey }/doSignupConfirmAccountWithCode/error` );