import { actions } from '../slice';


export const {
    createAlert, clearAlert, clearAlerts,
    createBellAlert, makeBellAlertRead, makeAllBellAlertsRead, changeBellAlertActionResult, clearBellAlert, clearBellAlerts,
    createMessage, clearMessages,
    createNotification, clearNotifications,
    clearNotificationsState,
} = actions;
