import dynamic from 'next/dynamic';
import ConfigProviderAntD from 'antd/lib/config-provider';
import LayoutAntD from 'antd/lib/layout';


const PageHeaderAntD = dynamic( () => import('@ant-design/pro-layout/lib/components/PageHeader').then( a => a.PageHeader ), {
    ssr: false
} );

export const ConfigProvider = ConfigProviderAntD;
export const Layout = LayoutAntD;
export const PageHeader = PageHeaderAntD;