import { useEffect, useMemo, useState } from 'react';

import { selectActions, selectLastAction } from './actions.selector';
import { useAppSelector } from '@/store/hooks';

import { isAction, isActionFn } from './tools';

import type { TStoreAction } from '@/store/_global/actions-store/actions.reducer';


export const useActions = () =>
{
    const actions = useAppSelector( selectActions );

    return useMemo( () => ( {
        actions,
        isAction
    } ), [ actions ] );
};

export const useLastAction = ( isRelevant: ( la: TStoreAction ) => boolean = () => true ) =>
{
    const [ lastAction, setLastAction ] = useState<TStoreAction>( null );
    const newLastAction = useAppSelector( selectLastAction );

    useEffect( () =>
    {
        if ( newLastAction && isRelevant( newLastAction ) ) setLastAction( newLastAction );
    }, [ newLastAction ] );

    return useMemo( () => ( {
        lastAction,
        isAction: isActionFn( lastAction )
    } ), [ lastAction ] );
};