import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { configureReduxPersist } from '@/store/configureReduxPersist';

import { notificationsSliceKey } from './slice.key';

import type { IAlert, IMessage, INotification } from '@/features/notifications/models';
import type { INotificationsState } from './state.interface';
import type { IBellAlert } from '@/features/notifications/models/bell-alert.interface';


const initialState: INotificationsState = {
    alerts: [],
    bellAlerts: [],
    messages: [],
    notifications: []
};

let nextId = 0;

const _slice = createSlice( {
    name: notificationsSliceKey,
    initialState,
    /**
     * The reducers property both creates an action creator function and responds to that action in the slice reducer.
     * Actions need to be exported, see ./actions/index.ts and list these reducers there
     */
    reducers: {
        createAlert: ( state, action: PayloadAction<IAlert> ) =>
        {
            state.alerts.push( {
                ...action.payload,
                read: 'read' in action.payload ? action.payload.read : true,
                channel: action.payload.channel ?? 'global',
                id: ( nextId++ ),
            } );
        },
        clearAlert: ( state, action: PayloadAction<IAlert> ) =>
        {
            state.alerts = [ ...state.alerts.filter( alert => alert.id !== action.payload.id ) ];
        },
        clearAlerts: ( state ) =>
        {
            state.alerts = [];
        },
        createBellAlert: ( state, action: PayloadAction<IBellAlert> ) =>
        {
            state.bellAlerts.push( {
                action_result: null,
                read: false,
                id: ( nextId++ ),
                created_at: new Date().toUTCString(),
                ...action.payload,
            } );
        },
        makeBellAlertRead: ( state, action: PayloadAction<IBellAlert> ) =>
        {
            state.bellAlerts.find( bellAlert => bellAlert.id === action.payload.id ).read = true;
        },
        makeAllBellAlertsRead: ( state ) =>
        {
            state.bellAlerts.forEach( bellAlert =>
            {
                bellAlert.read = true;
            } );
        },
        changeBellAlertActionResult: (
              state,
              action: PayloadAction<{
                  bellAlert: IBellAlert,
                  newActionResult: IBellAlert['action_result']
              }>
        ) =>
        {
            const alert = state.bellAlerts.find( bellAlert => bellAlert.id === action.payload.bellAlert.id );
            alert.action_result = action.payload.newActionResult;
            alert.action_date = new Date().toUTCString();
            alert.read = true;
        },
        clearBellAlert: ( state, action: PayloadAction<IBellAlert> ) =>
        {
            state.bellAlerts = [ ...state.bellAlerts.filter( bellAlert => bellAlert.id !== action.payload.id ) ];
        },
        clearBellAlerts: ( state ) =>
        {
            state.bellAlerts = [];
        },
        createMessage: ( state, action: PayloadAction<IMessage> ) =>
        {
            state.messages.push( {
                ...action.payload,
                id: ( nextId++ ),
            } );
        },
        clearMessages: ( state ) =>
        {
            state.messages = [];
        },
        createNotification: ( state, action: PayloadAction<INotification> ) =>
        {
            const notificationExistsByContent = state.notifications.find( notification => notification.content === action.payload.content );
            if ( notificationExistsByContent ) return;

            state.notifications.push( {
                ...action.payload,
                id: ( nextId++ ),
            } );
        },
        clearNotifications: ( state ) =>
        {
            state.notifications = [];
        },
        clearNotificationsState: () =>
        {
            return initialState;
        }
    },
    /**
     * --- SAGAS ARE PREFERRED ---
     * Use extraReducers when you are dealing with an action that you have already defined somewhere else.
     * The most common examples are responding to a createAsyncThunk action and responding to an action from another slice.
     */
    /*extraReducers: ( builder ) =>
    {
        builder.addCase( ACTION, ( state, action ) =>
        {

        } );
    }*/
} );

// export const notificationsSlice = _slice.reducer;
export const notificationsSlice = configureReduxPersist<INotificationsState>( notificationsSliceKey, _slice.reducer );

export const actions = _slice.actions;

