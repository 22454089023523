import { type FC, memo, type ReactNode } from 'react';
import tw from 'tailwind-styled-components';

import Popover, { type PopoverProps } from 'antd/lib/popover';
import { TextWithCutoff } from '@/components/structural/TextWithCutoff';


export interface IPillProps
{
    variant?: 'success' | 'danger' | 'warning' | 'info' | 'default' | 'primary',
    text: ReactNode,
    className?: string,
    trigger?: PopoverProps['trigger'],
    size?: 'sm' | 'md' | 'lg' | 'xl' | 'unlimited' | 'fit',
    customColour?: string,
    customBgColour?: string,
    onClick?: () => void,
    disableEllipsis?: boolean,
    maxCharsCutOff?: number
}

export const Pill: FC<IPillProps> = memo( ( {
    text,
    variant = 'default',
    className,
    trigger = 'click',
    size = 'fit',
    customColour,
    customBgColour,
    onClick,
    disableEllipsis,
    maxCharsCutOff
} ) =>
{
    return <>
        <StyledPill $variant={ variant } $size={ size } className={ className } style={ {
            backgroundColor: customBgColour,
            color: customColour
        } } onClick={ onClick }>
            <Popover content={ text } placement="topLeft" trigger={ trigger }>
                { typeof text !== 'string' || !text.trim().length ?
                      text :
                      <TextWithCutoff disableEllipsis={ disableEllipsis } maxCharsCutOff={ maxCharsCutOff }>
                          { text }
                      </TextWithCutoff>
                }
            </Popover>
        </StyledPill>
    </>;
} );

Pill.displayName = 'Pill';

type StyledPillStyleProps = { $variant: IPillProps['variant'], $size: IPillProps['size'] };
const StyledPill = tw.div<StyledPillStyleProps>`
    rounded-2xl
    py-1
    px-3
    inline-block
    overflow-hidden
    overflow-ellipsis
    whitespace-nowrap
    h-8
    ${ ( p: StyledPillStyleProps ) =>
      ( p.$size === 'sm' ? `
        w-[2rem]
      ` : null ) +
      ( p.$size === 'md' ? `
        w-[3rem]
      ` : null ) +
      ( p.$size === 'lg' ? `
        w-[4rem]
      ` : null ) +
      ( p.$size === 'xl' ? `
        w-[6rem]
      ` : null ) +
      ( p.$size === 'unlimited' ? `
        w-full
      ` : null ) +
      ( p.$size === 'fit' ? `
        w-fit
      ` : null )
}
    hover:cursor-pointer
    ${ ( p: StyledPillStyleProps ) =>
      ( p.$variant === 'success' ? `
        text-success
            dark:text-success-dark
        bg-success-bg
            dark:bg-default-dark
        hover:bg-success-hover
            dark:bg-success-hover-dark
        ` : null ) +
      ( p.$variant === 'default' ? `
        text-default
            dark:text-default-dark
        bg-default-bg
            dark:bg-default-dark
        hover:bg-default-hover
            dark:bg-default-hover-dark
        ` : null ) +
      ( p.$variant === 'warning' ? `
        text-warning
            dark:text-warning-dark
        bg-warning-bg
            dark:bg-default-dark
        hover:bg-warning-hover
            dark:bg-warning-hover-dark
        ` : null ) +
      ( p.$variant === 'info' ? `
        text-info
            dark:text-info-dark
        bg-info-bg
            dark:bg-default-dark
        hover:bg-info-hover
            dark:bg-info-hover-dark
        ` : null ) +
      ( p.$variant === 'danger' ? `
        text-danger
            dark:text-danger-dark
        bg-danger-bg
            dark:bg-default-dark
        hover:bg-danger-hover
            dark:bg-danger-hover-dark
        ` : null ) +
      ( p.$variant === 'primary' ? `
        text-primary
            dark:text-primary-dark
        bg-primary-bg
            dark:bg-default-dark
        hover:bg-primary-hover
            dark:bg-primary-hover-dark
        ` : null ) }
`;