import { useMemo } from 'react';

import { useGetAllByNameQuery } from '@/store/company/service.ch';


export const useCompaniesChByName = ( { query }: { query?: string }, skip = false ) =>
{
    const { data, isFetching, isLoading, error } = useGetAllByNameQuery( query, {
        skip
    } );

    return useMemo( () => ( {
        results: data.data,
        meta: data.meta,
        isLoading,
        isFetching,
        error
    } ), [ data, isFetching, isLoading, error ] );
};