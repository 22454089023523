import useMount from 'ahooks/lib/useMount';
import { type FC, memo, useCallback, useState } from 'react';

import { type IUploadZoneProps, UploadZone } from '@/components/structural';
import { Checkbox, DatePicker, Form, FormItem, type IFormStepProps, Input, Radio, RadioGroup, useForm, useTouchForm } from '@/components/structural/form';

import type { ORJSON } from '@/shared/models';
import type { ORCompanyFilingNoticeOfChange } from '@/features/company/shared/models';


export interface IStep2Data extends ORJSON<string | File[] | boolean>
{
    proposed_name: string,
    notice_of_change: ORCompanyFilingNoticeOfChange,
    files: File[],
    confirm_resolution_file_distributed: boolean,
    resolution_date: string,
}

export const Step2: FC<IFormStepProps<IStep2Data>> = memo( ( { initialData, setData, isStepValid, setIsValid } ) =>
{
    const { touchForm } = useTouchForm();
    const [ formStep ] = useForm();

    const [ defaults ] = useState<IStep2Data>( initialData || {
        proposed_name: '',
        notice_of_change: null,
        files: [],
        confirm_resolution_file_distributed: false,
        resolution_date: null,
    } );

    useMount( () =>
    {
        formStep.setFieldsValue( defaults );
        touchForm();

        isStepValid( formStep ).then( setIsValid );
    } );

    const updateFormData = useCallback( () =>
    {
        setData( formStep.getFieldsValue() );
        isStepValid( formStep ).then( setIsValid );
    }, [ setData, formStep, setIsValid, isStepValid ] );

    const onFilesDrop = useCallback<IUploadZoneProps[ 'onFilesDrop']>( ( files ) =>
    {
        formStep.setFieldsValue( {
            files
        } );

        updateFormData();
    }, [ formStep, updateFormData ] );

    return <>
        <Form form={ formStep }
              className="font-roboto"
              layout="vertical"
              requiredMark={ false }
              onValuesChange={ updateFormData }
        >
            <FormItem
                  label="Proposed Name"
                  tooltip="Name here"
                  name="proposed_name"
                  rules={ [ { required: true, message: <></>, type: 'string', min: 1 } ] }
            >
                <Input/>
            </FormItem>

            <FormItem
                  label="I confirm that the change of company name:"
                  name="notice_of_change"
                  className="gray-label simple-label"
                  valuePropName="value"
                  rules={ [ { required: true, message: <></>, type: 'enum', enum: [ 'Special Resolution', 'Articles Of Association' ] } ] }
            >
                <RadioGroup className="w-full">
                    <div className="grid grid-rows-2">
                        <Radio value="Special Resolution" className="my-2">
                            <div className="ml-4">Requires a <span className="success">Special Resolution</span></div>
                        </Radio>
                        <Radio value="Articles Of Association" className="my-2">
                            <div className="ml-4">Is allowed in our <span className="success">Articles of Association</span></div>
                        </Radio>
                    </div>
                </RadioGroup>
            </FormItem>

            <div>
                <FormItem
                      label="Upload your supporting documents"
                      name="files"
                      rules={ [ { required: true, message: <></>, type: 'array', min: 1 } ] }
                >
                    <>
                        <div className="text-default dark:text-default-dark mb-4">Please provide a copy of your supporting document.</div>
                        <UploadZone onFilesDrop={ onFilesDrop } existingFiles={ formStep.getFieldValue( 'files' ) }/>
                    </>
                </FormItem>

                <FormItem
                      name="confirm_resolution_file_distributed"
                      valuePropName="checked"
                      className="-mt-6"
                      rules={ [ { required: true, message: <></>, type: 'enum', enum: [ true ] } ] }
                >
                    <Checkbox>
                        <div className="ml-4">
                            I confirm that the resolution has been distributed to shareholders OR that we held a meeting.
                        </div>
                    </Checkbox>
                </FormItem>
            </div>

            <FormItem
                  label="Date meeting held of resolution agreed"
                  name="resolution_date"
                  rules={ [ { required: true, message: <></>, type: 'date' } ] }
            >
                <DatePicker/>
            </FormItem>
        </Form>
    </>;
} );

Step2.displayName = 'CompanyChangeRegisteredNameStep2';