import { type FC, memo, useCallback, useMemo } from 'react';

import { useCurrentCompany } from '@/store/company/hooks';

import { Card, DataFormat, Loader, WidgetViewAllLink } from '@/components/structural';
import { prepareColumnItem } from '@/components/tables/sorting-tools/prepareColumnItem';
import { Table } from '@/components/tables/Table';

import { formatWordsWithSeparator, getArray } from '@/shared/tools';


interface IFilingHistoryWidgetProps
{
    maxToShow?: number;
}

export const FilingHistoryWidget: FC<IFilingHistoryWidgetProps> = memo( ( { maxToShow = 10 } ) =>
{
    const { chFilings, isFetching } = useCurrentCompany();

    const data = useMemo( () => getArray<typeof chFilings.items[number]>( chFilings?.items )
          .map( ( filing, index ) => ( {
              ...filing, key: index.toString(),
          } ) )
          .slice( 0, maxToShow ), [ chFilings, maxToShow ] );
    type DataType = typeof data[number];

    const getColumns = useCallback( () => [
        prepareColumnItem<DataType, 'category'>( {
            title: 'Description',
            key: 'category',
            render: ( category, filing ) => filing.subcategory ?
                  <div>
                      <DataFormat raw={
                            formatWordsWithSeparator( category ) + ' - ' +
                            `${ formatWordsWithSeparator( filing.subcategory ) } (${ filing.type })`
                      } inline maxCharsCutOff={ 30 }/>
                  </div>
                  :
                  <DataFormat raw={ formatWordsWithSeparator( category ) }/>
        } ),
        prepareColumnItem<DataType>( {
            title: 'Date',
            key: 'date',
            render: date => <DataFormat raw={ date } type="date"/>
        } ),
    ], [] );

    if ( isFetching ) return <Loader skeleton/>;

    return ( <>
        <Card title="Filing History" styles={ { body: { paddingBottom: '3.5rem' } } }>
            <Table
                  parsedData={ data }
                  getColumns={ getColumns }
                  disablePagination
                  size="small"
                  disableColumnsSelector
            />
            <WidgetViewAllLink
                  url="/my-company/filings"
            />
        </Card>
    </> );
} );

FilingHistoryWidget.displayName = 'FilingHistoryWidget';