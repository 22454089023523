import { useMemo } from 'react';

import { selectAppNotifications } from '@/store/notifications/selectors';
import { useAppSelector } from '@/store/hooks';


export const useNotifications = () =>
{
    const notifications = useAppSelector( selectAppNotifications );

    return useMemo( () => ( {
        notifications
    } ), [ notifications ] );
};