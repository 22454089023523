export const getMimeTypeByExtension = ( fileName: string ): string =>
{
    const ext = fileName.split( '.' ).pop()?.toLowerCase();

    const mimeTypes: { [ key: string ]: string } = {
        'pdf': 'application/pdf',
        'jpg': 'image/jpeg',
        'jpeg': 'image/jpeg',
        'png': 'image/png',
        'gif': 'image/gif',
        'bmp': 'image/bmp',
        'svg': 'image/svg+xml',
        'txt': 'text/plain',
        'html': 'text/html',
        'htm': 'text/html',
        'json': 'application/json',
        'js': 'text/javascript',
        'css': 'text/css',
        'xml': 'application/xml',
        'doc': 'application/msword',
        'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'xls': 'application/vnd.ms-excel',
        'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'ppt': 'application/vnd.ms-powerpoint',
        'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'mp3': 'audio/mpeg',
        'mp4': 'video/mp4',
        'wav': 'audio/wav',
        'avi': 'video/x-msvideo',
        'csv': 'text/csv'
    };

    return mimeTypes[ ext ] || 'application/octet-stream';
};
