import { type FC } from 'react';
import tw from 'tailwind-styled-components';

import CheckboxAntD, { type CheckboxProps } from 'antd/lib/checkbox';


export const Checkbox: FC<CheckboxProps> = ( props ) =>
{
    return ( <CheckboxWrapper { ...props }/> );
};

export const { Group: CheckboxGroup } = CheckboxAntD;

const CheckboxWrapper = tw( CheckboxAntD )`
    items-start
`;