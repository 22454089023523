import type { IORCompany } from '@/features/company/shared/models';
import { formatStringTitleCase } from '@/shared/tools';


export const normaliseCompanyName = ( c: IORCompany ): IORCompany => ( {
    ...c,
    name: formatStringTitleCase( c.company_name ),
} );

// export const normaliseCompanyNameString = ( company_name: string ): string => formatStringTitleCase( company_name );
export const normaliseCompanyNameString = ( company_name: string ): string => company_name;