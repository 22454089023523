import type { ORJSON } from '@/shared/models';
import type { IORAttachment, IORAttachmentMainAttributes } from '@/store/_global/attachments/attachment.interface';


const attachmentFiles: ORJSON<File> = {};

export const addAttachmentFileToStore = ( attachment: IORAttachmentMainAttributes, file: File ) =>
{
    attachmentFiles[ attachment.name + attachment.file_size.toString() ] = file;
};

export const getAttachmentFileFromStore = ( attachment: IORAttachment ) =>
      attachmentFiles[ attachment.name + attachment.file_size.toString() ];

export const removeAttachmentFileFromStore = ( attachment: IORAttachment ) =>
{
    delete attachmentFiles[ attachment.name + attachment.file_size.toString() ];
};