import useMount from 'ahooks/lib/useMount';
import { type FC, memo, useCallback, useState } from 'react';

import { AddressLookup } from '@/components/structural';
import { Form, FormItem, type IFormStepProps, Input, useForm } from '@/components/structural/form';

import type { IORCompanyFilingCompanyRegisteredAddressMainAttributes } from '@/features/company/shared/models';


export interface IStep2Data extends Omit<IORCompanyFilingCompanyRegisteredAddressMainAttributes, 'same_country'>
{
    some?: boolean,
}

export const Step2: FC<IFormStepProps<IStep2Data>> = memo( ( { initialData, setData, isStepValid, setIsValid } ) =>
{
    const [ formStep ] = useForm();

    const [ defaults ] = useState<IStep2Data>( initialData || {
        address_line_1: '',
        address_line_2: '',
        city: '',
        country: '',
        post_code: '',
    } );

    useMount( () =>
    {
        formStep.setFieldsValue( defaults );
        isStepValid( formStep ).then( setIsValid );
    } );

    const updateFormData = useCallback( () =>
    {
        setData( formStep.getFieldsValue() );
        isStepValid( formStep ).then( setIsValid );
    }, [ setData, formStep, setIsValid, isStepValid ] );

    const onAddressSearchSelected = ( address: FetchifyAddressLookupResult ) =>
    {
        formStep.setFieldsValue( {
            address_line_1: address.line_1,
            address_line_2: address.line_2,
            city: address.locality,
            country: address.province_name,
            post_code: address.postal_code,
        } );

        updateFormData();
    };

    return <>
        <Form form={ formStep }
              className="font-roboto"
              layout="vertical"
              requiredMark={ false }
              onValuesChange={ updateFormData }
        >
            <FormItem
                  label="Address Lookup"
            >
                <AddressLookup
                      onSelect={ onAddressSearchSelected }/>
            </FormItem>

            <FormItem
                  label="Building Name/Number:"
                  name="address_line_1"
                  rules={ [ { required: false, message: <></>, type: 'string', min: 0 } ] }
            >
                <Input/>
            </FormItem>

            <FormItem
                  label="Street:"
                  name="address_line_2"
                  rules={ [ { required: false, message: <></>, type: 'string', min: 0 } ] }
            >
                <Input/>
            </FormItem>

            <FormItem
                  label="Post Town:"
                  name="city"
                  rules={ [ { required: false, message: <></>, type: 'string', min: 0 } ] }
            >
                <Input/>
            </FormItem>

            <FormItem
                  label="County/Region:"
                  name="country"
                  rules={ [ { required: false, message: <></>, type: 'string', min: 0 } ] }
            >
                <Input/>
            </FormItem>

            <FormItem
                  label="Postcode:"
                  name="post_code"
                  rules={ [ { required: false, message: <></>, type: 'string', min: 0 } ] }
            >
                <Input/>
            </FormItem>
        </Form>
    </>;
} );

Step2.displayName = 'CompanyChangeRegisteredAddressStep2';