import { all } from 'redux-saga/effects';

import { httpErrorNotificationSaga } from './httpErrorNotification.saga';
import { onLogoutSaga } from './onLogout.saga';


export default function* notificationsSaga()
{
    yield all( [
        httpErrorNotificationSaga(),

        onLogoutSaga(),
    ] );
}