import { put, takeEvery } from 'redux-saga/effects';

import { datastoreApi } from '@/store/datastore';
import { clearDatastoreState } from '../actions';
import { doLogoutClearState } from '@/store/auth/actions';


function* onLogoutScript()
{
    yield put( datastoreApi.util.resetApiState() );

    yield put( clearDatastoreState() );
}

export function* onLogoutSaga()
{
    yield takeEvery( [ doLogoutClearState ], onLogoutScript );
}