import Router, { useRouter } from 'next/router';
import { useEffect } from 'react';

import { createNotification } from '@/store/notifications/actions';
import { useAppDispatch } from '@/store/hooks';

import { FAKE_ERROR_MESSAGE } from '@/shared/errors';
import { routeAllowedOnMultipleCompanies } from '@/shared/constants';

import type { IAWSUserLimited } from '@/features/auth/models';
import type { IORUser } from '@/features/user/models';


const defaultLandingPageImport = require( '../../../shared/defaultLandingPage.js' );

export const usePreventNavigationIfOnDashboardAndNoCompanySelected = ( {
    setShowLoader,
    awsUser,
    settings,
    defaultLandingPage = defaultLandingPageImport
}: {
    setShowLoader: ( show: boolean ) => void,
    awsUser: IAWSUserLimited,
    settings: IORUser['settings'],
    defaultLandingPage?: string
} ) =>
{
    const router = useRouter();
    const dispatch = useAppDispatch();

    useEffect( () =>
    {
        const handleRouteChangeStart = ( nextUrl: string ) =>
        {
            if ( !routeAllowedOnMultipleCompanies( settings.currentCompanyUuid, nextUrl ) )
            {
                dispatchErrorMessage();

                router.replace( Router.asPath, Router.asPath, { shallow: true } );
                router.events.emit( 'routeChangeError', '', '', { shallow: false } );

                throw FAKE_ERROR_MESSAGE;
            } else
            {
                setShowLoader( true );
            }
        };

        const handleRouteChangeComplete = () =>
        {
            setShowLoader( false );
        };

        const rejectionHandler = ( event: PromiseRejectionEvent ) =>
        {
            if ( event.reason === FAKE_ERROR_MESSAGE )
            {
                event.preventDefault();
            }
        };

        const unloadHandler = () =>
        {
            router.beforePopState = null;
            router.events.off( 'routeChangeStart', handleRouteChangeStart );
            router.events.off( 'routeChangeComplete', handleRouteChangeComplete );
            window.removeEventListener( 'unhandledrejection', rejectionHandler );
        };

        const dispatchErrorMessage = () =>
        {
            dispatch( createNotification( {
                title: 'Please select a Company before attempting to view other pages.',
                type: 'warning',
                duration: 3
            } ) );
        };

        router.events.on( 'routeChangeStart', handleRouteChangeStart );
        router.events.on( 'routeChangeComplete', handleRouteChangeComplete );
        window.addEventListener( 'unhandledrejection', rejectionHandler );

        if ( !settings || !awsUser ) return unloadHandler;

        if ( !routeAllowedOnMultipleCompanies( settings.currentCompanyUuid, router.pathname ) )
        {
            dispatchErrorMessage();

            setTimeout( async () =>
            {
                await router.replace( defaultLandingPage, defaultLandingPage, { shallow: true } );
            }, 100 );
        }

        return unloadHandler;
    }, [ settings, awsUser ] );

    // Ensure router.beforePopState is set to a proper function
    router.beforePopState = ( cb ) => cb( { url: '', as: '', options: {} } );
};
