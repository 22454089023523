import { useMemo } from 'react';

import { useCurrentCompany } from '@/store/company/hooks/useCurrentCompany.hook';
import { getMetaDefaultObject } from '@/store/_rtk-query/getMetaDefaultObject';

import { filingCompanyRegisteredNameAdapter, useGetManyFilingsCompanyRegisteredNameQuery } from '@/store/company';
import { filingCompanyRegisteredNameSelectors } from '@/store/company/selectors';

import { compare } from '@/shared/tools';

import { DEFAULT_PER_PAGE } from '@/shared/constants';

import type { IORCompanyFilingCompanyRegisteredName } from '@/features/company/shared/models';
import type { IORGetParameters } from '@/store/_models/get-parameters.interface';


export const useFilingCompanyRegisteredNames = ( getParameters: IORGetParameters = { page: 1, per_page: DEFAULT_PER_PAGE }, skip = false ) =>
{
    const { orCompany } = useCurrentCompany();
    const { data, isFetching, isLoading, error } = useGetManyFilingsCompanyRegisteredNameQuery( getParameters, {
        skip: skip || !orCompany
    } );

    const filingCompanyRegisteredNames = filingCompanyRegisteredNameSelectors.selectAll(
          data ||
          filingCompanyRegisteredNameAdapter.getInitialState()
    );

    filingCompanyRegisteredNames.sort( ( a, b ) => compare( a.updated_at, b.updated_at, true ) );

    const latest: IORCompanyFilingCompanyRegisteredName =
          filingCompanyRegisteredNames[ filingCompanyRegisteredNames.length - 1 ] ||
          ( {} as IORCompanyFilingCompanyRegisteredName );

    const { filing: { data: filing } = { data: null }, ...requestRaw } = latest;

    const request = Object.keys( requestRaw ).length === 0 ? null : requestRaw;

    const meta = data?.meta || getMetaDefaultObject();

    return useMemo( () => ( {
        request,
        filing,
        meta,
        isLoading,
        isFetching,
        error
    } ), [ data, isFetching, isLoading, error ] );
};