import { call, put, takeEvery } from 'redux-saga/effects';

import { authService } from '@/store/auth';
import { doForgotPasswordChange, doForgotPasswordChangeError, doForgotPasswordChangeSuccess } from '@/store/auth/actions';
import { HTTPErrorOccurred } from '@/store/internal/actions';

import handleError from './handleError';
import { processError } from '@/shared/processError';
import { routerNavigate } from '@/components/structural/Link';


function* forgotPasswordChangeScript( { payload }: ReturnType<typeof doForgotPasswordChange> )
{
    try
    {
        yield call( authService.forgotPasswordSubmitNewPassword, payload );
        yield put( doForgotPasswordChangeSuccess() );
    } catch ( err )
    {
        const error = handleError( err );

        processError( 'onForgotPasswordChange saga', error );

        yield put( HTTPErrorOccurred( error ) );
        yield put( doForgotPasswordChangeError( error ) );
    }
}

function* forgotPasswordChangeSuccessScript()
{
    yield routerNavigate( '/auth/forgot-password-change-success' );
}

export function* onForgotPasswordChangeSaga()
{
    yield takeEvery( doForgotPasswordChange, forgotPasswordChangeScript );
    yield takeEvery( doForgotPasswordChangeSuccess, forgotPasswordChangeSuccessScript );
}