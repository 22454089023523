export const getWordsFirstLettersAsCapitals = ( input: string, maxLetters = 9999 ) =>
{
    if ( !input || typeof input !== 'string' || input.length === 0 )
    {
        return '';
    }

    const words = input.split( ' ' );

    return words.reduce( ( acc, word ) =>
    {
        acc += word[ 0 ]?.toLocaleUpperCase() || '';
        return acc;
    }, '' ).slice( 0, maxLetters );
};
