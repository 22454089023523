import { type FC } from 'react';
import tw from 'tailwind-styled-components';

import Tooltip from 'antd/lib/tooltip';

import { SVGIcon } from '@/components/structural/images';

import shareIcon from '@/icons/share.svg';
import printIcon from '@/icons/printer.svg';
import downloadIcon from '@/icons/download.svg';


export interface IModalTopRightContainerProps
{
    onShare?: () => void,
    onPrint?: () => void,
    onDownload?: () => void,
}

export const ModalTopRightActions: FC<IModalTopRightContainerProps> = ( { onShare, onPrint, onDownload } ) => <>
    <Container>
        { onShare && <Tooltip title="Share"><span onClick={ onShare }><Icon src={ shareIcon.src }/></span></Tooltip> }
        { onPrint && <Tooltip title="Print"><span onClick={ onPrint }><Icon src={ printIcon.src }/></span></Tooltip> }
        { onDownload && <Tooltip title="Download"><span onClick={ onDownload }><Icon src={ downloadIcon.src }/></span></Tooltip> }
    </Container>
</>;

const Container = tw.div`
    absolute
    top-6
    right-6
`;

const Icon = tw( SVGIcon )`
    !w-6
    !h-6
    ml-2
    hover:cursor-pointer
`;