import jwt from 'jsonwebtoken';
import jwksClient from 'jwks-rsa';

import { TypedPromise } from '@/shared/models';


const client = jwksClient( {
    jwksUri: `https://cognito-idp.${ process.env.NEXT_PUBLIC_AWS_AMPLIFY_REGION }.amazonaws.com/${ process.env.NEXT_PUBLIC_AWS_AMPLIFY_USER_POOL_ID }/.well-known/jwks.json`
} );

function getKey( header: jwt.JwtHeader, callback: jwt.SigningKeyCallback ): void
{
    client.getSigningKey( header.kid, ( err, key ) =>
    {
        if ( err )
        {
            callback( err, null );
        } else
        {
            const signingKey = key?.getPublicKey();
            callback( null, signingKey );
        }
    } );
}

export const validateOAuthToken = ( token: string ): TypedPromise<boolean | jwt.VerifyErrors, never> =>
{
    return new TypedPromise( ( resolve/*, reject*/ ) =>
    {
        jwt.verify( token, getKey, {}, ( err/*, decoded*/ ) =>
        {
            if ( err )
            {
                resolve( err );
            } else
            {
                resolve( true );
            }
        } );
    } );
};