import { type ParsingOptions, read, utils, type WorkBook, type WorkSheet } from 'xlsx';

import { DATE_FORMAT, DATE_FORMAT_NO_DAY } from '@/shared/dateFormats';
import { isDate, normaliseDate } from '@/shared/tools';


export const parseExcelFile = <T>(
      file: unknown,
      // if date field name contains an '!', it will be treated as dateFormatNoDay, else dateFormat
      dateFields: string[] = [],
      options: ParsingOptions = { type: 'binary' }
): T[] =>
{
    const wb: WorkBook = read( file, options );

    /* grab first sheet */
    const worksheetName: string = wb.SheetNames[ 0 ];
    const worksheet: WorkSheet = wb.Sheets[ worksheetName ];

    /* return data */
    const data = utils.sheet_to_json( worksheet, { blankrows: false, defval: null } ) as T[];

    dateFields.forEach( field =>
    {
        let fieldParsed: string, format: string;
        if ( field.includes( '!' ) )
        {
            fieldParsed = field.split( '!' )[ 0 ];
            format = DATE_FORMAT_NO_DAY;
        } else
        {
            fieldParsed = field;
            format = DATE_FORMAT;
        }

        if ( !isDate( data[ fieldParsed ] ) )
        {
            throw new Error( `Excel Parse Error: Field "${ fieldParsed } is not a valid date value."` );
        }

        data[ fieldParsed ] = normaliseDate( data[ fieldParsed ], format );
    } );

    return data;
};