import { put, takeEvery } from 'redux-saga/effects';

// import { clearNotificationsState } from '@/store/notifications/actions';
import { clearBellAlerts } from '@/store/notifications/actions';
import { doLogoutClearState } from '@/store/auth/actions';


function* onLogoutScript()
{
    yield put( clearBellAlerts() );
    // yield put( clearNotificationsState() );
}

export function* onLogoutSaga()
{
    yield takeEvery( [ doLogoutClearState ], onLogoutScript );
}