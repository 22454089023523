import { type FC, memo, useCallback } from 'react';

import { getFileTypeIcon } from '@/shared/tools';
import { SVGIcon } from './images';

import removeIcon from '@/icons/x-circle-fill.svg';


export interface IUploadZoneFilesListProps
{
    files?: File[],
    setFiles: ( files: File[] ) => void,
    className?: string,
    viewOnly?: boolean,
}

export const UploadZoneFilesList: FC<IUploadZoneFilesListProps> = memo( ( { className, files = [], setFiles, viewOnly = false } ) =>
{
    const bytesToMB = useCallback( ( size: number ) => ( size / ( 1024 * 1024 ) ).toFixed( 2 ), [] );
    const removeFile = useCallback( ( file: File ) => () => setFiles( files.filter( f => f.name !== file.name ) ), [ setFiles, files ] );

    return ( <>
        <div className={ `w-full grid auto-rows-auto mb-4 ${ className }` }>
            { files.map( file => file && (
                  <div className="w-full grid grid-cols-8 items-start" key={ file.name + file.type + file.size }>
                      <div className="col-span-5">{ getFileTypeIcon( file.name, undefined, '!w-6 -ml-1 -mb-0.5' ) } { file.name }</div>
                      { !viewOnly && <div className="justify-self-end col-span-2">{ bytesToMB( file.size ) }MB</div> }
                      { !viewOnly && <div className="justify-self-end" onClick={ removeFile( file ) }>
                          <SVGIcon src={ removeIcon.src } className="text-light !h-6 !w-6 hover:cursor-pointer flex"/>
                      </div> }
                  </div>
            ) ) }
        </div>
    </> );
} );

UploadZoneFilesList.displayName = 'UploadZoneFilesList';