import { type FC, memo } from 'react';

import { doSignupResendConfirmationEmail } from '@/store/auth/actions';
import { useAppDispatch } from '@/store/hooks';

import { AuthPageFormButton } from '@/features/auth/components';
import { Form, FormItem, Input } from '@/components/structural/form';


export interface IAuthSignupResendConfirmationFormProps
{
    className?: string,
}

export const AuthSignupResendConfirmationForm: FC<IAuthSignupResendConfirmationFormProps> = memo( ( props ) =>
{
    const dispatchAction = useAppDispatch();

    const process = ( values: { email: string } ) =>
    {
        dispatchAction( doSignupResendConfirmationEmail( {
            email: values.email.toLocaleLowerCase().trim()
        } ) );
    };

    return ( <>
        <Form
              name="auth"
              layout="vertical"
              requiredMark={ false }
              onFinish={ process }
              validateTrigger="onSubmit"
              className={ props.className }
        >
            <FormItem
                  label="Email"
                  name="email"
                  rules={ [ { required: true, message: 'Please provide your email!', type: 'email' } ] }
            >
                <Input/>
            </FormItem>

            <FormItem wrapperCol={ { span: 24 } }>
                <AuthPageFormButton>
                    RESEND EMAIL
                </AuthPageFormButton>
            </FormItem>
        </Form>
    </> );
} );

AuthSignupResendConfirmationForm.displayName = 'AuthSignupResendConfirmationForm';