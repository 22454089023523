import { type FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { type DropzoneProps, useDropzone } from 'react-dropzone';
import tw from 'tailwind-styled-components';

import { UploadDropZone, UploadZoneFilesList } from '@/components/structural';


export interface IUploadZoneProps
{
    className?: string,
    onFilesDrop: DropzoneProps['onDrop'],
    existingFiles?: File[],
}

export const UploadZone: FC<IUploadZoneProps> = memo( ( { className, onFilesDrop, existingFiles = [] } ) =>
{
    const [ files, setFiles ] = useState<File[]>( [ ...existingFiles ] );
    const filesSetOps = useRef( {
        allSet: false,
        tries: 0
    } );

    const filesDropped = useCallback<DropzoneProps['onDrop']>( ( acceptedFiles, fileRejections, event ) =>
    {
        setFiles( acceptedFiles );
        onFilesDrop( acceptedFiles, fileRejections, event );
    }, [ onFilesDrop, setFiles ] );

    const setFilesCb = useCallback( ( newFiles: File[] ) => filesDropped( newFiles, [], null ), [ filesDropped ] );

    useEffect( () =>
    {
        if ( existingFiles.length > 0 && !filesSetOps.current.allSet )
        {
            setFiles( existingFiles );
            filesSetOps.current.allSet = true;
        }
    }, [ existingFiles, filesSetOps, setFiles ] );

    const { getRootProps, getInputProps, isDragActive, open: openDropzone } = useDropzone( {
        noClick: true,
        noKeyboard: true,
        onDrop: filesDropped
    } );

    return <>
        <DropzoneOver
              $isDragActive={ isDragActive }
              { ...getRootProps( { className: 'relative' } ) }
        >

            <input { ...getInputProps() } />

            { isDragActive && files.length === 0 && <UploadDropZone className={ `!bg-info-light dark:!bg-info-dark border border-2 border-dashed 
                border-info dark:border-info-dark` } textClassName="!text-white"/> }

            { files.length === 0 && <div className={ `mb-4 flex flex-col place-items-center border-2 border-dashed 
        border-default dark:border-default-dark rounded-xl bg-LighterGray ${ className }` }>
                <div className="grid grid-rows-3 place-items-center p-4">
                    <div className="text-primary dark:text-default-dark">Drag & Drop your Files Here</div>
                    <div className="text-default dark:text-default-light">Or</div>
                    <a className="text-success dark:text-success-dark" onClick={ openDropzone }>BROWSE</a>
                </div>
            </div> }

            { files.length > 0 &&
                  <UploadZoneFilesList
                        files={ files }
                        setFiles={ setFilesCb }
                        viewOnly={ typeof files.at( 0 ) === 'object' && files.at( 0 )[ 'file_size' ] }
                  /> }

        </DropzoneOver>
    </>;
} );

UploadZone.displayName = 'UploadZone';

const DropzoneOver = tw.div<{ $isDragActive: boolean }>`
    ${ p => p.$isDragActive ? `
        rounded-xl
    ` : '' }
`;