import { useCallback, useState } from 'react';
import { DEFAULT_PER_PAGE } from '@/shared/constants';


export const usePagination = () =>
{
    const [ currentPage, setCurrentPage ] = useState( 1 );
    const [ perPage, setPerPage ] = useState( DEFAULT_PER_PAGE );
    const onPaginationChange = useCallback( ( page, pageSize ) =>
    {
        setCurrentPage( page );
        pageSize && setPerPage( pageSize );
    }, [] );

    return {
        onPaginationChange, currentPage, perPage
    };
};