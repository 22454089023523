import { useCallback, useState } from 'react';


export const useRefreshComponent = () =>
{
    const [ , setObject ] = useState( {} );
    const refreshComponent = useCallback( ( delay = 0 ) =>
    {
        if ( delay )
        {
            setTimeout( () => setObject( {} ), delay );
        } else
        {
            setObject( {} );
        }
    }, [] );

    return {
        refreshComponent
    };
};