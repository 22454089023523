import { type FC, memo, useMemo } from 'react';

import { useCurrentCompany } from '@/store/company/hooks';
import { useCurrentUser } from '@/store/user/hooks';

import { DataLabel, DataValue, type IWidgetSimpleWrapperProps, Loader, type ORButtonType, Row, WidgetSimpleWithAction, WidgetSimpleWrapper } from '@/components/structural';
import { getPriorityAndAlertPill, getPriorityAndAlertType } from '@/features/company/my-company/widgets/filing/filing-deadlines-data';


export const AccountsWidget: FC = memo( () =>
{
    const { chProfile, isFetching } = useCurrentCompany();
    const { settings } = useCurrentUser();

    const state = useMemo( () =>
    {
        if ( !chProfile?.accounts.next_due ) return { type: 'warning' as ORButtonType, text: '' };
        const analysis = getPriorityAndAlertType( chProfile.accounts.next_due );
        return { type: analysis.alertType as ORButtonType, text: analysis.priority };
    }, [ chProfile?.accounts.next_due ] );

    if ( isFetching ) return <Loader skeleton/>;

    return ( <>
        <WidgetSimpleWrapper
              title="Accounts"
              variant={ state.type as IWidgetSimpleWrapperProps['variant'] }
              extraContentTopRight={ getPriorityAndAlertPill( state.text, '!text-sm' ) }
        >
            <Row>
                <DataLabel>Made up to Date:</DataLabel>
                <DataValue raw={ chProfile?.accounts.next_made_up_to } type="date"/>
            </Row>
            <Row>
                <DataLabel>Due by:</DataLabel>
                <DataValue raw={ chProfile?.accounts.next_due } type="date"/>
            </Row>
            <Row>
                <DataLabel>Last Filed:</DataLabel>
                <DataValue raw={ chProfile?.accounts.last_accounts.made_up_to } type="date"/>
            </Row>
        </WidgetSimpleWrapper>
        { settings?.view_all_widget_states && <><br/>
            <WidgetSimpleWithAction
                  title="Accounts"
                  variant="updated"
                  updatedText="SUBMITTED: 9 DEC 2021"
                  centreContent={ false }
            >
                <Row>
                    <DataLabel>Made up to Date:</DataLabel>
                    <DataValue raw={ chProfile?.accounts.next_made_up_to } type="date"/>
                </Row>
                <Row>
                    <DataLabel>Due by:</DataLabel>
                    <DataValue raw={ chProfile?.accounts.next_due } type="date"/>
                </Row>
                <Row>
                    <DataLabel>Last Filed:</DataLabel>
                    <DataValue raw={ chProfile?.accounts.last_accounts.made_up_to } type="date"/>
                </Row>
            </WidgetSimpleWithAction></> }
    </> );
} );

AccountsWidget.displayName = 'AccountsWidget';