import { all } from 'redux-saga/effects';

import { onLogoutSaga } from './onLogout.saga';


export default function* accountSagas()
{
    yield all( [
        onLogoutSaga(),
    ] );
}