import type { IRequestError } from '@/shared/models';


export default function handleError( error ): IRequestError
{
    return {
        code: error[ 'code' ] ? error.code : 'javascript',
        name: error.name || 'Core Error',
        message: error.message || JSON.stringify( error ).replaceAll( '"', '' ),
    };
}