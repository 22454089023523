import { useMemo } from 'react';

import { selectAppBellAlerts } from '@/store/notifications/selectors';
import { useAppSelector } from '@/store/hooks';

import { compare, getArray } from '@/shared/tools';


export const useBellAlerts = () =>
{
    const bellAlerts = useAppSelector( selectAppBellAlerts );
    const bellAlertsProcessed = getArray<typeof bellAlerts[number]>( bellAlerts );

    const bellAlertsUnread = ( bellAlertsProcessed ).filter( ba => !ba.read );
    bellAlertsUnread.sort( ( a, b ) => compare( a.action_date, b.action_date ) );

    const bellAlertsPending = ( bellAlertsProcessed ).filter( ba => !ba.action_result ).filter( ba => !bellAlertsUnread.includes( ba ) );
    bellAlertsPending.sort( ( a, b ) => compare( a.action_date, b.action_date ) );

    const bellAlertsRead = ( bellAlertsProcessed ).filter( ba => ba.read )
          .filter( ba => !bellAlertsUnread.includes( ba ) )
          .filter( ba => !bellAlertsPending.includes( ba ) );
    bellAlertsRead.sort( ( a, b ) => compare( a.action_date, b.action_date ) );

    return useMemo( () => ( {
        bellAlerts,
        bellAlertsRead,
        bellAlertsUnread,
        bellAlertsPending
    } ), [ bellAlerts, bellAlertsRead, bellAlertsUnread, bellAlertsPending ] );
};