import values from 'lodash/values';
import isPlainObject from 'lodash/isPlainObject';


export const searchData = <T>( data: T[], filter = '' ) =>
{
    if ( !filter )
    {
        return data;
    }

    const stringify = ( str ) => JSON.stringify( str ).toLowerCase();
    const getValues = ( obj ) => [ ...values( obj ).map( v => isPlainObject( v ) ? getValues( v ) : v ) ];
    const needle = filter.toLowerCase();

    if ( needle.includes( ' ' ) )
    {
        const filters = needle.split( ' ' );
        return data.filter( d =>
        {
            const haystack = stringify( getValues( d ) );
            return filters.every( f =>
                  haystack.includes( f )
            );
        } );
    }

    return data.filter( d =>
    {
        return stringify( getValues( d ) ).includes( needle );
    } );
};