import tw from 'tailwind-styled-components';

import { DataFormat } from '@/components/structural/DataFormat';


export const DataLabel = tw.span`
    text-primary
    dark:text-primary-dark
    mr-4
`;

export const DataValue = tw( DataFormat )`
    font-roboto
`;