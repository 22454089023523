import InlineSVG from 'react-inlinesvg';
import { forwardRef, memo, type MouseEvent } from 'react';
import tw from 'tailwind-styled-components';


export interface ISVGIconProps
{
    src: string,
    alt?: string,
    link?: boolean,
    className?: string,
    wrapperClassName?: string;
    onClick?: ( e: MouseEvent<HTMLDivElement> ) => void,
    asSpan?: boolean,
}

export const SVGIcon = memo( forwardRef<HTMLDivElement, ISVGIconProps>( ( props: ISVGIconProps, ref ) =>
{
    const Icon = <IconWrapper
          src={ props.src }
          description={ props.alt || 'Icon' }
          fill="currentColor"
          height={ undefined }
          width={ undefined }
          className={ props.className }
          preProcessor={ ( code: string ) => code.replace( /fill=".*?"/g, 'fill="currentColor"' ) }
          cacheRequests
          uniquifyIDs
    />;

    if ( props.onClick || props.link )
    {
        if ( props.asSpan )
        {
            return <span ref={ ref } className={ 'hover:cursor-pointer inline-block ' + props.wrapperClassName } onClick={ props.onClick }>
                { Icon }
            </span>;
        }
        return <div ref={ ref } className={ 'hover:cursor-pointer inline-block ' + props.wrapperClassName } onClick={ props.onClick }>
            { Icon }
        </div>;
    }

    return Icon;
} ) );

SVGIcon.displayName = 'SVGIcon';

const IconWrapper = tw( InlineSVG )`
    inline-block
    w-4
    h-4
`;