import { type FC, memo } from 'react';

import { SVGIcon } from '@/components/structural/images';
import { Typography } from '@/components/structural';

import checkImage from '@/icons/check-circle-fill.svg';


export interface IAuthForgotPasswordChangeSuccessProps
{
    className?: string,
}

export const AuthForgotPasswordChangeSuccess: FC<IAuthForgotPasswordChangeSuccessProps> = memo( ( props ) =>
{
    return ( <>
        <div className={ `${ props.className }` }>
            <div className="grid place-items-center w-full mt-4 mb-12">
                <SVGIcon src={ checkImage.src } className="h-40 w-40 text-success dark:text-success-dark"/>

                <Typography.Title level={ 3 } className="mt-4">
                    Success!
                </Typography.Title>

                <Typography.Paragraph className="font-roboto">
                    Your password has been reset
                </Typography.Paragraph>
            </div>
        </div>
    </> );
} );

AuthForgotPasswordChangeSuccess.displayName = 'AuthForgotPasswordChangeSuccess';