import { put, takeEvery } from 'redux-saga/effects';

import { createNotification } from '../actions';
import { HTTPErrorOccurred } from '@/store/internal/actions';

import { breakWordByCapitals } from '@/shared/tools';


function* httpErrorNotificationScript( { payload }: ReturnType<typeof HTTPErrorOccurred> )
{
    const title = breakWordByCapitals( payload.name )
          .filter( word => !word.toLowerCase().match( /(exception|error)/g ) );

    yield put( createNotification( {
        title: title.length > 1 ? title.join( ' ' ) : payload.name,
        content: payload.message,
        type: 'error',
        duration: 10
    } ) );
}

export function* httpErrorNotificationSaga()
{
    yield takeEvery( [ HTTPErrorOccurred ], httpErrorNotificationScript );
}