import styled from 'styled-components';
import AnchorAntD from 'antd/lib/anchor';

import twConfig from '@/shared/tailwindConfig';


export const ScrollableFormContainer = styled.div`
    & {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 20rem);
        padding-bottom: 45rem;
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        overflow-y: scroll;
        overscroll-behavior: contain;
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const Anchor = styled( AnchorAntD )`
    & {
        .ant-anchor-ink {
            display: none;
        }

        .ant-anchor-link-title {
            border-bottom-color: rgba(0, 0, 0, 0);
            border-bottom-style: solid;
            border-bottom-width: 0.15rem;
            margin-bottom: 1rem;
        }

        .ant-anchor-link-title-active {
            border-bottom-color: ${ twConfig.theme.colors.success.DEFAULT };
        }
    }
`;

const { Link } = AnchorAntD;
export { Link as LinkAntD };