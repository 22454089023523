import { type FC, memo, type MouseEvent, type PropsWithChildren } from 'react';

import BadgeAntD from 'antd/lib/badge';


export const BadgeWrapper = BadgeAntD;

export interface IBadgeProps
{
    className?: string,
    onClick?: ( e: MouseEvent<HTMLSpanElement> ) => void,
}

export const Badge: FC<PropsWithChildren<IBadgeProps>> = memo( ( props ) =>
{
    return (
          <span
                className={ `relative rounded-full py-0.5 px-1.5 
                          bg-danger dark:bg-danger-dark 
                          text-white dark:text-default-dark 
                          text-2xs 
                          ${ props.className }` }
                onClick={ props.onClick }
          >
            { props.children }
        </span>
    );
} );

Badge.displayName = 'Badge';