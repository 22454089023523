import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import { formatWordsWithSeparator } from '@/shared/tools';


export const usePageTitle = () =>
{
    const router = useRouter();

    const [ pageTitle, setPageTitle ] = useState( 'Client' );
    const pageTitleWithDash = `OneRegistry - ${ pageTitle }`;

    useEffect( () =>
    {
        setPageTitle( () =>
        {
            const path = router.pathname.split( '/' ).filter( ( part ) => part !== '' );
            return path.map( ( phrase ) => formatWordsWithSeparator( phrase ) ).join( ' - ' );
        } );
    }, [ router.pathname ] );

    return useMemo( () => ( { pageTitle, pageTitleWithDash } ), [ pageTitle ] );
};