import styled from 'styled-components';

import TableAntD, { type TablePaginationConfig, type TableProps } from 'antd/lib/table';
import { type SortOrder } from 'antd/lib/table/interface';

import twConfig from '@/shared/tailwindConfig';


const TableOR = ( { className, ...rest }: TableProps ) =>
{
    return (
          <StyledTable
                className={ `${ className || '' }` }
                { ...rest } />
    );
};

const StyledTable = styled( TableAntD )<{ $columnSelectorActive: boolean, $tableEmpty: boolean }>`
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .ant-table {
        scrollbar-width: none;
    }

    & .ant-table-content {
        ${ p => p.$tableEmpty ? 'overflow: hidden !important;' : '' }
    }

    & .ant-table-column-sorter {
        span[role="img"] {
            color: ${ twConfig.theme.colors.default.DEFAULT };

            &.active {
                color: ${ twConfig.theme.colors.success.DEFAULT };
            }
        }
    }

    .ant-table-thead.ant-table-thead.ant-table-thead.ant-table-thead th.ant-table-column-has-sorters:hover::before,
    .ant-table-thead.ant-table-thead.ant-table-thead.ant-table-thead th.ant-table-column-sort::before {
        background-color: ${ twConfig.theme.colors.white } !important;
    }

    & tbody .or-table-recently-updated {
        background-color: ${ twConfig.theme.colors.success[ 'table-row' ] };

        .dark & {
            background-color: ${ twConfig.theme.colors.success[ 'table-row-dark' ] };
        }
    }

    & tbody .or-table-recently-updated td .updated-dot-container {
        text-align: center;

        &::after {
            content: '\\25CF';
            color: ${ twConfig.theme.colors.success.DEFAULT };

            .dark & {
                color: ${ twConfig.theme.colors.success.dark };
            }
        }
    }

    & tbody tr.or-table-row-variant-info {
        background-color: ${ twConfig.theme.colors.info[ 'table-row' ] };
    }

    & tbody tr.or-table-row-variant-primary {
        background-color: ${ twConfig.theme.colors.primary[ 'table-row' ] };
    }

    & tbody tr.or-table-row-variant-danger {
        background-color: ${ twConfig.theme.colors.danger[ 'table-row' ] };
    }

    & tbody tr td:${ p => p.$columnSelectorActive ? `nth-of-type(2)` : `first-of-type` } a,
    & tbody tr td:${ p => p.$columnSelectorActive ? `nth-of-type(2)` : `first-of-type` } > div {
        font-family: ${ twConfig.theme.fontFamily.poppins } !important;
        color: ${ twConfig.theme.colors.primary.light } !important;
    }

    .dark & tbody tr td:${ p => p.$columnSelectorActive ? `nth-of-type(2)` : `first-of-type` } a,
    .dark & tbody tr td:${ p => p.$columnSelectorActive ? `nth-of-type(2)` : `first-of-type` } > div {
        color: ${ twConfig.theme.colors.primary.dark } !important;
    }

    & tbody tr td:${ p => p.$columnSelectorActive ? `nth-of-type(2)` : `first-of-type` } a {
        &:hover {
            color: ${ twConfig.theme.colors.success.DEFAULT } !important;
        }
    }

    .dark & tbody tr td:${ p => p.$columnSelectorActive ? `nth-of-type(2)` : `first-of-type` } a {
        &:hover {
            color: ${ twConfig.theme.colors.success.dark } !important;
        }
    }
` as typeof TableAntD;

export const Table = TableOR as typeof TableAntD;

export type { SortOrder, TablePaginationConfig, TableProps };